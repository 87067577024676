
import { TrQuals } from './index';
import { NgTenants } from './index';
import { TrTrainingGroup } from './index';

export class TrQualXTrainingGroup {
   public qualTrainingGroupId: number = 0;
   public qualId: number = 0;
   public trainingGroupId: number = 0;
   public tenantId: number = 0;
   public qual: TrQuals = null;
   public tenant: NgTenants = null;
   public trainingGroup: TrTrainingGroup = null;
}