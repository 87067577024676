
import { SchClearance } from './index';
import { HrEmployees } from './index';
import { HrGenderType } from './index';
import { SchGrade } from './index';
import { SchOrganizations } from './index';
import { SchScheduleItem } from './index';
import { SchTravelRequests } from './index';
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class SchResources {
   public resourceId: number = 0;
   public oldScheduledIds: string = null;
   public lastName: string = null;
   public firstName: string = null;
   public middleName: string = null;
   public fullName: string = null;
   public gradeId: number = 0;
   public organizationId: number = 0;
   public clearanceId: number = 0;
   public genderTypeId: number = 0;
   public isVip: boolean = false;
   public createdOn: Date = new Date(0);
   public createdById: number = 0;
   public modifiedOn: Date = null;
   public modifiedById: number = null;
   public active: boolean = false;
   public isCarrier: boolean = false;
   public tenantId: number = 0;
   public clearance: SchClearance = null;
   public createdBy: HrEmployees = null;
   public genderType: HrGenderType = null;
   public grade: SchGrade = null;
   public modifiedBy: HrEmployees = null;
   public organization: SchOrganizations = null;
   public schScheduleItemCarrier: SchScheduleItem[] = [];
   public schScheduleItemResource: SchScheduleItem[] = [];
   public schTravelRequests: SchTravelRequests[] = [];
   public tenant: NgTenants = null;
   public woWorkOrders: WoWorkOrders[] = [];
}