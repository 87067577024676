
import { SchResources } from './index';
import { NgTenants } from './index';

export class SchOrganizations {
   public organizationId: number = 0;
   public organizationName: string = null;
   public govBilled: boolean = false;
   public companyBilled: boolean = false;
   public organizationTypeId: number = 0;
   public address: string = null;
   public email: string = null;
   public contactPhone: string = null;
   public tenantId: number = 0;
   public schResources: SchResources[] = [];
   public tenant: NgTenants = null;
}