
import { HrEmployees } from './index';
import { NgTenants } from './index';

export class AccResponsePremiumProject {
   public responsePremiumProjectId: number = 0;
   public projId: string = null;
   public employeeId: number = 0;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public tenant: NgTenants = null;
}