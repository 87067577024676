
import { QcInspectionReports } from './index';
import { NgTenants } from './index';

export class QcContractNumbers {
   public contractNumberId: number = 0;
   public contractNumber: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcInspectionReports: QcInspectionReports[] = [];
   public tenant: NgTenants = null;
}