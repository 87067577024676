
import { HrEmployees } from './index';
import { HrPsc } from './index';
import { NgTenants } from './index';

export class AllStatesOrTerritories {
   public stateOrTerritoryId: number = 0;
   public stateOrTerritory: string = null;
   public abbreviation: string = null;
   public postalCode: string = null;
   public isDefault: boolean = false;
   public tenantId: number = 0;
   public hrEmployeesDriversLicenseState: HrEmployees[] = [];
   public hrEmployeesHomeState: HrEmployees[] = [];
   public hrEmployeesMailingState: HrEmployees[] = [];
   public hrPscDriversLicenseStateToNavigation: HrPsc[] = [];
   public hrPscHomeStateToNavigation: HrPsc[] = [];
   public hrPscMailingStateToNavigation: HrPsc[] = [];
   public tenant: NgTenants = null;
}