
import { FacEquipment } from './index';
import { FacFuelPeriodStatus } from './index';
import { HrEmployees } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class FacFuelLevels {
   public fuelLevelsId: number = 0;
   public readingDate: Date = new Date(0);
   public equipmentId: number = 0;
   public fuelLevel: number = 0;
   public waterLevel: number = 0;
   public fuelTemp: number = 0;
   public measuredBy: string = null;
   public lastUpdatedBy: string = null;
   public siteId: number = null;
   public fuelPeriodStatusId: string = '00000000-0000-0000-0000-000000000000';
   public lastUpdatedById: number = null;
   public measuredById: number = null;
   public tenantId: number = 0;
   public equipment: FacEquipment = null;
   public fuelPeriodStatus: FacFuelPeriodStatus = null;
   public lastUpdatedByNavigation: HrEmployees = null;
   public measuredByNavigation: HrEmployees = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}