import { TransferState, makeStateKey, Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, of } from "rxjs";

import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

import { HandleJsonService } from '../services/json-handler/handle-json.service';
import { tap } from 'rxjs/operators';


@Injectable()
export class CacheDataInterceptor implements HttpInterceptor {
  constructor(protected transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    private handleJson: HandleJsonService
  ) {
  }



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      //If not get or post do nothing
      if (req.method !== 'GET' && req.method !== 'POST') {
        return next.handle(req);
      }

      //If this was already quried server-side, grab that data
      let key = req.url.substring(req.url.lastIndexOf("/") + 1);
      let cachedData = this.transferState.get(makeStateKey(key), undefined);
      if (cachedData !== undefined) {
        console.log(cachedData);
        return of(new HttpResponse({ body: this.handleJson.handleMapping(cachedData) }));
      }

      // if (isPlatformServer(this.platformId)) {
      //   console.log("\n\n\n $$$$$$$$$$$$$$$$ SERVER SIDE Request: \n" + JSON.stringify(req, null, '\t') + "\n\n\n");
      // }

      return next.handle(req).pipe(
        tap(event => {
          // if (isPlatformServer(this.platformId)) {
          //   console.log("\n\n\n $$$$$$$$$$$$$$$$ SERVER SIDE RESPONSE: \n" + JSON.stringify(event, null, '\t') + "\n\n\n");
          // }
          if (event.type == HttpEventType.Response) {
            const response = event.clone({ body: this.handleJson.handleMapping(event.body) });
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(makeStateKey(key), response.body);
              // console.log("$$$$$$$$$$$$$$$$$$$$ Set State \n" + key + "\n" + JSON.stringify(response.body, null, "\t") + "\n\n\n");
            }
            return response;
          }
        })
      );
    }
    catch (e) {
      console.log(`INTERCEPTOR ERROR: ${JSON.stringify(e, null, '\t')}`)
    }
  }


}

