
import { WoScheduledMaintenanceTemplates } from './index';

export class WoScheduledMaintenanceWeekly {
   public scheduledMaintenanceTemplateId: number = 0;
   public everyXweeks: number = null;
   public sunday: boolean = false;
   public monday: boolean = false;
   public tuesday: boolean = false;
   public wednesday: boolean = false;
   public thursday: boolean = false;
   public friday: boolean = false;
   public saturday: boolean = false;
   public scheduledMaintenanceTemplate: WoScheduledMaintenanceTemplates = null;
}