
import { NgEmployeeXpermissionGroup } from './index';
import { NgPermissions } from './index';
import { NgTenants } from './index';

export class NgPermissionGroups {
   public permissionGroupId: number = 0;
   public permissionGroupName: string = null;
   public description: string = null;
   public tenantId: number = 0;
   public ngEmployeeXpermissionGroup: NgEmployeeXpermissionGroup[] = [];
   public ngPermissions: NgPermissions[] = [];
   public tenant: NgTenants = null;
}