
import { InvConfig } from './index';
import { LogReqHeader } from './index';
import { LogReqHeaderAudit } from './index';

export class LogReqRoutingSections {
   public routingSectionId: number = 0;
   public routingSection: string = null;
   public routingDepartment: string = null;
   public holdsUnsubmitted: boolean = false;
   public routeToBuyerIfSufficientApprovalAuth: boolean = false;
   public isQuote: boolean = false;
   public isBuy: boolean = false;
   public isShippable: boolean = false;
   public isEditable: boolean = false;
   public showBuyerColumns: boolean = false;
   public order: number = 0;
   public componentId: number = 0;
   public tenantId: number = 0;
   public showPcaColumns: boolean = false;
   public isReceivable: boolean = false;
   public showRejectToUserButton: boolean = false;
   public showRejectToValidatorButton: boolean = false;
   public showSplitReqButton: boolean = false;
   public showForceCloseReqButton: boolean = false;
   public showCorrectReqButton: boolean = false;
   public showParkReqButton: boolean = false;
   public showSubmitButton: boolean = false;
   public showRequoteButton: boolean = false;
   public routingConfig: string = null;
   public parkingLotStatuses: string = null;
   public showRejectToPcabutton: boolean = false;
   public showCorrectCodingButton: boolean = false;
   public invConfig: InvConfig[] = [];
   public logReqHeader: LogReqHeader[] = [];
   public logReqHeaderAudit: LogReqHeaderAudit[] = [];
}