
import { HrPsc } from './index';
import { HrEmployees } from './index';
import { HrJobTitles } from './index';
import { NgTenants } from './index';

export class HrFyPayRates {
   public fyPayRateId: number = 0;
   public fiscalYear: number = 0;
   public payRate: number = 0;
   public jobTitleId: number = 0;
   public pmmApprovedById: number = null;
   public pmmApprovedOn: Date = null;
   public hrmApprovedById: number = null;
   public hrmApprovedOn: Date = null;
   public pscsGenerated: boolean = false;
   public tenantId: number = 0;
   public hrPscUnionHourlyRateFrom: HrPsc[] = [];
   public hrPscUnionHourlyRateTo: HrPsc[] = [];
   public hrmApprovedBy: HrEmployees = null;
   public jobTitle: HrJobTitles = null;
   public pmmApprovedBy: HrEmployees = null;
   public tenant: NgTenants = null;
}