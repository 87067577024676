import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core'
import {
  AimsCommonTableDateFilter,
  AimsCommonTableDateRange,
  AimsCommonTableDateRanges,
  AimsCommonTableHeader,
} from '../aims-common-table.interface'

@Component({
  selector: 'aims-common-table-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css'],
})
export class DateFilterComponent implements OnChanges {
  @Input() column: AimsCommonTableHeader
  @Input() public set value(val: { value : Date | undefined, range : AimsCommonTableDateRanges | undefined }) {
    this.date = val?.value;
    this.range = val?.range;
    this.dateValue = val?.value;
  }

  @Output() change: EventEmitter<{ value: AimsCommonTableDateFilter }> = new EventEmitter<{
    value: AimsCommonTableDateFilter
  }>()

  range?: AimsCommonTableDateRanges
  date?: Date
  dateValue

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    // Take care of when the user clears all filters in the table
    if (changes.value && changes.value.previousValue !== changes.value.currentValue) {
      if (changes.value.currentValue === undefined) {
        this.dateValue = ''
        this.date = undefined
        this.range = undefined
      }
    }
  }

  emitChange() {
    if (!this.range || (!this.date && this.range != 'Is Null' && this.range != 'Is not Null')) {
      return
    }

    const result: AimsCommonTableDateFilter = {
      value: this.date,
      range: this.range,
    }
    this.change.emit({ value: result })
  }

  filterChange($event) {
    this.date = $event.value
    this.emitChange()
  }

  rangeClick(type: AimsCommonTableDateRanges) {
    this.range = type
    if (this.range == 'Is Null' || this.range == 'Is not Null') {
      this.date = new Date();
    }
    this.emitChange()
  }

  clearFilter() {
    this.dateValue = ''
    this.date = undefined
    this.range = undefined
    this.change.emit({ value:  undefined })
  }
}
