
import { HrEmployees } from './index';
import { FacWasteContainerType } from './index';
import { AllLocations } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';
import { FacWasteCurrentStatus } from './index';
import { FacWasteTypes } from './index';

export class FacWasteDrums {
   public wasteDrumId: number = 0;
   public wasteDrumNumber: string = null;
   public wasteTypeId: number = 0;
   public containerTypeId: number = 0;
   public siteId: number = 0;
   public locationId: number = null;
   public issuedDate: Date = new Date(0);
   public issuedBy: string = null;
   public closedOutDate: Date = null;
   public closedOutBy: string = null;
   public wasteCurrentStatusId: number = null;
   public sealedDate: Date = null;
   public sealedBy: string = null;
   public issuedById: number = 0;
   public sealedById: number = null;
   public closedOutById: number = null;
   public createdDate: Date = new Date(0);
   public accumulationDate: Date = null;
   public offSiteDate: Date = null;
   public tenantId: number = 0;
   public closedOutByNavigation: HrEmployees = null;
   public containerType: FacWasteContainerType = null;
   public issuedByNavigation: HrEmployees = null;
   public location: AllLocations = null;
   public sealedByNavigation: HrEmployees = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
   public wasteCurrentStatus: FacWasteCurrentStatus = null;
   public wasteType: FacWasteTypes = null;
}