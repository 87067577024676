
import { FacFuelPeriodStatus } from './index';
import { NgTenants } from './index';

export class FacFuelPeriods {
   public fuelPeriodId: string = '00000000-0000-0000-0000-000000000000';
   public calendarMonthNumber: number = 0;
   public calendarMonthName: string = null;
   public calendarYear: number = 0;
   public fromDate: Date = new Date(0);
   public toDate: Date = new Date(0);
   public tenantId: number = 0;
   public facFuelPeriodStatus: FacFuelPeriodStatus[] = [];
   public tenant: NgTenants = null;
}