
import { QcObservations } from './index';
import { NgTenants } from './index';

export class QcObservationAttachments {
   public attachmentId: number = 0;
   public observationId: number = 0;
   public attachment: Array<any> = [];
   public attachmentName: string = null;
   public attachmentType: string = null;
   public printOnQcInspectionReport: boolean = false;
   public addedById: number = 0;
   public tenantId: number = 0;
   public attachmentGuid: string = null;
   public observation: QcObservations = null;
   public tenant: NgTenants = null;
}