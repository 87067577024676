
import { BinMaster } from './index';
import { LogReqLineItems } from './index';

export class BinsAtSites {
   public binAtSiteId: number = 0;
   public binNumber: string = null;
   public invSiteNr: number = 0;
   public loc: string = null;
   public wpr: boolean = null;
   public qtyOnHand: number = null;
   public qtyAlloc: number = null;
   public qtyOnOrder: number = 0;
   public qtyAuth: number = null;
   public qtyMin: number = null;
   public comment: string = null;
   public xref: string = null;
   public dateSerial: string = null;
   public siteId: number = null;
   public tenantId: number = 0;
   public binId: number = null;
   public bin: BinMaster = null;
   public logReqLineItems: LogReqLineItems[] = [];
}