import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, EMPTY } from "rxjs";
import { AuthService, Tokens } from '../services/auth/auth.service';
import { catchError, switchMap, filter, take, delay, map, tap, retry, retryWhen } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Don't add token to these requests
    const doNotAddBearerToken: Array<string> = [
      "Common/TransferData",
      "Auth/Refresh"
    ];

    if (!doNotAddBearerToken.some(val => request.url.indexOf(val) !== -1) && request.url.indexOf("api") !== -1 && this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      withCredentials: true,
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private cancelRequests: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private requestsAwaitingAuth: number = 0;

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.requestsAwaitingAuth++;
    if (!this.isRefreshing.value) {
      this.isRefreshing.next(true);

      return this.authService.refreshToken().pipe(
        catchError(error => {
          //419 isn't in the HTML spec but is proposed to mean Authentication Timeout
          //If token is invalid, cancel all requests and navigate to login
          this.isRefreshing.next(false);
          if (error instanceof HttpErrorResponse && error.status == 419){
            this.cancelRequests.next(true);
            this.authService.doLogoutUser();
            this.router.navigate(['login']);
            return EMPTY;
          }
        }),
        tap(t => this.requestsAwaitingAuth--),
        switchMap((token: Tokens) => {
          this.isRefreshing.next(false);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      //If is refreshing token currently, wait a second then try again
      //Will log still refreshing
      //If cancelled, will return empty to drop the request since we redirect to login
      return this.isRefreshing.pipe(
          take(1),
          delay(1000),
          tap(isRef => {
            if (isRef && !this.cancelRequests.value)
              throw "Still Refreshing Token!";
          }),
          retryWhen(errors =>
            errors.pipe(
              tap(err => console.log(err))
            )),
          switchMap(isRef => {
            const cancel: boolean = this.cancelRequests.value;
            this.requestsAwaitingAuth--;
            //If there isn't anything else wait to be cancelled, set this back to false to not cancel the next requests that occur
            if (this.requestsAwaitingAuth <= 0 && cancel){
              this.cancelRequests.next(false);
            }
            return (cancel) ? EMPTY : this.intercept(request, next);
          })
        )
    }
  }
}

