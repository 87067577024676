
import { AccResponsePremiums } from './index';
import { AccTimeEntry } from './index';
import { AccTimeEntry2 } from './index';
import { AccTimeEntryComments } from './index';
import { AccTimeEntryHeaderAudit } from './index';
import { HrEmployees } from './index';
import { NgTenants } from './index';

export class AccTimeEntryHeader {
   public timeEntryHeaderId: number = 0;
   public employeeId: number = 0;
   public weekOf: Date = new Date(0);
   public statusId: number = 0;
   public timeReportTypeId: number = 0;
   public payStubDest: string = null;
   public submittedById: number = null;
   public submittedDate: Date = null;
   public approvedById: number = null;
   public approvedDate: Date = null;
   public reviewedById: number = null;
   public reviewedDate: Date = null;
   public acceptedById: number = null;
   public acceptedDate: Date = null;
   public modifiedById: number = null;
   public modifiedDate: Date = null;
   public beenRejected: boolean = null;
   public corrected: boolean = null;
   public otherComments: string = null;
   public employeeComments: string = null;
   public otherCommentsId: number = null;
   public tenantId: number = 0;
   public onHold: boolean = false;
   public sentToCp: boolean = null;
   public accResponsePremiums: AccResponsePremiums[] = [];
   public accTimeEntry: AccTimeEntry[] = [];
   public accTimeEntry2: AccTimeEntry2[] = [];
   public accTimeEntryComments: AccTimeEntryComments[] = [];
   public accTimeEntryHeaderAudit: AccTimeEntryHeaderAudit[] = [];
   public acceptedBy: HrEmployees = null;
   public approvedBy: HrEmployees = null;
   public employee: HrEmployees = null;
   public modifiedBy: HrEmployees = null;
   public reviewedBy: HrEmployees = null;
   public submittedBy: HrEmployees = null;
   public tenant: NgTenants = null;
}