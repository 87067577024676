
import { SchDepartAndArriveType } from './index';
import { AllSites } from './index';
import { AllTravelSites } from './index';
import { SchScheduleItem } from './index';
import { OpsTaskCodes } from './index';
import { NgTenants } from './index';
import { SchTravelRequests } from './index';
import { SchTravelerType } from './index';

export class SchTravelRequestLegs {
   public travelRequestLegId: number = 0;
   public travelRequestId: number = 0;
   public oldTravelFromId: number = 0;
   public oldTravelToId: number = 0;
   public travelOn: Date = null;
   public departId: number = null;
   public arriveId: number = null;
   public travelLeg: number = null;
   public militaryNonSupported: boolean = false;
   public travelerTypeId: number = null;
   public taskCodeId: number = null;
   public oanumber: string = null;
   public scheduleItemId: number = null;
   public tenantId: number = 0;
   public oldTravelSiteFromId: number = null;
   public oldTravelSiteToId: number = null;
   public travelFromId: number = null;
   public travelToId: number = null;
   public arrive: SchDepartAndArriveType = null;
   public depart: SchDepartAndArriveType = null;
   public oldTravelFrom: AllSites = null;
   public oldTravelSiteFrom: AllTravelSites = null;
   public oldTravelSiteTo: AllTravelSites = null;
   public oldTravelTo: AllSites = null;
   public scheduleItem: SchScheduleItem = null;
   public taskCode: OpsTaskCodes = null;
   public tenant: NgTenants = null;
   public travelFrom: AllTravelSites = null;
   public travelRequest: SchTravelRequests = null;
   public travelTo: AllTravelSites = null;
   public travelerType: SchTravelerType = null;
}