import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { TransferHttpService } from './services/transfer-http/transfer-http.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart, ActivationStart, ActivatedRoute, ActivationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthGuard } from './guards/auth.guard';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  destroy$: Subject<boolean> = new Subject();

  title = 'AIMS';


  ngOnInit() {
    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe(e => {
      if (e instanceof ActivationEnd){
        const routeTitle = e.snapshot.data.title || e.snapshot.parent.data.title;
        if (routeTitle){
          const newTitle = `${this.title} - ${routeTitle}`;
          this.titleService.setTitle(newTitle);
        }
        else {
          this.titleService.setTitle(this.title);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

