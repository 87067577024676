import { SelectionModel } from '@angular/cdk/collections'
import { AbstractControlOptions, FormControl, FormGroup, ValidatorFn } from '@angular/forms'
import { MatRow } from '@angular/material/table'
import { PartialObserver } from 'rxjs'

export interface AimsCommonTableHeader {
  key: string
  label: string | Function
  tooltip?: string
  type: AimsCommonTableHeaderTypes | AimsCommonTableHeaderType
  format?: string
  prefix?: string
  linkId?: string
  width?: number
  filter?: boolean
  events?: { click?: Function; filterAndSort?: Function; filterAndSortCompare?: Function }
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions
  formControl?: (entity: any) => FormControl | FormGroup
  hidden?: boolean
  filterType?: AimsCommonTableFilterTypes | AimsCommonTableFilterType
  editable?: boolean
  headerClass?: [] | {} | string
  button?: { color: AimsCommonTableButtonColor | AimsCommonTableButtonColors, icon: string }
}

export enum AimsCommonTableButtonColor {
  PRIMARY = 'primary',
  BASIC = 'basic',
  WARN = 'warn',
}

export type AimsCommonTableButtonColors  = 'primary' | 'basic' | 'warn'

export enum AimsCommonTableFilterType {
  BOOLEAN = 'boolean',
  DYNAMIC = 'dynamic',
  DATE = 'date',
}

export type AimsCommonTableFilterTypes = 'boolean' | 'dynamic' | 'date'

export type AimsCommonTableHeaderTypes =
  | 'string'
  | 'checkbox'
  | 'rowExpand'
  | 'dynamic'
  | 'button'
  | 'detailsButton'
  | 'editButton'
  | 'date'
  | 'number'
  | 'boolean'
  | 'html'
  | 'key'
  | 'currency'
  | 'detailCurrency'


export enum AimsCommonTableHeaderType {
  STRING = 'string',
  CHECKBOX = 'checkbox',
  NUMBER = 'number',
  ROW_EXPAND = 'rowExpand',
  DYNAMIC = 'dynamic',
  BUTTON = 'button',
  EDIT_BUTTON = 'editButton',
  DATE = 'date',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  HTML = 'html',
  KEY = 'key',
  CURRENCY = 'currency',
  DETAIL_CURRENCY = 'detailCurrency'
}

export interface AimsCommonTablePermissions {
  create: boolean
  update: boolean | AimsCommonTableFunctionPermissions
  delete: boolean
  read?: boolean
  export?: boolean
  print?: boolean
  cancel?: boolean
  approve?: boolean
  disapprove?: boolean
  filter?: boolean
  clear?: boolean
}

export interface AimsCommonTableFunctionPermissions {
  (element: any): boolean
}

export interface AimsCommonTableSelection {
  key: string
  row: MatRow
  selections: any[]
}
export interface AimsCommonTableAllSelection {
  key: string
  selections: any[]
}

export interface TableState {
  paginator?: TablePaginationState
  sort?: TableSortState
  filter?: {}
}

export interface TablePaginationState {
  previousPageIndex: number
  pageIndex: number
  pageSize: number
  length: number
}

export interface TableSortState {
  active: string
  direction: 'asc' | 'desc'
}

export interface TableFilterState {
  [key: string]: string | undefined
}
export interface TableRowUpdate {
  index: number
  newRow: MatRow | null
  oldRow: MatRow | null
  oldData: object[]
  handleSubscribe: () => PartialObserver<any>
}

export interface TableRowDelete {
  index: number
  row: MatRow
  handleSubscribe: () => PartialObserver<any>
}

export interface TableRowAdd {
  index: number
  data: MatRow
}

export interface TablePrint {
  selected: any,
  data: any
}

export interface AimsCommonTableDateFilter {
  value?: Date
  range?: AimsCommonTableDateRanges
}

export interface TableRowClass {
  element: MatRow
  formGroup: FormGroup
}

export type AimsCommonTableDateRanges = 'Is equal to' | 'Is not equal to' | 'Is after' | 'Is before' | 'Is Null' | 'Is not Null';

export enum AimsCommonTableDateRange {
  IS_EQUAL_TO = 'Is equal to',
  IS_NOT_EQUAL_TO = 'Is not equal to',
  IS_AFTER = 'Is after',
  IS_BEFORE = 'Is before',
  IS_NULL = 'Is Null',
  IS_NOT_NULL = 'Is not Null',
}

export interface FormOptions {
  type: FormOptionTypes
}

export type FormOptionTypes = 'full' | 'default'
