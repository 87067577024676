
import { NgTenants } from './index';
import { AccTimeEntryHeader } from './index';
import { AllSites } from './index';

export class AccTimeEntry {
   public timeEntryId: number = 0;
   public timeEntryHeaderId: number = 0;
   public mondayHrs: number = 0;
   public tuesdayHrs: number = 0;
   public wednesdayHrs: number = 0;
   public thursdayHrs: number = 0;
   public fridayHrs: number = 0;
   public saturdayHrs: number = 0;
   public sundayHrs: number = 0;
   public workSiteId: number = 0;
   public clinid: number = 0;
   public taskId: number = null;
   public jobClassId: number = 0;
   public isCallOut: boolean = false;
   public timeType: string = null;
   public payLevel: number = 0;
   public payShift: number = 0;
   public payType: string = null;
   public workType: string = null;
   public slb: boolean = false;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public timeEntryHeader: AccTimeEntryHeader = null;
   public workSite: AllSites = null;
}