
import { HrEmployees } from './index';
import { TrQuals } from './index';
import { NgTenants } from './index';

export class TrQualHistory {
   public qualHistoryId: number = 0;
   public qualId: number = 0;
   public employeeId: number = 0;
   public approvedOn: Date = null;
   public approvedById: number = null;
   public revokedOn: Date = null;
   public revokedById: number = null;
   public tenantId: number = 0;
   public approvedBy: HrEmployees = null;
   public employee: HrEmployees = null;
   public qual: TrQuals = null;
   public revokedBy: HrEmployees = null;
   public tenant: NgTenants = null;
}