import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { ButtonClick } from '../aims-common-button/aims-common-button.interface'

@Component({
  selector: 'aims-common-button-mini',
  templateUrl: './aims-common-button-mini.component.html',
  styleUrls: ['./aims-common-button-mini.component.css'],
})
export class AimsCommonButtonMiniComponent implements OnInit {
  @Input() disabled = false

  constructor() {}

  ngOnInit(): void {}
}
