
import { HrEmployees } from './index';
import { FacEquipmentMeters } from './index';
import { NgTenants } from './index';

export class FacEquipmentMeterHistory {
   public equipmentMeterHistoryId: number = 0;
   public equipmentMeterId: number = 0;
   public readingDate: Date = new Date(0);
   public meterReading: number = 0;
   public userName: string = null;
   public newMeter: boolean = null;
   public employeeId: number = null;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public equipmentMeter: FacEquipmentMeters = null;
   public tenant: NgTenants = null;
}