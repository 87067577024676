
import { TrQualTasks } from './index';
import { NgTenants } from './index';

export class TrQualTaskXPrerequisites {
   public qualTaskPreReqId: number = 0;
   public qualTaskId: number = 0;
   public preReqQualTaskId: number = 0;
   public tenantId: number = 0;
   public preReqQualTask: TrQualTasks = null;
   public qualTask: TrQualTasks = null;
   public tenant: NgTenants = null;
}