
import { HrEmployees } from './index';
import { TrQualTasks } from './index';
import { NgTenants } from './index';

export class TrQtHistory {
   public qtHistoryId: number = 0;
   public qualTaskId: number = 0;
   public employeeId: number = 0;
   public completedOn: Date = new Date(0);
   public trainedById: number = null;
   public certifiedById: number = null;
   public revokedOn: Date = null;
   public revokedById: number = null;
   public revokedReason: string = null;
   public revoked: boolean = false;
   public tenantId: number = 0;
   public certifiedBy: HrEmployees = null;
   public employee: HrEmployees = null;
   public qualTask: TrQualTasks = null;
   public revokedBy: HrEmployees = null;
   public tenant: NgTenants = null;
   public trainedBy: HrEmployees = null;
}