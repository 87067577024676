
import { HrEmployeeJobAssignments } from './index';
import { TrQuals } from './index';
import { NgTenants } from './index';

export class TrQualXJobAssignments {
   public qualJobAssignmentId: number = 0;
   public qualId: number = 0;
   public employeeJobAssignmentId: number = 0;
   public tenantId: number = 0;
   public employeeJobAssignment: HrEmployeeJobAssignments = null;
   public qual: TrQuals = null;
   public tenant: NgTenants = null;
}