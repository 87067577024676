import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ComponentEnum } from './component.enum';
import { TransferHttpService, CacheItem } from '../transfer-http/transfer-http.service';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, forkJoin, map, Observable } from 'rxjs';
import { HrEmployees, NgComponents, NgEmployeeXpermissionGroup, NgPermissionGroups, NgPermissions } from 'src/models';
import { AuthService } from '../auth/auth.service';
import { AimsCommonTablePermissions } from '@aims/common-components';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private transferHttp: TransferHttpService) {
  }

  //Backward compatibility
  public readonly ComponentID = ComponentEnum;

  //Converted these to subjects
  permissions: BehaviorSubject<Set<Permission>> = new BehaviorSubject(new Set<Permission>());
  user: BehaviorSubject<any> = new BehaviorSubject(null);
  //Aims Admin overrides all permissions to true
  isAimsAdmin: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** Export comes from Read permission */
  getDataGridPerms$(componentId: number, 
    permissionsOverride: {create?: boolean, read?: boolean, update?: boolean, delete?: boolean, export?: boolean, filter?: boolean} = {create: null, read: null, update: null, delete: null, filter: null}
    ): Observable<AimsCommonTablePermissions>{
    return combineLatest(<Array<Observable<boolean>>>[
      this.canCreate$(componentId), 
      this.canRead$(componentId), 
      this.canUpdate$(componentId), 
      this.canDelete$(componentId)
    ])
    .pipe(
      map(([c,r,u,d]) => {
        return <AimsCommonTablePermissions>{
          create: permissionsOverride.create ?? c,
          read: permissionsOverride.read ?? r,
          update: permissionsOverride.update ?? u,
          delete: permissionsOverride.delete ?? d,
          export: permissionsOverride.export ?? r,
          filter: permissionsOverride.filter
        }
      })
    )
  }

  getDataGridPermsObs$(componentId: number, 
    permissionsOverride: BehaviorSubject<AimsCommonTablePermissions>
    ): Observable<AimsCommonTablePermissions>{
    return combineLatest(
      { 
        create: this.canCreate$(componentId), 
        read: this.canRead$(componentId), 
        update: this.canUpdate$(componentId), 
        delete: this.canDelete$(componentId),
        override: permissionsOverride
      }
    )
    .pipe(
      map((perms) => {
        let retVal = <AimsCommonTablePermissions>{
          create: perms.override.create ?? perms.create,
          read: perms.override.read ?? perms.read,
          update: perms.override.update ?? perms.update,
          delete: perms.override.delete ?? perms.delete,
          export: perms.override.export ?? perms.read,
          filter: perms.override.filter ?? perms.read
        };
        return retVal;
      })
    )
  }

  canRead$(componentId: number): Observable<boolean> {
    return this.permissions.pipe(
      map(perms => {
        if (perms){
          for (let perm of perms) {
            if (perm.componentId == componentId && perm.read == true) {
              return true;
            }
          }
        }
        return false || this.isAimsAdmin.value;
      })
    )
  }

  canRead(componentId: number): boolean {
    if (this.permissions.value !== undefined) {
      for (let perm of this.permissions.value) {
        if (perm.componentId == componentId && perm.read == true) {
          return true;
        }
      }
    }

    return false || this.isAimsAdmin.value;
  }

  canCreate$(componentId: number): Observable<boolean> {
    return this.permissions.pipe(
      map(perms => {
         if (perms){
          for (let perm of perms) {
            if (perm.componentId == componentId && perm.create == true) {
              return true;
            }
          }
        }
        return false || this.isAimsAdmin.value;
      })
    )
  }

  canCreate(componentId: number): boolean {
    if (this.permissions.value !== undefined) {
      for (let perm of this.permissions.value) {
        if (perm.componentId == componentId && perm.create == true) {
          return true;
        }
      }
    }

    return false || this.isAimsAdmin.value;
  }

  canUpdate$(componentId: number): Observable<boolean> {
    return this.permissions.pipe(
      map(perms => {
         if (perms){
          for (let perm of perms) {
            if (perm.componentId == componentId && perm.update == true) {
              return true;
            }
          }
        }
        return false || this.isAimsAdmin.value;
      })
    )
  }

  canUpdate(componentId: number): boolean {
    if (this.permissions.value !== undefined) {
      for (let perm of this.permissions.value) {
        if (perm.componentId == componentId && perm.update == true) {
          return true;
        }
      }
    }
    return false || this.isAimsAdmin.value;
  }

  canDelete$(componentId: number): Observable<boolean> {
    return this.permissions.pipe(
      map(perms => {
        if (perms){
          for (let perm of perms) {
            if (perm.componentId == componentId && perm.delete == true) {
              return true;
            }
          }
        }
        return false || this.isAimsAdmin.value;
      })
    )
  }

  canDelete(componentId: number): boolean {
    if (this.permissions.value !== undefined) {
      for (let perm of this.permissions.value) {
        if (perm.componentId == componentId && perm.delete == true) {
          return true;
        }
      }
    }
    return false || this.isAimsAdmin.value;
  }


  //Get
  getComponents():Observable<any>{
    return this.transferHttp.getSingle('Permissions/GetComponents');
  }
  getGroupMembership(groupId:number):Observable<any>{
    return this.transferHttp.getSingle('Permissions/GetGroupMembership?groupId=' + groupId);
  }
  getGroupPermissionsOnly(groupId:number):Observable<Array<NgPermissions>>{
    return this.transferHttp.getSingle<Array<NgPermissions>>('Permissions/GetGroupPermissionsOnly?groupId=' + groupId);
  }
  getPermissionGroups(): Observable<any>{
    return this.transferHttp.getSingle('Permissions/GetPermissionGroups');
  }
  getUsers():Observable<any>{
    return this.transferHttp.getSingle('Permissions/GetUsers');
  }
  getUserGroups(userId: number){
    return this.transferHttp.getSingle<Array<NgEmployeeXpermissionGroup>>('Permissions/GetUserGroups?userId='+userId);
  }
  getUserPermissions():Observable<any>{
    return this.transferHttp.getSingle(`Permissions/GetUserPermissions`);
  }
  getUserPermissionsOnly(userId:number):Observable<Array<NgPermissions>>{
    return this.transferHttp.getSingle<Array<NgPermissions>>('Permissions/GetUserPermissionsOnly?userId=' + userId);
  }
  getUserPurchaseAuth(userId:number){
    return this.transferHttp.getSingle('Permissions/GetUserPurchaseAuth?userId=' + userId);
  }
  getPermissionsReport(userId:number):Observable<any>{
    return this.transferHttp.getSingle('Permissions/GetPermissionsReport?userId=' + userId);
  }
  getComponentReport(componentId:number):Observable<any>{
    return this.transferHttp.getSingle(`Permissions/GetComponentReport?componentId=${componentId}`);
  }
  //Delete
  deleteComponent(componentId:number){
    return this.transferHttp.delete('Permissions/DeleteComponent?componentId=' + componentId)
  }
  deletePermissionGroup(groupId:number){
    return this.transferHttp.delete('Permissions/DeletePermissionGroup?groupId=' + groupId);
  }
  deleteAllPermissions() {
    this.permissions.next(new Set([]));
    this.user.next(null);
  }
  deletePermission(permissionId: number){
    return this.transferHttp.delete('Permissions/DeletePermission?permissionId='+permissionId);
  }
  deleteGroupMember(employeeXpermissionGroupId: number){
    return this.transferHttp.delete('Permissions/DeleteGroupMember?employeeXpermissionGroupId='+employeeXpermissionGroupId);
  }
  //Save
  saveComponent(component:NgComponents){
    return this.transferHttp.put('Permissions/SaveComponent',component);
  }
  savePermissionGroup(group:NgPermissionGroups): Observable<any>{
    return this.transferHttp.put('Permissions/SavePermissionGroup', group);
  }
  savePermission(permission:NgPermissions) {
    return this.transferHttp.put('Permissions/SavePermission',permission);
  }
  saveMembership(employeeXpermissionGroupId:number,permissionGroupId:number,userId:number,employeeId:number){
    return this.transferHttp.put('Permissions/SaveGroupMember?employeeXpermissionGroupId='+employeeXpermissionGroupId+'&permissionGroupId='+permissionGroupId+'&userId='+userId+'&employeeId='+employeeId, null);
  }
  savePurchaseAuth(userId:number, arctecFunds:number, oaFunds: number, govtFunds: number){
    return this.transferHttp.put('Permissions/SavePurchaseAuth?userId=' + userId + '&arctecFunds=' + arctecFunds + '&oaFunds=' + oaFunds + '&govtFunds=' + govtFunds, null)
  }
  changeUserActivation(userId:number){
    return this.transferHttp.post('Permissions/ChangeUserActivation?userId='+userId, null)
  }
  saveUser(userId:number, userName:string, email:string, password:string, employeeId:number, generatePwd:boolean){
    return this.transferHttp.put(`Permissions/SaveUser`, {
      userId: userId,
      userName: userName,
      email: email,
      password: password,
      employeeId: employeeId,
      generatePwd: generatePwd
    });
  }
  saveUserPassword(userId:number, password:null){
    return this.transferHttp.post(`Permissions/UpdatePassword`, { userId: userId, password: password})
  }
}

class Permission {
  userId: number;
  componentId: number;
  create: boolean;
  createProvider: number;
  read: boolean;
  readProvider: number;
  update: boolean;
  updateProvider: number;
  delete: boolean;
  deleteProvider: number;
}

//For Testing
@Injectable()
export class MockPermissionsService extends PermissionsService {
  canCreate(componentId: number): boolean { return true; }
  canRead(componentId: number): boolean { return true; }
  canUpdate(componentId: number): boolean { return true; }
  canDelete(componentId: number): boolean { return true; }
  getComponents(): Observable<any>{return new Observable()}
  getPermissionGroups(): Observable<any>{return new Observable()}
  setPermissionGroup(group:NgPermissionGroups): Observable<any>{return new Observable()}
  saveComponent(component:NgComponents): Observable<any>{return new Observable()}
  user: BehaviorSubject<any> = new BehaviorSubject({userId: 0})

}
