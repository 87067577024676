
import { SchTravelRequests } from './index';
import { NgTenants } from './index';

export class SchFlightAuthType {
   public flightAuthTypeId: number = 0;
   public flightAuthType: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public schTravelRequests: SchTravelRequests[] = [];
   public tenant: NgTenants = null;
}