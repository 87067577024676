import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { NgModel } from '@angular/forms'
import { MatButton } from '@angular/material/button'
import { ButtonClick } from './aims-common-button.interface'

@Component({
  selector: 'aims-common-button',
  templateUrl: './aims-common-button.component.html',
  styleUrls: ['./aims-common-button.component.css'],
})
export class AimsCommonButtonComponent implements OnInit {
  @Input() disabled = false

  constructor() {}

  ngOnInit(): void {}
}
