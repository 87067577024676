
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrPscType {
   public pscTypeId: number = 0;
   public pscType: string = null;
   public hrAuditRequired: boolean = false;
   public wsLeadReviewRequired: boolean = false;
   public dmApprovalRequired: boolean = false;
   public pmmApprovalRequired: boolean = false;
   public hrmApprovalRequired: boolean = false;
   public acctReceivalRequired: boolean = false;
   public itReceivalRequired: boolean = false;
   public canSelfService: boolean = false;
   public dmSelfService: boolean = false;
   public tenantId: number = 0;
   public hrPsc: HrPsc[] = [];
   public tenant: NgTenants = null;
}