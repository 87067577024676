
import { FacEquipment } from './index';
import { FacWeeklyReadingsHistory } from './index';
import { FacMeterUnits } from './index';
import { FacWeeklyReadingCategories } from './index';
import { NgTenants } from './index';

export class FacWeeklyReadings {
   public weeklyReadingId: number = 0;
   public readingCategoryId: number = null;
   public equipmentId: number = 0;
   public readingDescription: string = null;
   public meterUnitId: string = null;
   public meterIdMp2: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public equipment: FacEquipment = null;
   public facWeeklyReadingsHistory: FacWeeklyReadingsHistory[] = [];
   public meterUnit: FacMeterUnits = null;
   public readingCategory: FacWeeklyReadingCategories = null;
   public tenant: NgTenants = null;
}