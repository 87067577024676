
import { HrEmployees } from './index';
import { HrOcc } from './index';
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrEmploymentType {
   public employmentTypeId: number = 0;
   public employmentTypeCode: string = null;
   public employmentType: string = null;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public hrOcc: HrOcc[] = [];
   public hrPsc: HrPsc[] = [];
   public tenant: NgTenants = null;
}