
import { NgTenants } from './index';

export class HrEeoType {
   public eeoId: number = 0;
   public eeoType: string = null;
   public eeoCode: string = null;
   public tenantId: number = 0;
   public active: boolean = false;
   public tenant: NgTenants = null;
}