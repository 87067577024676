<h2 mat-dialog-title>
  {{ title }}
</h2>
<mat-dialog-content>
  <ng-template *ngComponentOutlet="component; injector: injector"></ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <aims-common-button (click)="onCancel($event)" attr.aria-label="{{ btnCancelText }}" >
    {{ btnCancelText }}
  </aims-common-button>
  <aims-common-button
    attr.aria-label="{{ btnOkText }}"
    (click)="onSave($event)"
    [disabled]="!(formGroup.valid && (formGroup.touched || formGroup.dirty))"
  >
    <mat-icon>save</mat-icon>
    {{ btnOkText }}
  </aims-common-button>
</mat-dialog-actions>
