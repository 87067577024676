
import { HrEmployees } from './index';
import { FacFuelPeriodStatus } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class FacMogasEstimates {
   public mogasEstimateId: string = '00000000-0000-0000-0000-000000000000';
   public enteredBy: string = null;
   public amountInGallons: number = 0;
   public date: Date = new Date(0);
   public siteId: number = 0;
   public fuelPeriodStatusId: string = '00000000-0000-0000-0000-000000000000';
   public lastUpdatedBy: string = null;
   public enteredById: number = null;
   public lastUpdatedById: number = null;
   public tenantId: number = 0;
   public enteredByNavigation: HrEmployees = null;
   public fuelPeriodStatus: FacFuelPeriodStatus = null;
   public lastUpdatedByNavigation: HrEmployees = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}