
import { BinPartNumbers } from './index';
import { BinsAtSites } from './index';
import { BinCategories } from './index';
import { BinShopCodes } from './index';
import { BinSourceOfSupply } from './index';
import { BinStatusCodes } from './index';

export class BinMaster {
   public binNumber: string = null;
   public shopCode: string = null;
   public categoryCode: string = null;
   public accountable: boolean = false;
   public obsolete: boolean = false;
   public comment: string = null;
   public statusCode: string = null;
   public difm: boolean = false;
   public partDescription: string = null;
   public unitOfIssue: string = null;
   public critical: boolean = false;
   public sosCode: string = null;
   public netCost: number = null;
   public standardCost: number = null;
   public q13: boolean = false;
   public createdOn: Date = null;
   public lastEditedOn: Date = null;
   public lastUpdated: Array<any> = [];
   public esd: boolean = null;
   public itemNo: string = null;
   public createdById: number = null;
   public lastEditedById: number = null;
   public tenantId: number = 0;
   public id: number = 0;
   public binPartNumbers: BinPartNumbers[] = [];
   public binsAtSites: BinsAtSites[] = [];
   public categoryCodeNavigation: BinCategories = null;
   public shopCodeNavigation: BinShopCodes = null;
   public sosCodeNavigation: BinSourceOfSupply = null;
   public statusCodeNavigation: BinStatusCodes = null;
}