
import { NgTenants } from './index';
import { HrWorkSections } from './index';

export class WorkSectionBudgets {
   public workSectionBudgetsId: number = 0;
   public workSectionId: number = 0;
   public budget: number = 0;
   public beginDate: Date = new Date(0);
   public endDate: Date = new Date(0);
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public workSection: HrWorkSections = null;
}