 <select class="filterSelection form-control"
        name="filter"
        [(ngModel)]="selectedFilterId"
        (change)="filterSelectChange($event)"
        placeholder="Filter Select..."
      >
        <option [value]="undefined" disabled >Select...</option>
        <option *ngFor="let f of filterSelection" [value]="f.filterId">
          {{ f.label }}
        </option>
</select>
