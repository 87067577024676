
import { HrEmployees } from './index';
import { NgTenants } from './index';

export class HrMaritalStatus {
   public maritalStatusId: number = 0;
   public maritalStatusCode: string = null;
   public maritalStatus: string = null;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public tenant: NgTenants = null;
}