
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { AccTimeEntryHeader } from './index';

export class AccTimeEntryComments {
   public timeEntryCommentId: number = 0;
   public timeEntryHeaderId: number = 0;
   public comment: string = null;
   public employeeId: number = 0;
   public createdDate: Date = new Date(0);
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public tenant: NgTenants = null;
   public timeEntryHeader: AccTimeEntryHeader = null;
}