
import { LogReqHeaderAudit } from './index';
import { LogReqHeader } from './index';

export class LogReqPriorities {
   public priorityId: number = 0;
   public priority: number = 0;
   public priorityDescription: string = null;
   public active: boolean = false;
   public daysOut: number = 0;
   public tenantId: number = 0;
   public isDefault: boolean = false;
   public logReqHeaderAudit: LogReqHeaderAudit[] = [];
   public logReqHeaderPriority: LogReqHeader[] = [];
   public logReqHeaderShippingPriority: LogReqHeader[] = [];
}