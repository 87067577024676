import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferHttpService } from '../transfer-http/transfer-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private transferHttp: TransferHttpService) { }

  // TODO: this is unused, remove it (and problably the whole service)
  getPasswordExpiration(userId:number):Observable<any>{
    return this.transferHttp.getSingle<any>('User/GetPasswordExpiration?userId=' + userId);
  }
}
