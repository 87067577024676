
import { QcInspectionSequences } from './index';
import { QcReportSchedule } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class QcReportScheduleAsRequired {
   public reportScheduleAsRequiredId: number = 0;
   public reportScheduleId: number = 0;
   public siteId: number = null;
   public trackingNumber: string = null;
   public inspectionSequenceId: number = 0;
   public reportScheduleComment: string = null;
   public dateDue: Date = null;
   public dateSubmittedToQc: Date = null;
   public dateSubmittedToAf: Date = null;
   public tenantId: number = 0;
   public inspectionSequence: QcInspectionSequences = null;
   public reportSchedule: QcReportSchedule = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}