
import { HrEmployees } from './index';
import { FacEquipment } from './index';

export class FacEquipmentDocument {
   public equipmentDocumentId: number = 0;
   public equipmentId: number = 0;
   public comment: string = null;
   public attachmentName: string = null;
   public attachmentGuid: string = null;
   public attachmentType: string = null;
   public addedById: number = 0;
   public isDisplay: boolean = false;
   public display: string = null;
   public tenantId: number = 0;
   public addedBy: HrEmployees = null;
   public equipment: FacEquipment = null;
}