
import { NgComponents } from './index';
import { HrEmployees } from './index';
import { NgPermissionGroups } from './index';
import { NgTenants } from './index';
import { NgUsers } from './index';

export class NgPermissions {
   public permissionId: number = 0;
   public componentId: number = 0;
   public employeeId: number = null;
   public permissionGroupId: number = null;
   public create: boolean = false;
   public read: boolean = false;
   public update: boolean = false;
   public delete: boolean = false;
   public tenantId: number = 0;
   public userId: number = null;
   public component: NgComponents = null;
   public employee: HrEmployees = null;
   public permissionGroup: NgPermissionGroups = null;
   public tenant: NgTenants = null;
   public user: NgUsers = null;
}