
import { LogReqHeader } from './index';
import { Vend } from './index';
import { VendAddr } from './index';

export class LogRfqs {
   public rfqId: number = 0;
   public rfqNumber: string = null;
   public reqId: string = null;
   public employeeId: number = 0;
   public vendorId: string = null;
   public vendorAddrDc: string = null;
   public dateRequired: Date = new Date(0);
   public dateOfInquiry: Date = new Date(0);
   public awarded: boolean = false;
   public quotedAmount: number = null;
   public addnlShipping: number = null;
   public quoteDoc: Array<any> = [];
   public quoteDocName: string = null;
   public quoteDocType: string = null;
   public company: string = null;
   public contactName: string = null;
   public tenantId: number = 0;
   public vendor: string = null;
   public vendorAddr: string = null;
   public req: LogReqHeader = null;
   public vend: Vend = null;
   public vendAddr: VendAddr = null;
}