
import { HrEmployees } from './index';
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacOilAdditions {
   public oilAdditionId: number = 0;
   public equipmentId: number = 0;
   public dateAdded: Date = new Date(0);
   public scheduled: boolean = null;
   public quantity: number = 0;
   public addedBy: string = null;
   public employeeId: number = null;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public equipment: FacEquipment = null;
   public tenant: NgTenants = null;
}