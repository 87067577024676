import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aims-common-labeltooltip',
  templateUrl: './aims-common-labeltooltip.component.html',
  styleUrls: ['./aims-common-labeltooltip.component.css']
})
export class AimsCommonLabeltooltipComponent implements OnInit {
  @Input() labelText: string;
  @Input() tooltipText: string;
  showTooltip = false;

  constructor() {
    this.labelText = '';
    this.tooltipText = '';
  }

  ngOnInit(): void {
  }

}
