
import { HrJobTitles } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';
import { AllTravelSites } from './index';

export class SchMinPermPos {
   public minPermPosId: number = 0;
   public siteId: number = 0;
   public jobTitleId: number = 0;
   public minimum: number = 0;
   public tenantId: number = 0;
   public travelSiteId: number = null;
   public jobTitle: HrJobTitles = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
   public travelSite: AllTravelSites = null;
}