
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacAssetStatus {
   public assetStatusId: number = 0;
   public assetStatus: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public facEquipment: FacEquipment[] = [];
   public tenant: NgTenants = null;
}