
import { SchTravelRequests } from './index';
import { NgTenants } from './index';

export class SchRentalCarAuthType {
   public rentalCarAuthTypeId: number = 0;
   public rentalCarAuthType: string = null;
   public tenantId: number = 0;
   public active: boolean = false;
   public schTravelRequests: SchTravelRequests[] = [];
   public tenant: NgTenants = null;
}