
import { QcObservations } from './index';
import { NgTenants } from './index';

export class QcAreas {
   public areaId: number = 0;
   public area: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcObservations: QcObservations[] = [];
   public tenant: NgTenants = null;
}