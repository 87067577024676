
import { QcPubIndexTypes } from './index';
import { QcObservations } from './index';
import { QcQuestions } from './index';
import { NgTenants } from './index';

export class QcPublications {
   public publicationId: number = 0;
   public pubNum: string = null;
   public pubTitle: string = null;
   public pubIndexTypeId: number = 0;
   public active: boolean = false;
   public tenantId: number = 0;
   public pubIndexType: QcPubIndexTypes = null;
   public qcObservations: QcObservations[] = [];
   public qcQuestions: QcQuestions[] = [];
   public tenant: NgTenants = null;
}