
import { QcPublications } from './index';
import { QcResponsibleAssignments } from './index';
import { NgTenants } from './index';

export class QcQuestions {
   public questionId: number = 0;
   public question: string = null;
   public pwsReference: string = null;
   public altReference: string = null;
   public selfInspection: boolean = false;
   public preInspection: boolean = false;
   public inspectorElc: boolean = false;
   public inspectorFac: boolean = false;
   public responsibleAssignmentId: number = null;
   public pubReferenceId: number = null;
   public pubRefParagraph: string = null;
   public areas: string = null;
   public sites: string = null;
   public tenantId: number = 0;
   public pubReference: QcPublications = null;
   public responsibleAssignment: QcResponsibleAssignments = null;
   public tenant: NgTenants = null;
}