
import { HrEmployeeXShareholderType } from './index';
import { NgTenants } from './index';

export class HrShareholderType {
   public shareholderTypeId: number = 0;
   public shareholderType: string = null;
   public tenantId: number = 0;
   public hrEmployeeXShareholderType: HrEmployeeXShareholderType[] = [];
   public tenant: NgTenants = null;
}