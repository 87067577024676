import { Injectable, Injector, PLATFORM_ID, Inject, makeStateKey, TransferState } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {AsyncSubject, BehaviorSubject, interval, Observable, of} from 'rxjs';
import { map, share, take } from 'rxjs/operators';

import {HrEmployees, LogReqHeader, LogReqHeaderAudit, LogReqRoutingSections} from '../../../../src/models';
import { isPlatformBrowser } from '@angular/common';
import {TravelRequest} from '../../containers/scheduling/schCommon';

@Injectable({
  providedIn: 'root'
})
export class TransferHttpService {

  constructor(private transferState: TransferState,
              private http: HttpClient,
              private injector: Injector,
              @Inject(PLATFORM_ID) private platformId) {

  }

  private cacheSubjects: { [key: string]: BehaviorSubject<any> } = {};

  private get router(): Router {
    return this.injector.get(Router);
  }

  reloadCache(): void {
    this.dumpCache();
    this.getSingle(`Common/GetTransferData`)
      .subscribe(transferData => {
        if (this.loadTransferData(transferData)) {
          const cachedTenantId = this.getCache(CacheItem.tenantId);
          console.log('APP INIT COMPLETE', cachedTenantId);
        }
      });
  }

  loadTransferData(transferData: any): boolean {
    if (transferData) {
      Object.keys(transferData)
        .forEach(key => {
          if (transferData[key] !== null && transferData[key] !== undefined) {
            this.transferState.set(makeStateKey(key), (transferData[key].value !== undefined) ? transferData[key].value : transferData[key]);
          }
        });

        return true;
    }

    return false;
  }

  dumpCache(): void {
    const cache = JSON.parse(this.transferState.toJson());
    for (const key of Object.keys(cache)){
        this.transferState.remove(makeStateKey(key));
    }
  }

  getCache<T = any>(key: string): T {
    const stateKey = makeStateKey<T>(key);
    let cachedValue = this.transferState.get<T>(stateKey, null);

    // Check local storage otherwise and create state value from there
    if (cachedValue === null && isPlatformBrowser(this.platformId) && localStorage.getItem(key)) {
      cachedValue = JSON.parse(localStorage.getItem(key)) as T;
      this.transferState.set<T>(stateKey, cachedValue);
    }

    // If Array filter by tenantId
    if (cachedValue instanceof Array)
    {
      let v = cachedValue.filter(v => v.tenantId == this.getCache(CacheItem.user).tenantId);
      if (v.length > 0 && key == CacheItem.tenantConfig){
        v = v[0];
      }
      cachedValue = v as unknown as T;
    }

    return cachedValue;
  }

  getCacheSubject<T = any>(key: string): BehaviorSubject<T> {
    const cachedValue = this.getCache(key);

    // Create or get BehaviorSubject
    let subject = this.cacheSubjects[key];
    if (!subject) {
      subject = new BehaviorSubject<T>(cachedValue);
      this.cacheSubjects[key] = subject;
    }

    return subject;
  }

  setCacheSubject<T = any>(key: string, value: T): void {
    const stateKey = makeStateKey<T>(key);
    this.transferState.set<T>(stateKey, value);

    // Update BehaviorSubject
    let subject = this.cacheSubjects[key];
    if (!subject) {
      subject = new BehaviorSubject<T>(value);
      this.cacheSubjects[key] = subject;
    } else {
      subject.next(value);
    }
  }

  get tenantConfig(): TenantConfig {
    return this.getCache<TenantConfig>(CacheItem.tenantConfig);
  }

  request(uri: string | Request, options?: any): Observable<any> {
    const url = (uri instanceof Request) ? uri.url : uri;
    // return this.getData(uri, options, (url: string, options: any) => {
    return this.http.request(url, options);
    // });
  }
  /**
   * Performs a request with `get` http method and returns a hot observable.
   */
  getHot<T = any>(url: string): Observable<T> {
    return this.get(url).pipe(
      share({ connector: () => new AsyncSubject(), resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false })
    );
  }

  /**
   * Performs a request with `get` http method and returns the first result as hot observable.
   * Use for dropdowns primarily
   */
  getSingle<T = any>(url: string): Observable<T> {
    return this.getHot<T>(url).pipe(take(1));
  }

  /*
  When pared with the Server Side function DBHelpers.AIMSSave()
  This function handles create and update for EF objects and their children
  Will reload object on page if update, will navigate to <current url>/<new object id> if create
  Function will emit "true" to show the spinner when it fires off then "false" when it completes
  So you don't need to do the showSpinner = true
  Usage Example:

  this.spinnerText = "Saving...";
  this.transferHttp.save("Logistics/Test", this.sr).subscribe(show => {
      this.showSpinner = show;
  });

  */
  save(url: string, entityToSave: any, navigate: boolean = true, urlOverride: string = null): BehaviorSubject<boolean> {

    const ret: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // true to show spinner initially

    // Return object if works, return string if error for display purposes
    this.put(url, entityToSave)
      .pipe(
        // takeUntil(ret), //destroys subscription after emitting
        map((retObj: RetObj) => {
          // Error handling
          if (retObj.errorMessage !== undefined) {
            throw new Error(retObj.errorMessage);
          }
          return retObj;
        })
      )
      .subscribe((retObj: RetObj) => {
        // If id exists we reload if not we navigate
        if (document.URL.indexOf(retObj.id.toString()) != -1) {
          entityToSave = retObj.savedEntity;
          ret.next(false);  // False to hide spinner
        }
        else {
          if (navigate) {
            const navurl = (urlOverride) ? urlOverride : this.router.url;
            this.router.navigate([`${navurl}/${retObj.id}`]);
          }
          else {
            ret.next(false);
          }
        }

      },
        () => {
          ret.next(false);
        }
      );

    return ret;

  }

  // Does save as above, but without any actions and auto unsubscribe
  backgroundSave(url: string, entityToSave: any): void {
    const sub$ = this.save(url, entityToSave, false).subscribe(saving => {
      if (!saving) {
        sub$.unsubscribe();
      }
    });
  }

  /**
   * Performs a request with `get` http method.
   */
  get(url: string, options?: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getData(url, options, (url: string, options: any) => {
    return this.http.get(url, options);
    // });
  }
  /**
   * Performs a request with `post` http method.
   */
  post<T = any>(url: string, body: any, options?: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getPostData(url, body, options, (url: string, options: any) => {
    return this.http.post<T>(url, body, options);
    // });
  }
  /**
   * Performs a request with `put` http method.
   */
  put(url: string, body: any, options?: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getPostData(url, body, options, (url: string, options: any) => {
    return this.http.put(url, body, options);
    // });
  }
  /**
   * Performs a request with `delete` http method.
   */
  delete(url: string, options?: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getData(url, options, (url: string, options: any) => {
    return this.http.delete(url, options);
    // });
  }
  /**
   * Performs a request with `patch` http method.
   */
  patch(url: string, body: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getPostData(url, body, options, (url: string, options: any) => {
    return this.http.patch(url, body.options);
    // });
  }
  /**
   * Performs a request with `head` http method.
   */
  head(url: string, options?: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getData(url, options, (url: string, options: any) => {
    return this.http.head(url, options);
    // });
  }
  /**
   * Performs a request with `options` http method.
   */
  options(url: string, options?: any): Observable<any> {
    // url = this.handleUrl(url);
    // return this.getData(url, options, (url: string, options: any) => {
    return this.http.options(url, options);
    // });
  }
}

class RetObj {
  errorMessage: string;
  id: number;
  savedEntity: any;
}

export enum CacheItem {
  user = 'user',
  allEmployees = 'employeeDropdown',
  activeEmployees = 'activeEmployeeDropdown',
  sites = 'sitesDropdown',
  isDev = 'isDev',
  permissions = 'permissions',
  tenantId = 'tenantId',
  tenantConfig = 'tenantConfig',
  workSections = 'workSectionsDropdown'
}

@Injectable()
export class MockTransferHttpService extends TransferHttpService {
  get(url: string, options?: any): Observable<any> {
    return of([]);
  }

  getSingle(url: string): Observable<any> {
    return of([])
  }

  put(url: string, body: any, options?: any): Observable<any> {
    return of([]);
  }

  post(url: string, body: any, options?: any): Observable<any> {
    return of([]);
  }

  delete(url: string, options?: any): Observable<any> {
    return of([]);
  }

  save(url: string, entityToSave: any, navigate: boolean = true, urlOverride: string = null): BehaviorSubject<boolean> {
    return null;
  }

  get shoppingCartRoute(): LogReqRoutingSections {
    return {
      routingSectionId: 999,
      routingSection: 'TestString',
      routingDepartment: 'TestString',
      holdsUnsubmitted:  false,
      routeToBuyerIfSufficientApprovalAuth:  false,
      isQuote:  false,
      isBuy:  false,
      isShippable:  false,
      isEditable:  false,
      showBuyerColumns:  false,
      showPcaColumns: false,
      order: 999,
      componentId: 999,
      tenantId: 999,
      logReqHeader: [new LogReqHeader(), new LogReqHeader()],
      logReqHeaderAudit: [new LogReqHeaderAudit(), new LogReqHeaderAudit()],
      routingConfig: "[{routeTo:\"next\"}]",
      isReceivable: false,
      showCorrectReqButton: false,
      showForceCloseReqButton: false,
      showParkReqButton: false,
      showRejectToUserButton: false,
      showRejectToValidatorButton: false,
      showRejectToPcabutton: false,
      showSplitReqButton: false,
      showSubmitButton: false,
      showRequoteButton: false,
      parkingLotStatuses: null,
      invConfig: [],
      showCorrectCodingButton: false
    };
  }

  get setDefaults() {
    return {
      currentRouteId: 999,
      nextRouteId: 999
    };
  }

  get LogReqRoutingSections(): LogReqRoutingSections {
    return {
      routingSectionId: 999,
      routingSection: 'TestString',
      routingDepartment: 'TestString',
      holdsUnsubmitted:  false,
      routeToBuyerIfSufficientApprovalAuth:  false,
      isQuote:  false,
      isBuy:  false,
      isShippable:  false,
      isEditable:  false,
      showBuyerColumns:  false,
      showPcaColumns: false,
      order: 999,
      componentId: 999,
      tenantId: 999,
      logReqHeader: [new LogReqHeader(), new LogReqHeader()],
      logReqHeaderAudit: [new LogReqHeaderAudit(), new LogReqHeaderAudit()],
      routingConfig: "[{routeTo:\"next\"}]",
      isReceivable: false,
      showCorrectReqButton: false,
      showForceCloseReqButton: false,
      showParkReqButton: false,
      showRejectToUserButton: false,
      showRejectToValidatorButton: false,
      showRejectToPcabutton: false,
      showSplitReqButton: false,
      showSubmitButton: false,
      showRequoteButton: false,
      parkingLotStatuses: null,
      invConfig: [],
      showCorrectCodingButton: false
    };
  }
  get AllJobControlNumbersBySiteCode() {
    return {
        siteCode: 999
    };
  }

  get AllWorkOrdersBySite() {
    return {
      siteCode: 999
    };
  }

  get Observation() {
    return {
      siteId: 999,
      observationId: 999
    };
  }



  get TravelRequestDetail(): TravelRequest {
    return {
      approvedBy: null,
      approvedById: 999,
      approvedOn: null,
      charter: false,
      confirmedOn: null,
      createdBy: null,
      createdById: 999,
      createdOn: null,
      employee: null,
      employeeId: 999,
      flightAuthType: null,
      flightAuthTypeId: 999,
      groundTime: 999,
      lodgingAuthType: null,
      lodgingAuthTypeId: 999,
      militaryTransportAuthorized: false,
      modifiedBy: null,
      modifiedById: 999,
      modifiedOn: null,
      nonRefundableAuth: false,
      qualityControlledBy: null,
      qualityControlledById: 999,
      qualityControlledOn: null,
      rentalCarAuthType: null,
      rentalCarAuthTypeId: 999,
      requestedBy: null,
      requestedById: 999,
      requestedOn: null,
      resource: null,
      resourceId: 999,
      schTravelRequestBags: [null, null, null],
      schTravelRequestLegs: [null, null, null],
      sentToTravelAgent: false,
      submittedOn: null,
      tenant: null,
      tenantId: 999,
      travelRequestComments: '',
      travelRequestStatus: null,
      travelRequestStatusId: 999,
      travelWeight: 999,
      travelRequestId: 999
    };
  }

  get tenantConfig(): TenantConfig {
    return {
      log: {
        // reqRouting: [new LogReqRoutingSections()] // there were two of these defined and angular errors now for v 11.
        reqRouting: [],
        siteIdForShowSiteColumn: [13]
      },
      qc: {
        showJCNTab: true
      },
      tenantId: 1,
      sharePointLink: null,
      hr: {
        tenantUsesPsc: true,
        tenantRequiresPersonalInfo: false,
        activeStatusIds: [1, 2],
        empConfig: {
            defaultHomeStateId: 999,
            defaultEmployeeStatusId: 999,
            defaultPermSiteId: 999
        }
      },
      tr: {
        tenantAllowsUpdatingOwnQuals: true
      },
      facilities: null,
      wo: {
        defaultSiteId: 999,
        defaultTypeId: 999,
        defaultOriginId: 999,
        routeAfterCloseId: 999,
        routeAfterSubmit: 999,
        defaultRouteId: 999,
        scheduledMaintenanceTypeId: 999
      },
      projectSupport: {
          oaProjectName: "Default"
      }
    };
  }

  getCache(key: string): any {
    if (key === CacheItem.user) {
      const dummyUser = new HrEmployees();
      dummyUser.loginName = 'travis.boyle';
      dummyUser.employeeId = 1228;
      dummyUser.defaultSiteId = 13;
      return dummyUser;
    } else if (key === (CacheItem.allEmployees || 'sitesDropdown' || 'employeeDropdown')) {
      return interval(1000);
    }
    else if (key === CacheItem.isDev) {
      return true;
    }

    return [];
  }
}


class ShortEmp {
  employeeId: number;
  employeeNumber: number;
  fullName: string;
  loginName: string;
}

export interface TenantConfig {
  tenantId: number;
  sharePointLink: string;
  hr: HrConfig;
  tr: TrConfig;
  qc: QcConfig;
  facilities: FacConfig;
  wo: WorkOrderConfig;
  log: LogConfig;
  projectSupport: ProjectSupportConfig;
}

export interface ProjectSupportConfig {
    oaProjectName: string;
}

export interface LogConfig {
  reqRouting: Array<LogReqRoutingSections>;
  siteIdForShowSiteColumn: Array<number>;
}

export interface WorkOrderConfig {
  defaultSiteId: number;
  defaultTypeId: number;
  defaultOriginId: number;
  routeAfterCloseId: number;
  routeAfterSubmit: number;
  defaultRouteId: number;
  scheduledMaintenanceTypeId: number;
}

interface FacConfig {
  defaultSiteId: number;
}

interface QcConfig {
  showJCNTab: boolean;
}

export interface EmployeeConfig {
  // ** HomeStateId */
  defaultHomeStateId: number;
  defaultEmployeeStatusId: number;
  // ** PermanentSiteId */
  defaultPermSiteId: number;
}

export interface HrConfig {
  tenantUsesPsc: boolean;
  // Don't require personal info section
  tenantRequiresPersonalInfo: boolean;
  // Active or EOA Status Ids
  activeStatusIds: number[];
  // ** Gets tenant specific config for employee component */
  empConfig: EmployeeConfig;
}

export interface TrConfig {
 tenantAllowsUpdatingOwnQuals: boolean;
}
