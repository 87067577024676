
import { LogCatalogCategory } from './index';
import { LogCatalogSubCategories } from './index';
import { LogReqLineItems } from './index';
import { Vend } from './index';

export class LogCatalog {
   public catalogId: number = 0;
   public nomenclature: string = null;
   public partNumber: string = null;
   public manufacturer: string = null;
   public catalogCategoryId: number = 0;
   public catalogSubCategoryId: number = null;
   public vendId: string = null;
   public companyId: string = null;
   public vendorPrice: number = null;
   public vendorPartNumber: string = null;
   public createdOn: Date = new Date(0);
   public tenantId: number = 0;
   public catalogCategory: LogCatalogCategory = null;
   public catalogSubCategory: LogCatalogSubCategories = null;
   public logReqLineItems: LogReqLineItems[] = [];
   public vend: Vend = null;
}