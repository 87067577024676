
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { WoWorkOrderDetailLabor } from './index';
import { WoWorkOrders } from './index';

export class WoWorkOrderDetails {
   public workOrderDetailId: number = 0;
   public workOrderId: number = 0;
   public workDate: Date = new Date(0);
   public workDescription: string = null;
   public createdById: number = 0;
   public createdOn: Date = new Date(0);
   public lastUpdatedById: number = 0;
   public oldId: number = null;
   public tenantId: number = 0;
   public createdBy: HrEmployees = null;
   public lastUpdatedBy: HrEmployees = null;
   public tenant: NgTenants = null;
   public woWorkOrderDetailLabor: WoWorkOrderDetailLabor[] = [];
   public workOrder: WoWorkOrders = null;
}