
import { NgPermissions } from './index';

export class NgComponents {
   public componentId: number = 0;
   public component: string = null;
   public parentComponentId: number = null;
   public description: string = null;
   public inverseParentComponent: NgComponents[] = [];
   public ngPermissions: NgPermissions[] = [];
   public parentComponent: NgComponents = null;
}