
export class Fractions {
  static tankDipFracs: Array<Fraction> = [
    { text: "0/8", decimal: .0 },
    { text: "1/8", decimal: .125 },
    { text: '1/4', decimal: .25 },
    { text: '3/8', decimal: .375 },
    { text: '1/2', decimal: .5 },
    { text: '5/8', decimal: .625 },
    { text: '3/4', decimal: .75 },
    { text: '7/8', decimal: .875 }
  ]
}

class Fraction {
  text: string;
  decimal: number;
}
