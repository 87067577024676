
import { HrEmployees } from './index';
import { FacEquipment } from './index';
import { NgTenants } from './index';
import { WoWorkOrderDetails } from './index';
import { WoWorkOrderTypes } from './index';

export class WoWorkOrderDetailLabor {
   public workOrderDetailLaborId: number = 0;
   public workOrderDetailId: number = 0;
   public employeeId: number = 0;
   public laborHours: number = 0;
   public emergencyTime: boolean = false;
   public workTypeId: number = 0;
   public createdById: number = 0;
   public lastUpdatedById: number = 0;
   public createdOn: Date = new Date(0);
   public updatedOn: Date = new Date(0);
   public equipmentId: number = null;
   public tenantId: number = 0;
   public createdBy: HrEmployees = null;
   public employee: HrEmployees = null;
   public equipment: FacEquipment = null;
   public lastUpdatedBy: HrEmployees = null;
   public tenant: NgTenants = null;
   public workOrderDetail: WoWorkOrderDetails = null;
   public workType: WoWorkOrderTypes = null;
}