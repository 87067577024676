
import { FacFuelPeriodStatus } from './index';
import { HrEmployees } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class FacFuelReceipts {
   public fuelReceiptId: number = 0;
   public receiptDate: Date = new Date(0);
   public fuelType: string = null;
   public qty: number = 0;
   public receiptQty: number = 0;
   public siteId: number = 0;
   public receivedBy: string = null;
   public lastUpdatedBy: string = null;
   public vendor: string = null;
   public fuelPeriodStatusId: string = '00000000-0000-0000-0000-000000000000';
   public receivedById: number = null;
   public lastUpdatedById: number = null;
   public tenantId: number = 0;
   public fuelPeriodStatus: FacFuelPeriodStatus = null;
   public lastUpdatedByNavigation: HrEmployees = null;
   public receivedByNavigation: HrEmployees = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}