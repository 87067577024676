
import { HrEmployees } from './index';
import { EngCadDisciplines } from './index';
import { EngDrawingFormat } from './index';
import { EngDrawingSource } from './index';
import { EngDrawingStatus } from './index';
import { EngCadDrawingTypes } from './index';
import { EngCadDrawingHistory } from './index';
import { EngCadSheetTypes } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class EngCadDrawings {
   public cadDrawingId: number = 0;
   public drawingNum: string = null;
   public pageNum: number = 0;
   public revisionDate: Date = null;
   public revision: string = null;
   public drawingTitle: string = null;
   public comments: string = null;
   public siteId: number = 0;
   public installationDate: Date = null;
   public coordinatorRevDate: Date = null;
   public siteRevBy: string = null;
   public siteLastRevDate: Date = null;
   public drafter: string = null;
   public revDrafter: string = null;
   public disciplineId: number = null;
   public sheetTypeId: number = null;
   public drawingTypeId: number = null;
   public locationId: number = null;
   public assignedDrafter: number = null;
   public buildingNumber: string = null;
   public drawingSource: number = null;
   public drawingStatus: number = 0;
   public originalPageNum: string = null;
   public originalDrawingNum: string = null;
   public reviewedBy: number = null;
   public drawingFormat: number = null;
   public referenceNum: string = null;
   public lastUpdatedBy: string = null;
   public lastUpdateDate: Date = null;
   public createdOn: Date = null;
   public tenantId: number = 0;
   public assignedDrafterNavigation: HrEmployees = null;
   public discipline: EngCadDisciplines = null;
   public drawingFormatNavigation: EngDrawingFormat = null;
   public drawingSourceNavigation: EngDrawingSource = null;
   public drawingStatusNavigation: EngDrawingStatus = null;
   public drawingType: EngCadDrawingTypes = null;
   public engCadDrawingHistory: EngCadDrawingHistory[] = [];
   public reviewedByNavigation: HrEmployees = null;
   public sheetType: EngCadSheetTypes = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}