
import { HrEmployees } from './index';
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacGeneratorsDeadlined {
   public deadlinedId: number = 0;
   public equipmentId: number = null;
   public deadlinedById: number = null;
   public deadlinedDate: Date = null;
   public estRepairDate: Date = null;
   public deadlinedReason: string = null;
   public dateRepaired: Date = null;
   public repairedById: number = null;
   public workOrderNumber: string = null;
   public tenantId: number = 0;
   public deadlinedBy: HrEmployees = null;
   public equipment: FacEquipment = null;
   public repairedBy: HrEmployees = null;
   public tenant: NgTenants = null;
}