
import { FacRefrigerantCylinders } from './index';
import { HrEmployees } from './index';
import { FacEquipment } from './index';
import { NgTenants } from './index';
import { FacTransferTypes } from './index';

export class FacRefrigerantTransfers {
   public refrigerantTransferId: number = 0;
   public cylinderId: number = 0;
   public equipmentId: number = null;
   public transferDate: Date = null;
   public transferTypeId: number = null;
   public lastEditedOn: Date = null;
   public employeeId: number = 0;
   public refrigerantQty: number = null;
   public cylinderGrossWeightBeforeXfer: number = 0;
   public cylinderGrossWeightAfterXfer: number = 0;
   public transferCylinderId: number = null;
   public tenantId: number = 0;
   public cylinder: FacRefrigerantCylinders = null;
   public employee: HrEmployees = null;
   public equipment: FacEquipment = null;
   public tenant: NgTenants = null;
   public transferCylinder: FacRefrigerantCylinders = null;
   public transferType: FacTransferTypes = null;
}