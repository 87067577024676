
import { FacEquipment } from './index';
import { FacFuelPeriodStatus } from './index';
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { FacFuelThirdParties } from './index';

export class FacFuelIssues {
   public fuelTransferId: number = 0;
   public transferDate: Date = new Date(0);
   public siteId: number = null;
   public quantityIssued: number = 0;
   public poc: string = null;
   public transferedBy: string = null;
   public thirdPartyId: number = null;
   public equipmentId: number = null;
   public fuelType: string = null;
   public vehicleType: string = null;
   public idRegNumber: string = null;
   public siteOther: string = null;
   public lastUpdatedBy: string = null;
   public siteUse: boolean = false;
   public fuelPeriodStatusId: string = '00000000-0000-0000-0000-000000000000';
   public transferredById: number = null;
   public lastUpdatedById: number = null;
   public tenantId: number = 0;
   public equipment: FacEquipment = null;
   public fuelPeriodStatus: FacFuelPeriodStatus = null;
   public lastUpdatedByNavigation: HrEmployees = null;
   public tenant: NgTenants = null;
   public thirdParty: FacFuelThirdParties = null;
   public transferredBy: HrEmployees = null;
}