

export class PoHdr {
   public poId: string = null;
   public poRlseNo: number = 0;
   public poChngOrdNo: number = 0;
   public sPoType: string = null;
   public buyerId: string = null;
   public vendId: string = null;
   public addrDc: string = null;
   public sPoStatusType: string = null;
   public poTotAmt: number = 0;
   public salesTaxAmt: number = 0;
   public blktAmt: number = 0;
   public totRlsedAmt: number = 0;
   public cntactLastName: string = null;
   public cntactFirstName: string = null;
   public ordDt: Date = new Date(0);
   public fobFld: string = null;
   public retainPoFl: string = null;
   public poPrntdFl: string = null;
   public cnfrmFl: string = null;
   public acknFl: string = null;
   public acknDt: Date = null;
   public vendSoId: string = null;
   public termsDc: string = null;
   public perfStartDt: Date = null;
   public perfEndDt: Date = null;
   public rlseFromBlktFl: string = null;
   public rlseLnTotFl: string = null;
   public grossUnitCstFl: string = null;
   public excdBlktTotFl: string = null;
   public brnchLocId: string = null;
   public brnchAddrDc: string = null;
   public billToLocId: string = null;
   public billToAddrDc: string = null;
   public chngDt: Date = null;
   public apprvlDt: Date = null;
   public apprvlUserId: string = null;
   public procureTypeCd: string = null;
   public phoneId: string = null;
   public faxId: string = null;
   public modifiedBy: string = null;
   public timeStamp: Date = new Date(0);
   public rowversion: number = null;
   public lockFl: string = null;
   public vchrdAmt: number = 0;
   public lastChngDtt: Date = null;
   public lastModDtt: Date = new Date(0);
   public gsaFl: string = null;
   public costSavingAmt: number = 0;
   public trnBlktAmt: number = 0;
   public trnCstSavingAmt: number = 0;
   public trnPoTotAmt: number = 0;
   public trnSalesTaxAmt: number = 0;
   public trnTotRlsedAmt: number = 0;
   public trnVchrdAmt: number = 0;
   public rateGrpId: string = null;
   public trnCrncyCd: string = null;
   public trnToEurRt: number = 0;
   public eurToFuncRt: number = 0;
   public trnCrncyDt: Date = null;
   public trnFreezeRtFl: string = null;
   public trnToEurRtFl: string = null;
   public clDisadvFl: string = null;
   public clHistBlClgFl: string = null;
   public clLabSrplFl: string = null;
   public clWomOwnFl: string = null;
   public cisCd: string = null;
   public companyId: string = null;
   public relToVendFl: string = null;
   public relToVendDt: Date = null;
   public usCitizenFl: string = null;
   public itarAuthFl: string = null;
   public secClrCd: string = null;
   public agencyName: string = null;
   public poTypeChngDtt: Date = null;
   public buyOrgId: string = null;
   public portalEmailFl: string = null;
   public cntactEmailId: string = null;
   public subcntrId: string = null;
}