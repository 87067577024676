
import { QcObservations } from './index';
import { NgTenants } from './index';

export class QcObservationRequiredActions {
   public observationRequiredActionId: number = 0;
   public observationId: number = 0;
   public orderNumber: number = 0;
   public requiredAction: string = null;
   public completedOn: Date = null;
   public completedById: number = null;
   public certifiedOn: Date = null;
   public certifiedById: number = null;
   public tenantId: number = 0;
   public observation: QcObservations = null;
   public tenant: NgTenants = null;
}