
import { AccResponsePremiumType } from './index';
import { NgTenants } from './index';
import { AccTimeEntryHeader } from './index';

export class AccResponsePremiums {
   public responsePremiumId: number = 0;
   public timeEntryHeaderId: number = 0;
   public responsePremiumTypeId: number = 0;
   public monday: boolean = false;
   public tuesday: boolean = false;
   public wednesday: boolean = false;
   public thursday: boolean = false;
   public friday: boolean = false;
   public saturday: boolean = false;
   public sunday: boolean = false;
   public tenantId: number = 0;
   public responsePremiumType: AccResponsePremiumType = null;
   public tenant: NgTenants = null;
   public timeEntryHeader: AccTimeEntryHeader = null;
}