
import { TrQualTasks } from './index';
import { NgTenants } from './index';
import { TrTrainers } from './index';

export class TrQualTaskXTrainers {
   public qualTaskTrainerId: number = 0;
   public qualTaskId: number = 0;
   public trainerId: number = 0;
   public tenantId: number = 0;
   public qualTask: TrQualTasks = null;
   public tenant: NgTenants = null;
   public trainer: TrTrainers = null;
}