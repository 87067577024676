import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
} from '@angular/core'

@Directive({
  selector: 'ng-template[aimsCommonTableDynamicRow]',
})
export class AimsCommonTableDynamicRowDirective {
  @Input() id: string

  constructor(public host: TemplateRef<ElementRef>) {}
}
