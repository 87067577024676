
import { SchScheduleItem } from './index';
import { NgTenants } from './index';

export class SchFirearms {
   public firearmId: number = 0;
   public make: string = null;
   public model: string = null;
   public caliber: string = null;
   public serialNumber: string = null;
   public scheduleItemId: number = 0;
   public approvedById: number = null;
   public approvedOn: Date = null;
   public disapprovedById: number = null;
   public disapprovedOn: Date = null;
   public tenantId: number = 0;
   public scheduleItem: SchScheduleItem = null;
   public tenant: NgTenants = null;
}