
import { SchScheduleItem } from './index';
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class AccCopas {
   public copaId: number = 0;
   public copaNumber: string = null;
   public copaDescription: string = null;
   public nteLaborHours: number = 0;
   public nteMaterialCost: number = 0;
   public nteTransportationCost: number = 0;
   public projIdPrefix: string = null;
   public laborTaskCode: string = null;
   public travelLaborTaskCode: string = null;
   public wxDelayLaborTaskCode: string = null;
   public materialPurchaseTaskCode: string = null;
   public travelPurchaseTaskCode: string = null;
   public negotiatedLaborRate: number = null;
   public tenantId: number = 0;
   public schScheduleItem: SchScheduleItem[] = [];
   public tenant: NgTenants = null;
   public woWorkOrders: WoWorkOrders[] = [];
}