
import { EngOaComments } from './index';
import { EngOaEngineerPocs } from './index';
import { EngOaSites } from './index';
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class EngOverAndAboveProjects {
   public oanumber: string = null;
   public projId: string = null;
   public ga: number = null;
   public overHead: number = null;
   public profit: number = null;
   public profitPercent: number = null;
   public completedOnDate: Date = null;
   public projectDescription: string = null;
   public sites: string = null;
   public engineerPoc: string = null;
   public rfpreceivedDate: Date = null;
   public proposalSubmittedDate: Date = null;
   public proposalPrice: number = null;
   public approvedPrice: number = null;
   public noticeToProceedDate: Date = null;
   public contractCompletionDate: Date = null;
   public davisBacon: boolean = null;
   public equipNeeds: string = null;
   public safetyPlan: Date = null;
   public enviroReview: Date = null;
   public crewNeeds: string = null;
   public airfieldWaiver: string = null;
   public downtimeStatus: string = null;
   public govFreight: string = null;
   public schedConstStartDate: Date = null;
   public approxWorkDuration: string = null;
   public installationCompDate: Date = null;
   public percentComplete: number = 0;
   public drafting: string = null;
   public comments: string = null;
   public nonProductivePercent: number = null;
   public payrollTaxPercent: number = null;
   public overheadPercent: number = null;
   public subcontractors: string = null;
   public sitePoc: string = null;
   public tenantId: number = 0;
   public engOaComments: EngOaComments[] = [];
   public engOaEngineerPocs: EngOaEngineerPocs[] = [];
   public engOaSites: EngOaSites[] = [];
   public tenant: NgTenants = null;
   public woWorkOrders: WoWorkOrders[] = [];
}