
import { SchScheduleItem } from './index';
import { NgTenants } from './index';

export class SchScheduleItemType {
   public scheduleItemTypeId: number = 0;
   public scheduleItemType: string = null;
   public requiresApproval: boolean = false;
   public isEmployeeSiType: boolean = false;
   public govBilledDefault: boolean = false;
   public companyBilledDefault: boolean = false;
   public mealsDefault: boolean = false;
   public billetingDefault: boolean = false;
   public color: string = null;
   public icon: string = null;
   public tenantId: number = 0;
   public showTravelInfo: boolean = false;
   public showBillingInfo: boolean = false;
   public showAccommodationsInfo: boolean = false;
   public showRestrictionsInfo: boolean = false;
   public active: boolean = false;
   public scheduleItemSystemTypeId: number = null;
   public schScheduleItem: SchScheduleItem[] = [];
   public tenant: NgTenants = null;
}