
import { HrEmployees } from './index';
import { TrQualTasks } from './index';
import { NgTenants } from './index';

export class TrQtNotRequired {
   public qtNotRequiredId: number = 0;
   public qualTaskId: number = 0;
   public employeeId: number = 0;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public qualTask: TrQualTasks = null;
   public tenant: NgTenants = null;
}