
import { LogReqRoutingSections } from './index';
import { LogReqStatuses } from './index';

export class InvConfig {
   public inventoryConfigId: number = 0;
   public inventoryItemNumberLength: number = 0;
   public useAutoNumbering: boolean = false;
   public autoNumberMask: string = null;
   public tenantId: number = 0;
   public autoNumberStartValue: number = 0;
   public autoNumberCurrentValue: number = 0;
   public reorderRoutingSectionId: number = null;
   public allowAutoRefill: boolean = false;
   public reorderStatusId: number = null;
   public reorderEmpId: number = null;
   public allowQuickCreateWorkOrder: boolean = false;
   public reorderRoutingSection: LogReqRoutingSections = null;
   public reorderStatus: LogReqStatuses = null;
}