
import { QcObservations } from './index';
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class QcObservationXworkOrders {
   public observationXworkOrderId: number = 0;
   public observationId: number = 0;
   public workOrderId: number = 0;
   public tenantId: number = 0;
   public observation: QcObservations = null;
   public tenant: NgTenants = null;
   public workOrder: WoWorkOrders = null;
}