
import { HrClearanceStatus } from './index';
import { HrDepartments } from './index';
import { HrEmployees } from './index';
import { AllStatesOrTerritories } from './index';
import { HrEmployeeStatus } from './index';
import { HrEmploymentStatus } from './index';
import { HrEmploymentType } from './index';
import { HrPscComments } from './index';
import { HrPscOcc } from './index';
import { HrEmployeeJobAssignments } from './index';
import { HrJobTitles } from './index';
import { AllSites } from './index';
import { HrPscType } from './index';
import { NgTenants } from './index';
import { HrUnionAffiliation } from './index';
import { HrFyPayRates } from './index';
import { HrWorkSections } from './index';

export class HrPsc {
   public pscId: number = 0;
   public pscTypeId: number = 0;
   public employeeId: number = 0;
   public effectiveOn: Date = new Date(0);
   public createdBy: string = null;
   public createdOn: Date = new Date(0);
   public hrAuditedBy: string = null;
   public hrAuditedOn: Date = null;
   public audited: boolean = false;
   public submitted: boolean = false;
   public rejected: boolean = false;
   public accepted: boolean = false;
   public wsLeadReviewedBy: string = null;
   public wsLeadReviewedOn: Date = null;
   public dmApprovedBy: string = null;
   public dmApprovedOn: Date = null;
   public pmmApprovedBy: string = null;
   public pmmApprovedOn: Date = null;
   public hrmApprovedBy: string = null;
   public hrmApprovedOn: Date = null;
   public acctReceivedBy: string = null;
   public acctReceivedOn: Date = null;
   public itReceivedBy: string = null;
   public itReceivedOn: Date = null;
   public rejectedBy: string = null;
   public rejectedOn: Date = null;
   public lastNameFrom: string = null;
   public lastNameTo: string = null;
   public firstNameFrom: string = null;
   public firstNameTo: string = null;
   public middleNameFrom: string = null;
   public middleNameTo: string = null;
   public miFrom: string = null;
   public miTo: string = null;
   public akaFrom: string = null;
   public akaTo: string = null;
   public loginNameFrom: string = null;
   public loginNameTo: string = null;
   public exemptFrom: boolean = false;
   public exemptTo: boolean = false;
   public employeeStatusFrom: string = null;
   public employeeStatusTo: string = null;
   public employeeStatusToId: number = null;
   public employmentStatusFrom: string = null;
   public employmentStatusTo: string = null;
   public employmentStatusToId: number = null;
   public employmentTypeFrom: string = null;
   public employmentTypeTo: string = null;
   public employmentTypeToId: number = null;
   public permanentSiteCodeFrom: string = null;
   public permanentSiteCodeTo: string = null;
   public permanentSiteCodeToId: number = null;
   public unionAffiliationCodeFrom: string = null;
   public unionAffiliationCodeTo: string = null;
   public unionAffiliationCodeToId: number = null;
   public departmentFrom: string = null;
   public departmentTo: string = null;
   public departmentToId: number = null;
   public workSectionFrom: string = null;
   public workSectionTo: string = null;
   public workSectionToId: number = null;
   public jobTitleFrom: string = null;
   public jobTitleTo: string = null;
   public jobTitleToId: number = null;
   public jobAssignmentFrom: string = null;
   public jobAssignmentTo: string = null;
   public jobAssignmentToId: number = null;
   public hourlyRateFrom: number = 0;
   public hourlyRateTo: number = 0;
   public overTimeRateFrom: number = 0;
   public overTimeRateTo: number = 0;
   public salaryFrom: number = 0;
   public salaryTo: number = 0;
   public biWeeklyRateFrom: number = 0;
   public biWeeklyRateTo: number = 0;
   public probationStartFrom: Date = null;
   public probationStartTo: Date = null;
   public probationaryCompletionDateFrom: Date = null;
   public probationaryCompletionDateTo: Date = null;
   public homeAddressFrom: string = null;
   public homeAddressTo: string = null;
   public homeCityFrom: string = null;
   public homeCityTo: string = null;
   public homeStateFrom: string = null;
   public homeStateTo: string = null;
   public homeStateToId: number = null;
   public homeZipFrom: string = null;
   public homeZipTo: string = null;
   public mailingAddressFrom: string = null;
   public mailingAddressTo: string = null;
   public mailingCityFrom: string = null;
   public mailingCityTo: string = null;
   public mailingStateFrom: string = null;
   public mailingStateTo: string = null;
   public mailingStateToId: number = null;
   public mailingZipFrom: string = null;
   public mailingZipTo: string = null;
   public primaryPhoneFrom: string = null;
   public primaryPhoneTo: string = null;
   public alternatePhoneFrom: string = null;
   public alternatePhoneTo: string = null;
   public emergencyContactLastNameFrom: string = null;
   public emergencyContactLastNameTo: string = null;
   public emergencyContactFirstNameFrom: string = null;
   public emergencyContactFirstNameTo: string = null;
   public emergencyContactPrimaryPhoneFrom: string = null;
   public emergencyContactPrimaryPhoneTo: string = null;
   public emergencyContactAlternatePhoneFrom: string = null;
   public emergencyContactAlternatePhoneTo: string = null;
   public driversLicenseFrom: string = null;
   public driversLicenseTo: string = null;
   public driversLicenseStateFrom: string = null;
   public driversLicenseStateTo: string = null;
   public driversLicenseStateToId: number = null;
   public driversLicenseExpDateFrom: Date = null;
   public driversLicenseExpDateTo: Date = null;
   public bridgedDateOfHireFrom: Date = null;
   public bridgedDateOfHireTo: Date = null;
   public recallRightsFrom: string = null;
   public recallRightsTo: string = null;
   public dispatchDateFrom: Date = null;
   public dispatchDateTo: Date = null;
   public originalDateOfHireFrom: Date = null;
   public originalDateOfHireTo: Date = null;
   public eligibleForRehireFrom: boolean = false;
   public eligibleForRehireTo: boolean = false;
   public clearanceStatusFrom: string = null;
   public clearanceStatusTo: string = null;
   public clearanceStatusToId: number = null;
   public clearanceDateFrom: Date = null;
   public clearanceDateTo: Date = null;
   public unionHourlyRateFromId: number = null;
   public unionHourlyRateToId: number = null;
   public printedHr: boolean = false;
   public printedAcct: boolean = false;
   public dmAppEmpId: number = null;
   public pmmAppEmpId: number = null;
   public hrmAppEmpId: number = null;
   public cacExpDateFrom: Date = null;
   public cacExpDateTo: Date = null;
   public recallExpireDateFrom: Date = null;
   public recallExpireDateTo: Date = null;
   public maritalStatusFrom: string = null;
   public maritalStatusTo: string = null;
   public maritalStatusToId: number = null;
   public tenantId: number = 0;
   public clearanceStatusToNavigation: HrClearanceStatus = null;
   public departmentToNavigation: HrDepartments = null;
   public dmAppEmp: HrEmployees = null;
   public driversLicenseStateToNavigation: AllStatesOrTerritories = null;
   public employee: HrEmployees = null;
   public employeeStatusToNavigation: HrEmployeeStatus = null;
   public employmentStatusToNavigation: HrEmploymentStatus = null;
   public employmentTypeToNavigation: HrEmploymentType = null;
   public homeStateToNavigation: AllStatesOrTerritories = null;
   public hrPscComments: HrPscComments[] = [];
   public hrPscOcc: HrPscOcc[] = [];
   public hrmAppEmp: HrEmployees = null;
   public jobAssignmentToNavigation: HrEmployeeJobAssignments = null;
   public jobTitleToNavigation: HrJobTitles = null;
   public mailingStateToNavigation: AllStatesOrTerritories = null;
   public permanentSiteCodeToNavigation: AllSites = null;
   public pmmAppEmp: HrEmployees = null;
   public pscType: HrPscType = null;
   public tenant: NgTenants = null;
   public unionAffiliationCodeToNavigation: HrUnionAffiliation = null;
   public unionHourlyRateFrom: HrFyPayRates = null;
   public unionHourlyRateTo: HrFyPayRates = null;
   public workSectionToNavigation: HrWorkSections = null;
}