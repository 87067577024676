
import { FacWasteDrums } from './index';
import { NgTenants } from './index';

export class FacWasteTypes {
   public wasteTypeId: number = 0;
   public wasteType: string = null;
   public hazardous: boolean = null;
   public tenantId: number = 0;
   public facWasteDrums: FacWasteDrums[] = [];
   public tenant: NgTenants = null;
}