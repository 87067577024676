<div class="tooltip-container">
    <span class="labeltext"
        (mouseenter) = "showTooltip = true"
        (mouseleave)= " showTooltip = false">
        {{ labelText }}
    </span>
     <div class="tooltiptext" *ngIf="showTooltip">
        {{ tooltipText }}
    </div>
</div>
