<h1 mat-dialog-title cdkFocusRegionStart>
  {{ title }}
</h1>
<mat-dialog-content>
  <p [innerHTML]="message"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <ng-template [ngIf]="btnCancelText != ''">
    <aims-common-button attr.aria-label="{{ btnCancelText }}" class="aims-button" (click)="onDismiss()">
      {{ btnCancelText }}
    </aims-common-button>
  </ng-template>
  <ng-template [ngIf]="btnOkText != ''">
    <aims-common-button attr.aria-label="{{ btnOkText }}" class="aims-button" (click)="onConfirm()">
      <ng-container *ngIf="showMatIcon(btnOkText)">
        <mat-icon color="{{ getConfirmButtonColor(btnOkText) }}">{{btnIconText}}</mat-icon>
      </ng-container>
      {{ btnOkText }}
    </aims-common-button>
  </ng-template>
</mat-dialog-actions>
