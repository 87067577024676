
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacEquipmentOwners {
   public equipmentOwnerIdOld: string = null;
   public equipmentOwner: string = null;
   public equipmentOwnerId: number = 0;
   public tenantId: number = 0;
   public active: boolean = false;
   public facEquipment: FacEquipment[] = [];
   public tenant: NgTenants = null;
}