<div class="date-container">
  <mat-form-field appearance="fill" class="form-filter" [style.width.px]="column && column.width">
    <mat-label>Filter</mat-label>
    <input matInput [matDatepicker]="picker" [value]="dateValue" (dateInput)="filterChange($event)" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <button
      mat-button
      type="button"
      *ngIf="date && range"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearFilter($event, column)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button mat-icon-button [matMenuTriggerFor]="datemenu" aria-label="Filter date">
    <mat-icon>filter_alt</mat-icon>
  </button>
  <mat-menu #datemenu="matMenu">
    <button mat-menu-item (click)="rangeClick('Is equal to')">Is equal to</button>
    <button mat-menu-item (click)="rangeClick('Is not equal to')">Is not equal to</button>
    <button mat-menu-item (click)="rangeClick('Is before')">Is before</button>
    <button mat-menu-item (click)="rangeClick('Is after')">Is after</button>
    <button mat-menu-item (click)="rangeClick('Is Null')">Is Null</button>
    <button mat-menu-item (click)="rangeClick('Is not Null')">Is not Null</button>
  </mat-menu>
</div>
