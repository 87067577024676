
import { OpsTravelRequests } from './index';
import { SchTravelRequestLegs } from './index';
import { NgTenants } from './index';

export class OpsTaskCodes {
   public taskCodeId: number = 0;
   public taskCode: string = null;
   public taskAcctCode: string = null;
   public tenantId: number = 0;
   public opsTravelRequests: OpsTravelRequests[] = [];
   public schTravelRequestLegs: SchTravelRequestLegs[] = [];
   public tenant: NgTenants = null;
}