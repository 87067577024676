
import { QcInspectionReports } from './index';
import { NgTenants } from './index';

export class QcInspectionReferences {
   public inspectionReferenceId: number = 0;
   public inspectionReference: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcInspectionReportsRef1: QcInspectionReports[] = [];
   public qcInspectionReportsRef2: QcInspectionReports[] = [];
   public qcInspectionReportsRef3: QcInspectionReports[] = [];
   public qcInspectionReportsRef4: QcInspectionReports[] = [];
   public qcInspectionReportsRef5: QcInspectionReports[] = [];
   public qcInspectionReportsRef6: QcInspectionReports[] = [];
   public qcInspectionReportsRef7: QcInspectionReports[] = [];
   public qcInspectionReportsRef8: QcInspectionReports[] = [];
   public qcInspectionReportsRef9: QcInspectionReports[] = [];
   public tenant: NgTenants = null;
}