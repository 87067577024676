
import { InvTravelerStatus } from './index';

export class InvTraveler {
   public travelerId: number = 0;
   public description: string = null;
   public revision: string = null;
   public status: string = null;
   public tenantId: number = 0;
   public statusNavigation: InvTravelerStatus = null;
}