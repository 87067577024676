
import { QcObservationComments } from './index';
import { NgTenants } from './index';

export class QcObservationCommentTypes {
   public observationCommentTypeId: number = 0;
   public observationCommentType: string = null;
   public forCarsOnly: boolean = false;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcObservationComments: QcObservationComments[] = [];
   public tenant: NgTenants = null;
}