
import { InvAttachedItemPulled } from './index';
import { InvInventoryItem } from './index';

export class InvInventoryItemReceived {
   public inventoryItemReceivedId: number = 0;
   public inventoryItemId: number = null;
   public quantity: number = null;
   public receivedDate: Date = new Date(0);
   public costPerUnit: number = 0;
   public ibnLineItem: number = null;
   public tenantId: number = 0;
   public returnable: boolean = false;
   public originalQuantity: number = null;
   public invAttachedItemPulled: InvAttachedItemPulled[] = [];
   public inventoryItem: InvInventoryItem = null;
}