
import { FacEquipment } from './index';
import { ItAssetsHardware } from './index';
import { NgTenants } from './index';

export class AllSubLocations {
   public subLocationId: number = 0;
   public locationId: number = 0;
   public subLocation: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public facEquipment: FacEquipment[] = [];
   public itAssetsHardware: ItAssetsHardware[] = [];
   public tenant: NgTenants = null;
}