
import { LogReqLineItems } from './index';
import { LogReviewFlagAdditionalValidationWatch } from './index';

export class LogReviewFlags {
   public reviewFlagId: number = 0;
   public flagName: string = null;
   public flagCode: string = null;
   public tenantId: number = 0;
   public active: boolean = false;
   public logReqLineItems: LogReqLineItems[] = [];
   public logReviewFlagAdditionalValidationWatch: LogReviewFlagAdditionalValidationWatch[] = [];
}