
import { QcReportSchedule } from './index';
import { NgTenants } from './index';

export class QcReportScheduleFrequency {
   public reportScheduleFrequencyId: number = 0;
   public reportScheduleFrequency: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcReportSchedule: QcReportSchedule[] = [];
   public tenant: NgTenants = null;
}