import { Injectable } from '@angular/core'
import * as xlsx from 'xlsx'

@Injectable({
  providedIn: 'root',
})
export class ExportToExcelService {
  constructor() {}

  getFileName(name: string) {
    let timeSpan = new Date().toISOString()
    let sheetName = name || 'ExportResult'
    let fileName = `${sheetName}-${timeSpan}`
    return {
      sheetName,
      fileName,
    }
  }

  // Inspired by: https://stackblitz.com/edit/angular-material-table-export-excel-xlsx?file=app%2FtableUtil.ts
  exportArrayToExcel(arr: any[], name: string, headerHash: any, columnOrder: string[]) {
    const az = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    let { sheetName, fileName } = this.getFileName(name)
    var wb = xlsx.utils.book_new()
    var ws = xlsx.utils.json_to_sheet(arr, { header: columnOrder })

    for (var i = 0; i < az.length; i++) {
      const obj = ws[az.charAt(i) + '1']
      if (!obj) {
        break
      }

      const val = obj.v
      ws[az.charAt(i) + '1'].v = headerHash[val] || val
    }
    // xlsx.utils.sheet_add_aoa(ws, [headerLabels]); //heading: array of arrays
    xlsx.utils.book_append_sheet(wb, ws, sheetName)
    xlsx.writeFile(wb, `${fileName}.xlsx`)
  }
}
