
import { HrOcc } from './index';
import { NgTenants } from './index';

export class HrOccStatus {
   public occStatusId: number = 0;
   public occStatus: string = null;
   public sortOrder: number = 0;
   public tenantId: number = 0;
   public hrOcc: HrOcc[] = [];
   public tenant: NgTenants = null;
}