
import { SchResources } from './index';
import { NgTenants } from './index';

export class SchClearance {
   public clearanceId: number = 0;
   public clearanceName: string = null;
   public isDefault: boolean = false;
   public tenantId: number = 0;
   public schResources: SchResources[] = [];
   public tenant: NgTenants = null;
}