import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AimsCommonConfirmDialogModel } from '@aims/common-components';
import { DialogComponent,SpinnerText,DialogAction } from './message.enum';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() {}

  getDialogMessage(dialogComponent:number, customMessage?:string):AimsCommonConfirmDialogModel{
    let title = "";
    let message = "";
    let btnOkText = ""
    let btnCancelText = "Cancel" //Default text for the cancel button
    let btnIconText = "delete";
    let showIcon = false;

    if (!customMessage) {
      customMessage = "Unknown Error";
    }

    switch(dialogComponent){
      case DialogComponent.DeleteGroupPermissions:
        title = DialogAction.Delete + " Group?";
        message = "Are you really, really sure you want to delete this group?";
        btnOkText = DialogAction.Delete;
        break;
      case DialogComponent.DeactivateManageUsers:
        title = DialogAction.Deactivate + " User?";
        message = "Are you sure you want to deactivate this user?";
        btnOkText = DialogAction.Deactivate;
        break;
      case DialogComponent.DeleteComponent:
        title = DialogAction.Delete + " Component?";
        message = "Are you sure you want to delete this component?";
        btnOkText = DialogAction.Delete;
        break;
      case DialogComponent.DeleteRecord:
        title = DialogAction.Delete + " Record?";
        message = "Are you sure you want to permanently delete this record?";
        btnOkText = DialogAction.Delete;
        break;
      case DialogComponent.CloneUser:
        title = "Unable to clone User";
        message = "";
        btnOkText = DialogAction.Okay;
        btnCancelText = "";
        break;
      case DialogComponent.DeleteInventoryGroup:
        title = DialogAction.Delete + " Inventory Group?";
        message = "Are you sure you want to delete this Inventory Group?";
        btnOkText = DialogAction.Delete
        break;
      case DialogComponent.UnsavedChanges:
        title = "Unsaved Changes"
        message = "Are you sure you want to discard your changes?"
        btnOkText = DialogAction.Discard
        break;
      case DialogComponent.CloseTab:
        title = "Close Tab"
        message = "Would you like to close this tab?"
        btnOkText = DialogAction.Okay;
        break;
      case DialogComponent.AimsError:
        title = "AIMS Error";
        message = customMessage.replace(/\n/g,'<br/>'); //replacing \n to <br> other it's one line
        btnOkText = DialogAction.Okay;
        btnCancelText = ""
        break;
      case DialogComponent.AimsWarning:
        title = "AIMS Warning";
        message = customMessage.replace(/\n/g,'<br/>');
        btnOkText = DialogAction.Okay;
        btnCancelText = ""
        break;
      case DialogComponent.AimsInternetConnectivityError:
        title = "Internet Connectivity Issue"
        message = customMessage.replace(/\n/g,'<br/>');;
        btnOkText = DialogAction.Okay;
        btnCancelText = "";
        break;
      case DialogComponent.ConvertRequistion:
        title = "Convert to Req";
        message = "Are you sure you want to create a req with ALL of the items below?";
        btnOkText = DialogAction.Convert;
        break;
      case DialogComponent.CloseWorkOrder:
        title = "Close Work Order"
        message = "Are you sure you want to close the Work Order and submit it for approval? <br/><br/>Warning!! It will no longer be editable."
        btnOkText = DialogAction.Submit;
        break;
      case DialogComponent.RequestToApprove332:
        title = "Request to Approve a 332"
        message = "Are you sure you want to forward an offical request <br/> to approve a 332 to Engineering/Facilities Lead for review and place in STOP WORK status."
        + "<br/><br/>" + "YOU SHOULD HAVE ALREADY ENTERED YOUR LABOR ESTIMATES"

        btnOkText = DialogAction.Request
        break;
      case DialogComponent.ApproveWorkOrders:
        title = "Approve Work Orders";
        message = "Are you sure you want to approve these work orders?";
        btnOkText = DialogAction.Approve;
        btnIconText = "thumb_up_alt";
        showIcon = true;
        break;
      case DialogComponent.ApproveWorkOrder:
        title = "Approve Work Order";
        message = "Are you sure you want to approve this work order?";
        btnOkText = DialogAction.Approve;
        btnIconText = "thumb_up_alt";
        showIcon = true;
        break;
      case DialogComponent.RemoveItineraryConfirmation:
        title = "Remove Itinerary Confirmation"
        message = "Are you sure you want to remove itinerary confirmation?"
        btnOkText = DialogAction.Remove
        break;
      case DialogComponent.SendTravelRequestExternal:
        title = "Send Travel Request Travel Agency"
        message = "Are you sure you want to change the status to 'Sent'? <br/><br/> It will send out an email internally and to the Travel Agency!"
        btnOkText = DialogAction.Send
        break;
      case DialogComponent.SendTravelRequestInternal:
        title = "Send Travel Request Internally"
        message = "Are you sure you want to send out an email internally?"
        btnOkText = DialogAction.Send
        break;
      case DialogComponent.ChangeTravelRequest:
        title = "Change Travel Request"
        message = "Are you sure you want to change the status to 'Change'? <br/><br/> It must be re-submitted and approved!"
        btnOkText = DialogAction.Submit
        break;
      case DialogComponent.SendTravelRequestQuoteOnly:
        title = "Send Travel Request Quote Only"
        message = "Are you sure you want to change the status to 'Quote Only'? <br/><br/> It will send out an email!"
        btnOkText = DialogAction.Send
        break;
      case DialogComponent.CancelTravelRequest:
        title = "Cancel Travel Request"
        message = "Are you sure you want to change the status to 'Cancellation'? <br/><br/> It will send out an email!"
        btnOkText = DialogAction.Submit
        break;
      case DialogComponent.CancelTravelRequestNoEmail:
        title = "Cancel Travel Request"
        message = "Are you sure you want to change the status to 'Cancellation'?"
        btnOkText = DialogAction.Submit
        break;
      case DialogComponent.ConfirmTravelRequest:
        title = "Confirm Travel Request"
        message = "Are you sure you want to change the status to 'Confirmation'?"
        btnOkText = DialogAction.Submit
        break;
      case DialogComponent.CreatePoWarning:
        title = "Create PO Warning"
        message = customMessage + "<br/>This will create duplicate POs. You will need to delete any duplicates from CostPoint manually.<br/>Do you still want to create POs?"
        btnOkText = DialogAction.Submit
        break;
      case DialogComponent.ConfirmReceiveMaxQty:
        title = "Receivable Req Line Items"
        message = "Are you sure you want to receive the maximum quantity value for each selected rows, and all selected rows are <b>unable to attach any documents</b>?"
        btnOkText = DialogAction.Okay
        break;
    }
    return new AimsCommonConfirmDialogModel(title, message, btnOkText, btnCancelText, btnIconText, showIcon)
  }
  getSpinnerText(spinnerText:SpinnerText, additionalText?: string):string{
    let text = "";
    additionalText = additionalText ? " " + additionalText : "";
    switch(spinnerText){
      case SpinnerText.Delete:
        text = "Deleting";
        break;
      case SpinnerText.Save:
        text = "Saving";
        break;
      case SpinnerText.Create:
        text = "Creating";
        break;
      case SpinnerText.Generate:
        text = "Generating";
        break;
      case SpinnerText.Load:
        text = "Loading";
        break;
      case SpinnerText.Request:
        text = "Requesting"
        break;
    }
    return text + additionalText + "...";
  }
  getUserPasswordValidationMessage(){
    return of({
      'password': [
        { type: 'pattern', message: 'Your new password must be 8 characters long, must contain at least one uppercase, one lowercase, one number and one special character' },
        { type: 'contradiction', message: 'Your new password must not match your old password'}
      ],
      'confirmPassword': [
        { type: 'pattern', message: 'Your confirm password must be 8 characters long, contain at least one uppercase, one lowercase, one number and one special character'},
        { type: 'mismatch', message: 'Your new passwords do not match your confirm password' }
      ]
    })
  }
}
