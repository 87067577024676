
import { WoScheduledMaintenanceTemplates } from './index';

export class WoScheduledMaintenanceMonthly {
   public scheduledMaintenanceTemplateId: number = 0;
   public monthlyOption: string = null;
   public everyXmonths: number = null;
   public monthDay: number = null;
   public weekDay: number = null;
   public weekOccurence: number = null;
   public scheduledMaintenanceTemplate: WoScheduledMaintenanceTemplates = null;
}