
import { QcReportSchedule } from './index';
import { NgTenants } from './index';

export class QcReportScheduleSchedule {
   public reportScheduleSubmitId: number = 0;
   public reportScheduleId: number = 0;
   public dateDue: Date = null;
   public dateSubmittedToQc: Date = null;
   public dateSubmittedToAf: Date = null;
   public tenantId: number = 0;
   public reportSchedule: QcReportSchedule = null;
   public tenant: NgTenants = null;
}