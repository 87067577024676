
import { HrJobTitles } from './index';
import { TrQuals } from './index';
import { NgTenants } from './index';

export class TrQualXJobTitles {
   public qualJobTitleId: number = 0;
   public qualId: number = 0;
   public jobTitleId: number = 0;
   public tenantId: number = 0;
   public jobTitle: HrJobTitles = null;
   public qual: TrQuals = null;
   public tenant: NgTenants = null;
}