
import { HrEmployees } from './index';
import { SchResources } from './index';
import { NgTenants } from './index';

export class HrGenderType {
   public genderTypeId: number = 0;
   public genderTypeCode: string = null;
   public genderType: string = null;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public schResources: SchResources[] = [];
   public tenant: NgTenants = null;
}