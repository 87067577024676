
import { QcReportScheduleAsRequired } from './index';
import { NgTenants } from './index';

export class QcInspectionSequences {
   public inspectionSequenceId: number = 0;
   public inspectionSequence: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcReportScheduleAsRequired: QcReportScheduleAsRequired[] = [];
   public tenant: NgTenants = null;
}