
import { QcObservations } from './index';
import { QcObservationCommentTypes } from './index';
import { NgTenants } from './index';

export class QcObservationComments {
   public observationCommentId: number = 0;
   public observationId: number = 0;
   public observationCommentTypeId: number = 0;
   public comment: string = null;
   public commentDate: Date = new Date(0);
   public commentById: number = null;
   public attachment: Array<any> = [];
   public attachmentName: string = null;
   public attachmentType: string = null;
   public attachmentAddedById: number = null;
   public tenantId: number = 0;
   public attachmentGuid: string = null;
   public observation: QcObservations = null;
   public observationCommentType: QcObservationCommentTypes = null;
   public tenant: NgTenants = null;
}