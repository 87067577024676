
import { AccTimeEntry2 } from './index';
import { AccTimeEntryAudit } from './index';
import { HrEmployeeJobAssignments } from './index';
import { HrEmployees } from './index';
import { HrFyPayRates } from './index';
import { HrOcc } from './index';
import { HrPsc } from './index';
import { HrTitleXJobAssignment } from './index';
import { HrJobCategoriesTable } from './index';
import { SchMinPermPos } from './index';
import { SchScheduleItem } from './index';
import { NgTenants } from './index';
import { TrQualXJobTitles } from './index';
import { HrUnionAffiliation } from './index';
import { HrWorkSections } from './index';

export class HrJobTitles {
   public jobTitleId: number = 0;
   public jobTitle: string = null;
   public jobCategoryId: number = 0;
   public workSectionId: number = 0;
   public unionAffiliationId: number = 0;
   public active: boolean = false;
   public payrollCode: number = null;
   public onSeniorityList: boolean = null;
   public color: string = null;
   public glcSlbRft: string = null;
   public glcSlbRelief: string = null;
   public tenantId: number = 0;
   public accTimeEntry2: AccTimeEntry2[] = [];
   public accTimeEntryAudit: AccTimeEntryAudit[] = [];
   public hrEmployeeJobAssignments: HrEmployeeJobAssignments[] = [];
   public hrEmployees: HrEmployees[] = [];
   public hrFyPayRates: HrFyPayRates[] = [];
   public hrOcc: HrOcc[] = [];
   public hrPsc: HrPsc[] = [];
   public hrTitleXJobAssignment: HrTitleXJobAssignment[] = [];
   public jobCategory: HrJobCategoriesTable = null;
   public schMinPermPos: SchMinPermPos[] = [];
   public schScheduleItem: SchScheduleItem[] = [];
   public tenant: NgTenants = null;
   public trQualXJobTitles: TrQualXJobTitles[] = [];
   public unionAffiliation: HrUnionAffiliation = null;
   public workSection: HrWorkSections = null;
}