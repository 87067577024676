
import { NgTenants } from './index';

export class SchTravelRequestEmails {
   public externalTravelRequestEmailId: number = 0;
   public emailAddress: string = null;
   public includeOnInternal: boolean = false;
   public sendFrom: boolean = false;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
}