
import { TrQuals } from './index';
import { TrQualTasks } from './index';
import { NgTenants } from './index';

export class TrQualXQualTasks {
   public qualQualTaskId: number = 0;
   public qualId: number = 0;
   public qualTaskId: number = 0;
   public tenantId: number = 0;
   public qual: TrQuals = null;
   public qualTask: TrQualTasks = null;
   public tenant: NgTenants = null;
}