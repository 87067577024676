
import { HrJobTitles } from './index';
import { NgTenants } from './index';

export class HrJobCategoriesTable {
   public jobCategoryId: number = 0;
   public jobCategory: string = null;
   public tenantId: number = 0;
   public hrJobTitles: HrJobTitles[] = [];
   public tenant: NgTenants = null;
}