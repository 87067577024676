
import { QcAreas } from './index';
import { QcDeficiencyTypes } from './index';
import { QcInspectionReports } from './index';
import { QcObservationTypes } from './index';
import { QcOriginTypes } from './index';
import { QcPublications } from './index';
import { QcObservationAttachments } from './index';
import { QcObservationComments } from './index';
import { QcObservationRequiredActions } from './index';
import { QcObservationXjobControlNumbers } from './index';
import { QcObservationXworkOrders } from './index';
import { QcResponsibleManagers } from './index';
import { QcRootCauses } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';
import { HrWorkSections } from './index';

export class QcObservations {
   public observationId: number = 0;
   public obsInspNum: string = null;
   public openedDate: Date = null;
   public originTypeId: number = 0;
   public deficiencyTypeId: number = 0;
   public observationTypeId: number = 0;
   public qaCarNumber: string = null;
   public reportNumber: string = null;
   public inspectionReportId: number = null;
   public siteId: number = null;
   public nonConformance: string = null;
   public areaId: number = 0;
   public pwsReference: string = null;
   public pwsRefDesc: string = null;
   public pubReferenceId: number = null;
   public pubRefDesc: string = null;
   public altReference: string = null;
   public altRefDesc: string = null;
   public oldJobControlNumber: string = null;
   public responsibleManagerId: number = 0;
   public managerEstCompDate: Date = null;
   public managerCompDate: Date = null;
   public workSectionId: number = null;
   public workSectionSuspenseDate: Date = null;
   public workSectionCompDate: Date = null;
   public finalReviewDate: Date = null;
   public projMngrReviewedDate: Date = null;
   public rootCauseId: number = null;
   public managerActionId: number = null;
   public additionalCommentsId: number = null;
   public correctiveActionId: number = null;
   public inspectionDate: Date = null;
   public isValid: boolean = false;
   public tenantId: number = 0;
   public area: QcAreas = null;
   public deficiencyType: QcDeficiencyTypes = null;
   public inspectionReport: QcInspectionReports = null;
   public observationType: QcObservationTypes = null;
   public originType: QcOriginTypes = null;
   public pubReference: QcPublications = null;
   public qcObservationAttachments: QcObservationAttachments[] = [];
   public qcObservationComments: QcObservationComments[] = [];
   public qcObservationRequiredActions: QcObservationRequiredActions[] = [];
   public qcObservationXjobControlNumbers: QcObservationXjobControlNumbers[] = [];
   public qcObservationXworkOrders: QcObservationXworkOrders[] = [];
   public responsibleManager: QcResponsibleManagers = null;
   public rootCause: QcRootCauses = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
   public workSection: HrWorkSections = null;
}