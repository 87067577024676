
import { LogClinCodes } from './index';
import { LogReqChangeUserAlert } from './index';
import { LogReqChangeUserAlertData } from './index';
import { LogReqChangeUserWatchData } from './index';
import { LogReqComments } from './index';
import { LogReqHeaderAudit } from './index';
import { LogReqLineItems } from './index';
import { LogReqTextCodes } from './index';
import { LogRfqs } from './index';
import { LogOrgCodes } from './index';
import { LogReqStatuses } from './index';
import { LogPriceAnalysis } from './index';
import { LogReqPriorities } from './index';
import { LogReqTypes } from './index';
import { LogReqRoutingSections } from './index';
import { LogShopCodes } from './index';
import { LogSourceSelections } from './index';

export class LogReqHeader {
   public reqId: string = null;
   public siteId: number = null;
   public reqTypeId: number = null;
   public reqType: string = null;
   public requestDate: Date = null;
   public reqCreatedDate: Date = null;
   public reqCompleteDate: Date = null;
   public estPhqDeliveryDate: Date = null;
   public phqReceivedDate: Date = null;
   public paidBy: string = null;
   public reqStatusId: number = 0;
   public requestedById: number = null;
   public createdById: number = null;
   public workOrderId: number = null;
   public jobId: string = null;
   public oaNumber: string = null;
   public jcn: string = null;
   public equipmentId: number = null;
   public priorityId: number = null;
   public rfqOnly: boolean = false;
   public onHold: boolean = false;
   public buyerEmployeeId: number = null;
   public noPartialShipment: boolean = false;
   public holdShipment: boolean = false;
   public lpRequired: boolean = false;
   public cancelledOnDate: Date = null;
   public cancelledByEmpId: number = null;
   public highLineNum: number = null;
   public lastUpdatedOn: Date = null;
   public shippingPriorityId: number = null;
   public vendorRfqcomment: string = null;
   public lastUpdatedById: number = null;
   public reqStatus: string = null;
   public lpJustification: string = null;
   public soleSourceJustification: string = null;
   public bidComparisonDesc: string = null;
   public sourceSelectionId: number = null;
   public sourceJustification: string = null;
   public priceAnalysisId: number = null;
   public priceAnalysisDesc: string = null;
   public step1ApprovedBy: number = null;
   public step2ApprovedBy: number = null;
   public step1ApprovedDate: Date = null;
   public step2ApprovedDate: Date = null;
   public isMsi: boolean = null;
   public orgCode: string = null;
   public shopCode: string = null;
   public supplyPriority: string = null;
   public supplyDocNumber: string = null;
   public notesForPo: string = null;
   public routingSectionId: number = null;
   public tenantId: number = 0;
   public extApproverId: number = null;
   public extApprovalDate: Date = null;
   public workSectionId: number = null;
   public clinCodeId: number = null;
   public pwsCitation: string = null;
   public identifier: string = null;
   public preParkingLotStatusId: number = null;
   public copaId: number = null;
   public clinCode: LogClinCodes = null;
   public logReqChangeUserAlert: LogReqChangeUserAlert[] = [];
   public logReqChangeUserAlertData: LogReqChangeUserAlertData[] = [];
   public logReqChangeUserWatchData: LogReqChangeUserWatchData[] = [];
   public logReqComments: LogReqComments[] = [];
   public logReqHeaderAudit: LogReqHeaderAudit[] = [];
   public logReqLineItems: LogReqLineItems[] = [];
   public logReqTextCodes: LogReqTextCodes[] = [];
   public logRfqs: LogRfqs[] = [];
   public orgCodeNavigation: LogOrgCodes = null;
   public preParkingLotStatus: LogReqStatuses = null;
   public priceAnalysis: LogPriceAnalysis = null;
   public priority: LogReqPriorities = null;
   public reqStatusNavigation: LogReqStatuses = null;
   public reqTypeNavigation: LogReqTypes = null;
   public routingSection: LogReqRoutingSections = null;
   public shippingPriority: LogReqPriorities = null;
   public shopCodeNavigation: LogShopCodes = null;
   public sourceSelection: LogSourceSelections = null;
}