import { Component, OnInit, EventEmitter, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AimsCommonButtonComponent } from '../aims-common-button/aims-common-button.component'

@Component({
  selector: 'aims-common-confirm-dialog',
  templateUrl: './aims-common-confirm-dialog.component.html',
  styleUrls: ['./aims-common-confirm-dialog.component.css'],
})
export class AimsCommonConfirmDialogComponent implements OnInit {
  title: string = '';
  message: string;
  btnOkText: string = 'Delete'
  btnCancelText: string = 'Cancel';
  onSubmitReason = new EventEmitter();
  btnIconText: string = 'delete';
  showIcon: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AimsCommonConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AimsCommonConfirmDialogModel
  ) {
    //update with given values
    this.title = data.title;
    this.message = data.message;
    this.btnOkText = data.btnOkText;
    this.btnCancelText = data.btnCancelText;
    this.btnIconText = data.btnIconText;
    this.showIcon = data.showIcon;
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(true)
  }
  onDismiss(): void {
    this.dialogRef.close(false)
  }
  getConfirmButtonColor(buttonText) {
    return buttonText == 'Delete' || buttonText == 'Deactivate' ? 'warn' : ''
  }
  
  //Leaving this function for reverse compatibility.  Should switch to using the showIcon property going forward
  showMatIcon(buttonText){
    let showIcon = false;
    if(buttonText == "Delete" || buttonText == "Deactivate" || buttonText == "Remove" || buttonText == "Discard changes" || this.showIcon){
     showIcon = true
    }
    return showIcon;
  } 
}
export class AimsCommonConfirmDialogModel {
  constructor(
    public title: string, 
    public message: string, 
    public btnOkText: string, 
    public btnCancelText:string = "Cancel",
    public btnIconText:string = "delete",
    public showIcon: boolean = false
    ) {}
}
