
import { QcQuestions } from './index';
import { NgTenants } from './index';

export class QcResponsibleAssignments {
   public responsibleAssignmentId: number = 0;
   public responsibleAssignment: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcQuestions: QcQuestions[] = [];
   public tenant: NgTenants = null;
}