
import { LogReqLineItemsAudit } from './index';
import { LogReqPriorities } from './index';
import { LogReqHeader } from './index';
import { LogReqStatuses } from './index';
import { LogReqTypes } from './index';
import { LogReqRoutingSections } from './index';

export class LogReqHeaderAudit {
   public reqAuditId: number = 0;
   public reqId: string = null;
   public reqIdOrig: string = null;
   public siteId: number = null;
   public reqTypeId: number = null;
   public requestDate: Date = null;
   public reqCreatedDate: Date = null;
   public reqCompleteDate: Date = null;
   public paidBy: string = null;
   public reqDepartment: string = null;
   public serialNumber: string = null;
   public reqStatusId: number = 0;
   public requestedById: number = null;
   public createdById: number = null;
   public workOrderId: number = null;
   public jobId: string = null;
   public oaNumber: string = null;
   public jcn: string = null;
   public ujc: string = null;
   public priorityId: number = null;
   public shippingMethodId: number = null;
   public buyerEmployeeId: number = null;
   public noPartialShipment: boolean = false;
   public holdShipment: boolean = false;
   public rfqOnly: boolean = false;
   public lpRequired: boolean = false;
   public onHold: boolean = false;
   public cancelledOnDate: Date = null;
   public cancelledByEmpId: number = null;
   public cancelledReason: string = null;
   public numLineItems: number = 0;
   public timeStamp: Array<any> = [];
   public lastUpdatedOn: Date = null;
   public shippingPriority: number = null;
   public vendorRfqcomment: string = null;
   public lastUpdatedById: number = null;
   public routeId: number = null;
   public tenantId: number = 0;
   public workSectionId: number = null;
   public clinCodeId: number = null;
   public pwsCitation: string = null;
   public identifier: string = null;
   public logReqLineItemsAudit: LogReqLineItemsAudit[] = [];
   public priority: LogReqPriorities = null;
   public req: LogReqHeader = null;
   public reqStatus: LogReqStatuses = null;
   public reqType: LogReqTypes = null;
   public route: LogReqRoutingSections = null;
}