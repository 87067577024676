
import { LogReqHeader } from './index';

export class LogReqComments {
   public commentId: number = 0;
   public reqId: string = null;
   public commentDate: Date = new Date(0);
   public comment: string = null;
   public addedBy: string = null;
   public addedById: number = null;
   public attachment: Array<any> = [];
   public attachmentName: string = null;
   public attachmentType: string = null;
   public tenantId: number = 0;
   public attachmentGuid: string = null;
   public req: LogReqHeader = null;
}