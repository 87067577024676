import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AimsCommonButtonComponent } from './aims-common-button.component'
import { MatButtonModule } from '@angular/material/button'

@NgModule({
  declarations: [AimsCommonButtonComponent],
  exports: [AimsCommonButtonComponent],
  imports: [CommonModule, MatButtonModule],
})
export class AimsCommonButtonModule {}
