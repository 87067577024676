
import { NgTenants } from './index';

export class AccTimecardConfig {
   public timecardConfigId: number = 0;
   public cutoverDate: Date = new Date(0);
   public currentYear: string = null;
   public projectYears: string = null;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
}