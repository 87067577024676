
import { FacRefrigerantTransfers } from './index';
import { HrEmployees } from './index';
import { FacRefrigerantTypes } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class FacRefrigerantCylinders {
   public cylinderId: number = 0;
   public cylinderSerialNo: string = null;
   public refrigerantTypeId: string = null;
   public hydroTestDate: Date = null;
   public refrigerantQty: number = null;
   public onSiteDate: Date = null;
   public departSiteDate: Date = null;
   public capacity: number = null;
   public siteId: number = null;
   public nonReusable: boolean = null;
   public lastEditedOn: Date = null;
   public cylinderTypeId: number = 0;
   public tareWeight: number = null;
   public grossWeight: number = null;
   public comments: string = null;
   public lastWeighedOn: Date = null;
   public lastEditedById: number = null;
   public tenantId: number = 0;
   public facRefrigerantTransfersCylinder: FacRefrigerantTransfers[] = [];
   public facRefrigerantTransfersTransferCylinder: FacRefrigerantTransfers[] = [];
   public lastEditedBy: HrEmployees = null;
   public refrigerantType: FacRefrigerantTypes = null;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}