import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AimsCommonFilterSelectionComponent } from './aims-common-filter-selection.component'
import { MatSelectModule } from '@angular/material/select'
import { FormsModule } from '@angular/forms'

@NgModule({
  declarations: [AimsCommonFilterSelectionComponent],
  exports: [AimsCommonFilterSelectionComponent],
  imports: [CommonModule, FormsModule, MatSelectModule],
})
export class AimsCommonFilterSelectionModule {}
