import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AimsCommonTableDateRange } from '../aims-common-table/aims-common-table.interface';

@Component({
  selector: 'aims-common-filter-selection',
  templateUrl: './aims-common-filter-selection.component.html',
  styleUrls: [ './aims-common-filter-selection.component.css' ]
})
export class AimsCommonFilterSelectionComponent implements OnInit {

  @Input() filterSelection: AimsCommonTableFilterSelection[];

  @Output() filterSelectedEvent = new EventEmitter<AimsCommonTableFilterSelection> ();

  selectedFilterId: string;

  filterSelectChange(change: any) {
    let selectedFilter = this.filterSelection.find(f => { return f.filterId == this.selectedFilterId });
    if (selectedFilter) {
      this.filterSelectedEvent.emit(selectedFilter);
    }
  }

  public ngOnInit(): void {
  }
}

export interface AimsCommonTableFilterSelection {
  filterId: string
  label: string | Function
  filters: AimsCommonTableFilterSelectionValue[]
}

// If column filter is a date, then you must specify the range.
export interface AimsCommonTableFilterSelectionValue {
  columnName: string
  filterValue: any
  range?: AimsCommonTableDateRange
}
