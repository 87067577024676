
import { InvNotUsed } from './index';
import { LogShippingCommLi } from './index';
import { LogWrongParts } from './index';

export class PoLn {
   public poId: string = null;
   public poRlseNo: number = 0;
   public poLnKey: number = 0;
   public poLnNo: number = 0;
   public sPoLnType: string = null;
   public itemKey: number = null;
   public poLnDesc: string = null;
   public ordQty: number = 0;
   public recvdQty: number = 0;
   public accptdQty: number = 0;
   public vchrdQty: number = 0;
   public pstdQty: number = 0;
   public ordDt: Date = new Date(0);
   public dueDt: Date = null;
   public desiredDt: Date = null;
   public discPctRt: number = 0;
   public recptTolPctRt: number = 0;
   public grossUnitCstAmt: number = 0;
   public netUnitCstAmt: number = 0;
   public poLnExtAmt: number = 0;
   public poLnTotAmt: number = 0;
   public totRlsedAmt: number = 0;
   public blktBalAmt: number = 0;
   public recvdAmt: number = 0;
   public accptdAmt: number = 0;
   public vchrdAmt: number = 0;
   public pstdAmt: number = 0;
   public salesTaxAmt: number = 0;
   public shipId: string = null;
   public sLnStatusType: string = null;
   public sPoCommitType: string = null;
   public taxableFl: string = null;
   public delToFld: string = null;
   public shipViaFld: string = null;
   public poLnUmCd: string = null;
   public miscLnChgType: string = null;
   public rqId: string = null;
   public manufPartId: string = null;
   public manufPartRvsnId: string = null;
   public vendPartId: string = null;
   public vendPartRvsnId: string = null;
   public certOfCnfrmFl: string = null;
   public qcReqdFl: string = null;
   public srceInspFl: string = null;
   public ovrshpAllowFl: string = null;
   public blktPoRlseNo: number = 0;
   public blktPoLnKey: number = null;
   public itemId: string = null;
   public itemRvsnId: string = null;
   public dropShipFl: string = null;
   public orderRefId: string = null;
   public origDueDt: Date = null;
   public userRvsnFld: string = null;
   public invtAbbrvCd: string = null;
   public vchrRcvFl: string = null;
   public sMatchOptCd: string = null;
   public poLnChgAmt: number = 0;
   public poLnChgTaxAmt: number = 0;
   public rejRepQty: number = 0;
   public rejPayQty: number = 0;
   public rejCrQty: number = 0;
   public rejRepAmt: number = 0;
   public rejPayAmt: number = 0;
   public rejCrAmt: number = 0;
   public apprvlDt: Date = null;
   public apprvlUserId: string = null;
   public orderRefLnKey: number = 0;
   public sOrdRefTypeCd: string = null;
   public whseId: string = null;
   public poLnCloseDt: Date = null;
   public totRlsedQty: number = 0;
   public sMatchCd: string = null;
   public commCd: string = null;
   public minQty: number = 0;
   public ltDaysNo: number = 0;
   public sPurchAgrmtCd: string = null;
   public issueFl: string = null;
   public procureTypeCd: string = null;
   public autoVchrFl: string = null;
   public sublineNo: number = 0;
   public rqLnKey: number = null;
   public awardQtId: string = null;
   public modifiedBy: string = null;
   public timeStamp: Date = new Date(0);
   public salesTaxRt: number = 0;
   public milSpecId: string = null;
   public nsnId: string = null;
   public orderRefRlseNo: number = 0;
   public clinId: string = null;
   public perfStartDt: Date = null;
   public perfEndDt: Date = null;
   public billCycleCd: string = null;
   public billCyclePayAmt: number = 0;
   public orderRefLnNo: number = 0;
   public lastVchrdDt: Date = null;
   public plannerId: string = null;
   public opId: string = null;
   public sOeCstTypeCd: string = null;
   public trnAccptdAmt: number = 0;
   public trnBillCycleAmt: number = 0;
   public trnBlktBalAmt: number = 0;
   public trnGrUnCstAmt: number = 0;
   public trnNetUnCstAmt: number = 0;
   public trnLnChgAmt: number = 0;
   public trnLnChgTaxAmt: number = 0;
   public trnPoLnExtAmt: number = 0;
   public trnPoLnTotAmt: number = 0;
   public trnPstdAmt: number = 0;
   public trnRecvdAmt: number = 0;
   public trnRejCrAmt: number = 0;
   public trnRejPayAmt: number = 0;
   public trnRejRepAmt: number = 0;
   public trnSalesTaxAmt: number = 0;
   public trnTotRlsedAmt: number = 0;
   public trnVchrdAmt: number = 0;
   public trnRecoveryAmt: number = 0;
   public recoveryAmt: number = 0;
   public recoveryRt: number = 0;
   public bomConfigId: string = null;
   public manufId: string = null;
   public indClassCd: string = null;
   public sClSmBusCd: string = null;
   public soLnCompKey: number = null;
   public compltAmt: number = 0;
   public compltPct: number = 0;
   public storedAmt: number = 0;
   public compltRetPct: number = 0;
   public storedRetPct: number = 0;
   public compltRetAmt: number = 0;
   public storedRetAmt: number = 0;
   public rqstdAmt: number = 0;
   public trnCompltAmt: number = 0;
   public trnStoredAmt: number = 0;
   public trnCompltRetAmt: number = 0;
   public trnStoredRetAmt: number = 0;
   public trnRqstdAmt: number = 0;
   public cisWhFl: string = null;
   public rowversion: number = null;
   public lotReqdFl: string = null;
   public serialReqdFl: string = null;
   public usCitizenFl: string = null;
   public itarAuthFl: string = null;
   public secClrCd: string = null;
   public agencyName: string = null;
   public subKey: number = null;
   public rqPoKey: number = null;
   public clWomOwnFl: string = null;
   public clLabSrplFl: string = null;
   public clVetFl: string = null;
   public clSdVetFl: string = null;
   public clDisadvFl: string = null;
   public clHistBlClgFl: string = null;
   public clAncItFl: string = null;
   public sPortalActionCd: string = null;
   public propOrdQty: number = null;
   public trnPropExtAmt: number = null;
   public propDueDt: Date = null;
   public poLnExpdtNotes: string = null;
   public poLnIntNotes: string = null;
   public invNotUsed: InvNotUsed[] = [];
   public logShippingCommLi: LogShippingCommLi[] = [];
   public logWrongParts: LogWrongParts[] = [];
}