
import { FacWeeklyReadings } from './index';
import { NgTenants } from './index';

export class FacWeeklyReadingCategories {
   public readingCategoryId: number = 0;
   public readingCategory: string = null;
   public tenantId: number = 0;
   public facWeeklyReadings: FacWeeklyReadings[] = [];
   public tenant: NgTenants = null;
}