import { TransferState, makeStateKey, Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { of, Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { catchError, mapTo, tap, take } from 'rxjs/operators';
import { TransferHttpService, CacheItem } from '../transfer-http/transfer-http.service';
import { PermissionsService } from '../permissions/permissions.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../src/environments/environment';
import { NgTenants } from 'src/models';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../message/message.service';
import { DialogComponent } from '../message/message.enum';
import { AimsCommonConfirmDialogComponent } from '@aims/common-components';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = `${environment.name}JWT_TOKEN`;
  private readonly REFRESH_TOKEN = `${environment.name}REFRESH_TOKEN`;
  private readonly USER_INFO = `${environment.name}USER_INFO`;
  private readonly FORCE_PWD_RESET = `${environment.name}FORCE_PWD_RESET`;
  private readonly LAST_TENANT = `${environment.name}LAST_TENANT`;

  public redirectRoute: string;

  constructor(private transferHttp: TransferHttpService,
    private permissions: PermissionsService,
    private transferState: TransferState,
    private dialog: MatDialog,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) private platformId) { }


  LoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  login(user: JwtUser): Observable<any> {
    if (user.tenantId === -1) {
      // user is attemptint to login to Administration
      user.administration = true;
    }
    
    return this.transferHttp.post<AuthResult>(`Auth/Authenticate`, user).pipe(
      take(1),
      tap((authResult: AuthResult) => {
        const user = authResult.user;
        const userInfo = authResult.userInfo;

        // load the authenticated user's transfer data into the cache - this only contains data for the tenant the user has logged into
        this.transferHttp.loadTransferData(authResult.transferData);
        //save this for next login
        this.storeLastLogin(user.tenantId);
        this.doLoginUser(user, userInfo);
        this.LoggedIn.next(true);
        if (user.forcePasswordReset){
          this.setPwdRefresh();
        }
      })
    );
  }

  logout() {
    return this.transferHttp.post(`Auth/Logout`, {refreshToken: this.getRefreshToken()}).pipe(
      take(1),
      tap(() => {
        this.doLogoutUser();
        this.LoggedIn.next(false);
      }),
      mapTo(true),
      catchError(error => {
        const message = error.error

        var dialogData = this.messageService.getDialogMessage(DialogComponent.AimsError, message);
        let dialogRef = this.dialog.open(AimsCommonConfirmDialogComponent, {
          data: dialogData,
          disableClose: true
        })
        dialogRef.afterClosed().subscribe((dialogResult) => {
        });

        return of(false);
      }));
  }
  getTenants():Observable<Array<NgTenants>>{
    return this.transferHttp.getSingle('Auth/GetTenants')
  }

  //Will grab only for logged in user with JWT
  getUserIdFromToken() {

    const jwtPayload = this.parseJwt();

    let userId = null;
    if (jwtPayload) {
      userId = JSON.parse(jwtPayload.unique_name);
    }
    return userId;
  }

  getPermissiosFromJwt() {
    const jwtPayload = this.parseJwt();
    const permissions = jwtPayload.permissions ? this.unpackUserPermissions(jwtPayload.permissions) : [];

    return permissions;
  }

  get isAimsAdmin(): boolean {
    const jwtPayload = this.parseJwt();
    let isAimsAdmin = false;
    if (jwtPayload){
      isAimsAdmin = jwtPayload.isAimsAdmin == "True";
    }
    return isAimsAdmin;
  }

  get showAdminMenu(): boolean {
    const jwtPayload = this.parseJwt();
    return jwtPayload && ((jwtPayload.administration as string) || "").toLowerCase() == "true";
  }

  private unpackPermission(packed) {
    let parts = packed.split(':');
    let packedCRUD = parseInt(parts[6], 36);

    return {
        userId: parts[0] === "" ? null : parseInt(parts[0], 36),
        componentId: parseInt(parts[1], 36),
        createProvider: parts[2] === "" ? null : parseInt(parts[2], 36),
        readProvider: parts[3] === "" ? null : parseInt(parts[3], 36),
        updateProvider: parts[4] === "" ? null : parseInt(parts[4], 36),
        deleteProvider: parts[5] === "" ? null : parseInt(parts[5], 36),
        create: (packedCRUD & 1) === 1,
        read: (packedCRUD & 2) === 2,
        update: (packedCRUD & 4) === 4,
        delete: (packedCRUD & 8) === 8
    };
  }

  private unpackUserPermissions(packedPermissions) {
    return packedPermissions.split('|').map(this.unpackPermission);
  }

  private parseJwt() {
    let retVal = null;
    const jwt = this.getJwtToken();
    if (jwt !== null) {
      var base64Url = jwt.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      retVal = JSON.parse(jsonPayload);
    }

    return retVal
  };

  isLoggedIn() {
    return (this.getJwtToken() !== null && this.getRefreshToken() !== null && this.getUserInfo() !== null);
  }

  refreshToken() {
    if (this.isLoggedIn()) {
      return this.transferHttp.post('Auth/Refresh', {
        'jwtToken': this.getJwtToken(),
        'refreshToken': this.getRefreshToken()
      }).pipe(tap((tokens: Tokens) => {
        //Store new JWT and Refresh tokens
        this.storeTokens(tokens)
      }));
    }
    return EMPTY;
  }

  getJwtToken() {
    return (isPlatformBrowser(this.platformId)) ? localStorage.getItem(this.JWT_TOKEN) : "";
  }

  public get forcePasswordReset(): boolean {
    return (isPlatformBrowser(this.platformId)) ? JSON.parse(localStorage.getItem(this.FORCE_PWD_RESET)) : false;
  }

  private getUserInfo() {
    try{

      let user = null;
      if (isPlatformBrowser(this.platformId)) {

        user = JSON.parse(localStorage.getItem(this.USER_INFO));
      }
      return user;
    }
    catch (e){
      this.logout();
    }
  }


  doLoginUser(user = null, userInfo = null) {
    if (isPlatformBrowser(this.platformId)) {

      //Set tokens
      if (user && user.jwtToken && user.refreshToken) {
        this.storeTokens(<Tokens>{ jwt: user.jwtToken, refreshToken: user.refreshToken });
      }

      //Set user info
      if (userInfo) {
        this.storeUserInfo(userInfo);
      }

      //If no user data but are authenticated, need to go get it, this happens when user opens new tab
      if (user === null) {
        if (this.isLoggedIn()) {
          // ensure userInfo is initialized
          this.initUser(this.getUserInfo());
        }
        // refresh the user info
        this.transferHttp.getSingle('Auth/GetUserInfo')
        .pipe(
          catchError(err => this.logout())
        )
        .subscribe(userInfo => {
          this.storeUserInfo(userInfo);
          this.initUser(userInfo);
        });
      }
      else {
        //Set user and permissions
        this.initUser(userInfo);
      }
    }
  }

  private initUser(userInfo) {
    //Check if need to reload cache, only needs to happen if logging into different tenant
    if (userInfo.tenantId !== undefined && userInfo.tenantId !== this.transferHttp.getCache(CacheItem.tenantId)){
      this.transferHttp.reloadCache();
    }
    //Set is admin
    this.permissions.isAimsAdmin.next(this.isAimsAdmin);
    //Set permissions
    this.permissions.permissions.next(this.getPermissiosFromJwt());
    //Set user info in perm service
    this.permissions.user.next(userInfo);
    //Set userinfo in cache - use setCacheSubject so changes are observable
    this.transferHttp.setCacheSubject(CacheItem.user, userInfo);
    // Set LoggedIn to true
    this.LoggedIn.next(true);
  }

  doLogoutUser() {
    this.transferHttp.dumpCache();
    this.permissions.deleteAllPermissions();
    this.removeTokens();
    this.removeUser();
  }

  getRefreshToken() {
    return (isPlatformBrowser(this.platformId)) ? localStorage.getItem(this.REFRESH_TOKEN) : '';
  }

  get lastLoginTenantId(): number{
    return +((isPlatformBrowser(this.platformId)) ? localStorage.getItem(this.LAST_TENANT) : '0') || 0;
  }

  private setPwdRefresh() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.FORCE_PWD_RESET, JSON.stringify(true));
    }
  }

  removePwdRefresh(){
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.FORCE_PWD_RESET);
    }
  }

  private storeLastLogin(tenantId: number) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.LAST_TENANT, tenantId.toString());
    }
  }

  private storeUserInfo(userInfo) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.USER_INFO, JSON.stringify(userInfo));
    }
  }

  private storeJwtToken(jwt: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.JWT_TOKEN, jwt);
    }
  }

  private storeTokens(tokens: Tokens) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }
  }

  private removeUser() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.USER_INFO);
    }
  }

  private removeTokens() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.JWT_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
    }
  }
}

interface AuthResult {
  user: AimsUser;
  userInfo: any;
  transferData: any;
  administration?: boolean;
}

class AimsUser {
  userId: string;
  userName: string;
  jwtToken: string;
  refreshToken: string;
  userInfo: any;
  permissions: Array<any>;
  forcePasswordReset: boolean;
  tenantId: number;
  isAimsAdmin: boolean;
}

export class Tokens {
  jwt: string;
  refreshToken: string;
}


export class JwtUser {
  userName: string;
  password: string;
  tenantId: number;
  administration?: boolean;
}

class UserPerms {
  user;
  permissions;
}
