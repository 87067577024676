
import { WoWorkOrders } from './index';

export class WoWorkOrderComment {
   public workOrderCommentId: number = 0;
   public workOrderId: number = 0;
   public commentDate: Date = new Date(0);
   public comment: string = null;
   public addedBy: string = null;
   public addedById: number = null;
   public attachmentGuid: string = null;
   public attachmentName: string = null;
   public attachmentType: string = null;
   public tenantId: number = 0;
   public workOrder: WoWorkOrders = null;
}