import {
  Component,
  ContentChild,
  HostListener,
  ElementRef,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { ViewModeDirective } from './view-mode.directive'
import { EditModeDirective } from './edit-mode.directive'
import { Subject } from 'rxjs'
// import { switchMap, takeUntil, filter, take, switchMapTo } from 'rxjs/operators';
// import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'aims-common-table-editable',
  template: ` <ng-container *ngTemplateOutlet="currentView"></ng-container> `,
  styles: [],
})
export class AimsCommonTableEditableComponent implements OnInit, OnChanges {
  @Input() inputMode
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective
  @Output() update = new EventEmitter()

  // editMode = new Subject()
  // editMode$ = this.editMode.asObservable()

  mode: 'view' | 'edit' = 'view'

  constructor(private host: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.mode = this.inputMode ? this.inputMode : this.mode
  }

  ngOnInit() {
    // this.viewModeHandler();
    // this.editModeHandler();
  }

  toViewMode() {
    this.update.next(0)
    this.mode = 'view'
  }

  private get element() {
    return this.host.nativeElement
  }

  // private viewModeHandler() {
  //   fromEvent(this.element, 'dblclick').pipe(
  //     untilDestroyed(this)
  //   ).subscribe(() => {
  //     this.mode = 'edit';
  //     this.editMode.next(true);
  //   });
  // }

  // private editModeHandler() {
  //   const clickOutside$ = fromEvent(document, 'click').pipe(
  //     filter(({ target }) => this.element.contains(target) === false),
  //     take(1)
  //   )

  //   this.editMode$.pipe(
  //     switchMapTo(clickOutside$),
  //     untilDestroyed(this)
  //   ).subscribe(event => this.toViewMode());
  // }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl
  }

  ngOnDestroy() {}
}
