
import { FacEquipment } from './index';
import { NgTenants } from './index';
import { Ttt332 } from './index';
import { WoWorkOrders } from './index';

export class FacEquipmentGroups {
   public equipmentGroup: string = null;
   public equipmentGroupId: number = 0;
   public afEquipmentGroup: string = null;
   public equipmentGroupCode: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public facEquipment: FacEquipment[] = [];
   public tenant: NgTenants = null;
   public ttt332: Ttt332[] = [];
   public woWorkOrders: WoWorkOrders[] = [];
}