import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AimsCommonExportToExcelComponent } from './aims-common-export-to-excel.component'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { AimsCommonButtonModule } from '../aims-common-button/aims-common-button.module'

@NgModule({
  declarations: [AimsCommonExportToExcelComponent],
  exports: [AimsCommonExportToExcelComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, AimsCommonButtonModule, MatIconModule],
})
export class AimsCommonExportToExcelModule {}
