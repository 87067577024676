
import { NgTenants } from './index';
import { SchTravelRequestBagType } from './index';
import { HrEmployees } from './index';

export class HrTravelBagDefaults {
   public travelBagDefaultId: number = 0;
   public travelerId: number = 0;
   public travelRequestBagTypeId: number = 0;
   public weight: number = 0;
   public oversize: boolean = false;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public travelRequestBagType: SchTravelRequestBagType = null;
   public traveler: HrEmployees = null;
}