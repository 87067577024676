import {
  Component,
  Input,
  Inject,
  OnInit,
  PLATFORM_ID,
  Injector,
  ValueProvider,
  ViewChild,
  TemplateRef,
} from '@angular/core'
import { FormBuilder, FormGroup, NgForm } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AimsCommonPopupEditorData } from './aims-common-popup-editor-data.component'

@Component({
  selector: 'aims-common-aims-common-popup-editor',
  templateUrl: './aims-common-popup-editor.component.html',
  styleUrls: ['./aims-common-popup-editor.component.css'],
})
export class AimsCommonPopupEditorComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AimsCommonPopupEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inj: Injector,
    private formBuilder: FormBuilder) {
      this.formGroup = this.formBuilder.group({})
  }

  @Input() public isNew: boolean = false
  @Input() public header: string = ''
  @Input() public btnOkText: string = 'Save'
  @Input() public btnCancelText: string = 'Cancel'
  @Input() public useRaw: boolean = false

  component: any
  injector: Injector
  @ViewChild(TemplateRef) templateRef: any
  formGroup: FormGroup;
  title: string
  public onSave(e): void {
    e.preventDefault()
    this.closeDialog(this.useRaw ? this.formGroup.getRawValue() : this.formGroup.value)
  }

  public onCancel(e): void {
    e.preventDefault()
    this.closeForm()
    this.closeDialog(false)
  }

  public closeForm(): void {
    this.closeDialog(true)
  }
  closeDialog(value) {
    if (this.dialogRef) {
      this.dialogRef.close(value)
    }
  }

  ngOnInit(): void {
    if (this.data && this.data.content) {
      this.component = this.data.content
      this.header = this.data.header
      this.isNew = this.data.isNew
      this.useRaw = this.data.useRaw
      if (this.data.btnOkText) {
        this.btnOkText = this.data.btnOkText;
      }
      if (this.data.btnCancelText) {
        this.btnCancelText = this.data.btnCancelText;
      }

      if (this.isNew) {
        this.title = 'Add New'
      } else if (this.isNew == false) {
        this.title = 'Edit'
      } else {
        this.title = ''
      }

      this.title += this.header.length > 0 ? ' ' + this.header : ''

      //reset to an empty formGroup that is then added to in the popup component
      this.formGroup = this.formBuilder.group({}, { updateOn: 'change' })

      this.injector = Injector.create({
        providers: [
          {
            provide: AimsCommonPopupEditorData,
            useValue: new AimsCommonPopupEditorData(
              this.data.header,
              this.data.isNew,
              this.data.contentData,
              this.formGroup
            ),
          } as ValueProvider,
        ],
        parent: this.inj,
      })
    }
  }
}
