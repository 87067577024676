
import { SchTravelRequests } from './index';
import { NgTenants } from './index';

export class SchLodgingAuthType {
   public lodgingAuthTypeId: number = 0;
   public lodgingAuthType: string = null;
   public tenantId: number = 0;
   public active: boolean = false;
   public schTravelRequests: SchTravelRequests[] = [];
   public tenant: NgTenants = null;
}