
import { QcContractNumbers } from './index';
import { HrEmployees } from './index';
import { QcObservations } from './index';
import { QcInspectionReferences } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class QcInspectionReports {
   public inspectionReportId: number = 0;
   public contractNumberId: number = 0;
   public reportName: string = null;
   public attachment: Array<any> = [];
   public attachmentName: string = null;
   public attachmentType: string = null;
   public siteId: number = 0;
   public ref1Id: number = null;
   public ref2Id: number = null;
   public ref3Id: number = null;
   public ref4Id: number = null;
   public ref5Id: number = null;
   public ref6Id: number = null;
   public ref7Id: number = null;
   public ref8Id: number = null;
   public ref9Id: number = null;
   public inspector1Id: number = null;
   public inspector2Id: number = null;
   public inspector3Id: number = null;
   public inspector4Id: number = null;
   public sitePersonnel1Id: number = null;
   public sitePersonnel2Id: number = null;
   public sitePersonnel3Id: number = null;
   public sitePersonnel4Id: number = null;
   public isStaChief1: boolean = null;
   public isStaChief2: boolean = null;
   public isStaChief3: boolean = null;
   public isStaChief4: boolean = null;
   public inspectionDate: Date = new Date(0);
   public generalComments: string = null;
   public overallImpressionOfFacilities: string = null;
   public finalReviewDate: Date = null;
   public tenantId: number = 0;
   public contractNumber: QcContractNumbers = null;
   public inspector1: HrEmployees = null;
   public inspector2: HrEmployees = null;
   public inspector3: HrEmployees = null;
   public inspector4: HrEmployees = null;
   public qcObservations: QcObservations[] = [];
   public ref1: QcInspectionReferences = null;
   public ref2: QcInspectionReferences = null;
   public ref3: QcInspectionReferences = null;
   public ref4: QcInspectionReferences = null;
   public ref5: QcInspectionReferences = null;
   public ref6: QcInspectionReferences = null;
   public ref7: QcInspectionReferences = null;
   public ref8: QcInspectionReferences = null;
   public ref9: QcInspectionReferences = null;
   public site: AllSites = null;
   public sitePersonnel1: HrEmployees = null;
   public sitePersonnel2: HrEmployees = null;
   public sitePersonnel3: HrEmployees = null;
   public sitePersonnel4: HrEmployees = null;
   public tenant: NgTenants = null;
}