
import { AllSites } from './index';
import { NgTenants } from './index';

export class FacLandfillStatus {
   public landfillStatusId: number = 0;
   public siteId: number = 0;
   public weekEndingDate: Date = null;
   public userName: string = null;
   public question1: boolean = null;
   public question2: boolean = null;
   public question3: boolean = null;
   public question4: boolean = null;
   public question5: boolean = null;
   public question6: boolean = null;
   public question7: boolean = null;
   public question8: boolean = null;
   public question9: boolean = null;
   public question10: boolean = null;
   public question11: boolean = null;
   public question12: boolean = null;
   public landfillRemarks: string = null;
   public length: number = 0;
   public height: number = 0;
   public width: number = 0;
   public tenantId: number = 0;
   public site: AllSites = null;
   public tenant: NgTenants = null;
}