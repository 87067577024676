
import { QcReportScheduleAsRequired } from './index';
import { QcReportScheduleSchedule } from './index';
import { QcReportScheduleFrequency } from './index';
import { QcResponsibleManagers } from './index';
import { NgTenants } from './index';

export class QcReportSchedule {
   public reportScheduleId: number = 0;
   public reportScheduleNumber: string = null;
   public reportScheduleName: string = null;
   public reportScheduleFrequencyId: number = 0;
   public responsibleManagerId: number = 0;
   public dayDue: number = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcReportScheduleAsRequired: QcReportScheduleAsRequired[] = [];
   public qcReportScheduleSchedule: QcReportScheduleSchedule[] = [];
   public reportScheduleFrequency: QcReportScheduleFrequency = null;
   public responsibleManager: QcResponsibleManagers = null;
   public tenant: NgTenants = null;
}