import { Injectable } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatPaginator as MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { SessionStorageService } from '../shared/session-storage.service'
import Utils from '../shared/utils'
import { IAimsCommonTableDataSource } from './aims-common-table-data-source-interface'
import {
  AimsCommonTableHeader,
  FormOptions,
  TableFilterState,
  TableSortState,
  TableState,
} from './aims-common-table.interface'

@Injectable({
  providedIn: 'root',
})
export class AimsCommonTableServiceService {
  constructor(private sessionStorage: SessionStorageService) {}

  public setState(tableId: string, state: TableState) {
    this.sessionStorage.setUpdate(tableId, state)
  }

  public saveFilterState(tableId: string, filter: { [key: string]: string }) {
    const state: TableState = this.getState(tableId)
    let result = {}
    if (state?.filter) {
      result = { ...state.filter, ...filter }
    } else {
      result = filter
    }
    this.setState(tableId, { filter: result })
  }

  public resetFilterState(tableId: string) {
    this.setState(tableId, { filter: undefined })
  }

  public setPaginatorState(tableId: string, paginator: MatPaginator, pageSize: number) {
    const state: TableState = this.getState(tableId)
    if (state?.paginator) {
      paginator.pageIndex = state.paginator.pageIndex
      paginator.pageSize = state.paginator.pageSize
    } else {
      paginator.pageSize = pageSize || 20
    }
  }

  public setSortState(tableId: string, sort: MatSort, defaultSort: TableSortState | undefined) {
    const state: TableState = this.getState(tableId)
    const loadSort = state?.sort || defaultSort
    if (sort && loadSort) {
      sort.active = loadSort.active
      sort.direction = loadSort.direction
      sort.sortChange.emit({ ...loadSort })
    }
  }

  public setFilterState(
    tableId: string,
    data: IAimsCommonTableDataSource<unknown>,
    filterValues: TableFilterState | undefined,
    defaultFilterState: TableFilterState | undefined
  ) {
    const state: TableState = this.getState(tableId)

    if (state?.filter) {
      this.updateFilter(data, filterValues, state.filter)
    } else if (defaultFilterState) {
      this.updateFilter(data, filterValues, defaultFilterState)
    }
  }

  private updateFilter(data, filterValues, filters) {
    Object.keys(filters).forEach((i) => {
      data.filterChange(filters[i], i)
      filterValues[i] = filters[i]
    })
  }

  public getFilterValue(tableId: string, key: string) {
    const state: TableState = this.getState(tableId)

    if (state?.filter) {
      return state.filter[key]
    }
  }

  public getState(tableId: string): TableState {
    return this.sessionStorage.get(tableId) as TableState
  }

  public setupSingleControl(
    formOptions: FormOptions,
    entity: any,
    header: AimsCommonTableHeader[],
    rowValidatorOrOpts
  ) {
    const copyHeader: AimsCommonTableHeader[] | any[] = [...header]
    let group = Object.keys(entity).reduce((acc, item) => {
      if (Utils.isObject(entity, item)) {
        this.recursivelySetupFormControls(formOptions, acc, header, entity, item, item, copyHeader)
      }
      this.addFormControlsForAllEntityKeys(formOptions, entity, acc, item)
      const idx = header.findIndex((i) => i.key === item) // Performance flip the for loop
      if (idx === -1) {
        return acc
      }
      const headerItem = header[idx]
      copyHeader[idx] = undefined
      const validatorOrOpts = headerItem?.validatorOrOpts
      const formControl = headerItem?.formControl
      acc[item] = formControl ? formControl(entity) : new FormControl(entity[item], validatorOrOpts)
      return acc
    }, {})
    this.addFormControlsForDynamicKeys(group, copyHeader, entity)
    return new FormGroup(group, rowValidatorOrOpts ? rowValidatorOrOpts : { updateOn: 'change' })
  }

  recursivelySetupFormControls(formOptions, accumulator, header, entity, item, accumulatorKey, copyHeader) {
    if (Utils.isObject(entity, item)) {
      // if we encounter a nested object
      Object.keys(entity[item]).forEach((key) => {
        this.recursivelySetupFormControls(
          formOptions,
          accumulator,
          header,
          entity[item],
          key,
          accumulatorKey + '.' + key,
          copyHeader
        )
      })
    }
    this.addFormControlsForAllEntityKeys(formOptions, entity, accumulator, item)
    const idx = header.findIndex((i) => i.key === accumulatorKey) // Performance
    if (idx === -1) {
      return accumulator
    }
    const headerItem = header[idx]
    copyHeader[idx] = undefined
    const validatorOrOpts = headerItem?.validatorOrOpts
    const formControl = headerItem?.formControl
    accumulator[accumulatorKey] = formControl ? formControl(entity) : new FormControl(entity[item], validatorOrOpts)
  }

  private addFormControlsForAllEntityKeys(formOptions, entity: any, acc: {}, item: any) {
    if (formOptions.type === 'full') {
      if (acc[item] === undefined) {
        acc[item] = new FormControl(entity[item])
      }
    }
  }

  private addFormControlsForDynamicKeys(group: {}, header: AimsCommonTableHeader[], entity: any) {
    const additional = header.filter((x) => x !== undefined)
    // Only add if custom control or validatororopts (maybe consider adding another prop like "col.enableFormControl" in the future)
    additional
      .filter((i) => i.formControl !== undefined || i.validatorOrOpts !== undefined)
      .forEach((col) => {
        group[col.key] = col.formControl ? col.formControl(entity) : new FormControl('', col.validatorOrOpts)
      })
  }

  public transferFormValues(formOptions, group: any, row: any, oldRow: any) {
    // Couldn't use - Object.assign({}, oldRow) b/c of nested objects
    const cleanedRow = JSON.parse(JSON.stringify(oldRow))
    if (group.touched || formOptions.type === 'full') {
      const controls = (group as any).controls
      Object.keys(controls).forEach((name) => {
        this.transferValues(group, controls, cleanedRow, name)
      })
    }
    return cleanedRow
  }

  transferValues(group: AbstractControl, controls, row, name) {
    if (name.indexOf('.') > -1) {
      const control = controls[name] as FormControl
      Utils.setToValue(row, control.value, name)
      delete row[name]
    } else {
      row[name] = controls[name].value
    }
  }
}
