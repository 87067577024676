
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacManufacturers {
   public manufacturerId: number = 0;
   public manufacturer: string = null;
   public tenantId: number = 0;
   public facEquipment: FacEquipment[] = [];
   public tenant: NgTenants = null;
}