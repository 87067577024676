import { DataSource } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'

export interface IAimsCommonTableDataSource<T>
{
  data: T[]
  filteredData: T[]

  sort: MatSort
  paginator: MatPaginator

  addRowInitialObject?: object;

  filterChange(value: any, key: string): void
  resetFilters(): void
  setupDynamicFiltersAndSort(filterAndSortFunctions: { [key: string]: Function }): void
}

export abstract class AimsCommonTableDataSourceBase<T> extends DataSource<T> implements IAimsCommonTableDataSource<T>
{
  protected _data: T[] = []
  protected _filteredData: T[] = []
  protected _sort: MatSort;
  protected _paginator: MatPaginator;

  public get data(): T[] { return this._data }
  public set data(value: T[]) { this._data = value }

  public get filteredData(): T[] { return this._filteredData }
  public set filteredData(value: T[]) { this._filteredData = value }

  public get sort(): MatSort { return this._sort }
  public set sort(value: MatSort) { this._sort = value }

  public get paginator(): MatPaginator { return this._paginator }
  public set paginator(value: MatPaginator) { this._paginator = value }

  public filterChange(value: any, key: string): void {}
  public resetFilters(): void {}
  public setupDynamicFiltersAndSort(filterAndSortFunctions: { [key: string]: Function }): void {}
  public loadData(): void {};
  public init(): void {};
}
