
import { HrEmployees } from './index';
import { SchFlightAuthType } from './index';
import { SchLodgingAuthType } from './index';
import { SchRentalCarAuthType } from './index';
import { SchResources } from './index';
import { SchTravelRequestBags } from './index';
import { SchTravelRequestLegs } from './index';
import { NgTenants } from './index';
import { SchTravelRequestStatus } from './index';

export class SchTravelRequests {
   public travelRequestId: number = 0;
   public travelRequestStatusId: number = 0;
   public requestedOn: Date = new Date(0);
   public employeeId: number = null;
   public resourceId: number = null;
   public requestedById: number = 0;
   public approvedById: number = null;
   public approvedOn: Date = null;
   public qualityControlledById: number = null;
   public qualityControlledOn: Date = null;
   public nonRefundableAuth: boolean = false;
   public charter: boolean = false;
   public groundTime: number = null;
   public lodgingAuthTypeId: number = 0;
   public rentalCarAuthTypeId: number = 0;
   public travelRequestComments: string = null;
   public createdOn: Date = new Date(0);
   public createdById: number = 0;
   public modifiedOn: Date = null;
   public modifiedById: number = null;
   public confirmedOn: Date = null;
   public submittedOn: Date = null;
   public sentToTravelAgent: boolean = false;
   public tenantId: number = 0;
   public flightAuthTypeId: number = null;
   public approvedBy: HrEmployees = null;
   public createdBy: HrEmployees = null;
   public employee: HrEmployees = null;
   public flightAuthType: SchFlightAuthType = null;
   public lodgingAuthType: SchLodgingAuthType = null;
   public modifiedBy: HrEmployees = null;
   public qualityControlledBy: HrEmployees = null;
   public rentalCarAuthType: SchRentalCarAuthType = null;
   public requestedBy: HrEmployees = null;
   public resource: SchResources = null;
   public schTravelRequestBags: SchTravelRequestBags[] = [];
   public schTravelRequestLegs: SchTravelRequestLegs[] = [];
   public tenant: NgTenants = null;
   public travelRequestStatus: SchTravelRequestStatus = null;
}