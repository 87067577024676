
import { HrEmployees } from './index';
import { TrQuals } from './index';
import { NgTenants } from './index';

export class TrQualXAssigned {
   public qualAssignedId: number = 0;
   public qualId: number = 0;
   public employeeId: number = 0;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public qual: TrQuals = null;
   public tenant: NgTenants = null;
}