
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { TrTrainingGroup } from './index';

export class TrTrainingGroupXEmployees {
   public trainingGroupXemployeeId: number = 0;
   public trainingGroupId: number = 0;
   public employeeId: number = 0;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public tenant: NgTenants = null;
   public trainingGroup: TrTrainingGroup = null;
}