
import { QcObservations } from './index';
import { NgTenants } from './index';

export class QcDeficiencyTypes {
   public deficiencyTypeId: number = 0;
   public deficiencyType: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public qcObservations: QcObservations[] = [];
   public tenant: NgTenants = null;
}