
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class WoLaborEstimates {
   public laborEstimateId: number = 0;
   public workOrderId: number = 0;
   public description: string = null;
   public laborHours: number = 0;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public workOrder: WoWorkOrders = null;
}