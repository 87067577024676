
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrPscComments {
   public pscCommentId: number = 0;
   public pscId: number = 0;
   public commentDate: Date = new Date(0);
   public comment: string = null;
   public addedBy: string = null;
   public tenantId: number = 0;
   public psc: HrPsc = null;
   public tenant: NgTenants = null;
}