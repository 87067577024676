
import { FacWasteDrums } from './index';
import { NgTenants } from './index';

export class FacWasteContainerType {
   public containerTypeId: number = 0;
   public containerTypeCode: string = null;
   public containerTypeDescription: string = null;
   public maxAmount: number = 0;
   public tenantId: number = 0;
   public facWasteDrums: FacWasteDrums[] = [];
   public tenant: NgTenants = null;
}