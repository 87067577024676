import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  public set(key: string, value: string | object): void {
    if (typeof value === 'string') {
      sessionStorage.setItem(key, value)
    }
    const store = { ...(value as object), _storedAsObject: true }
    sessionStorage.setItem(key, JSON.stringify(store))
  }

  public setUpdate(key: string, value: object): void {
    const retrieved = this.get(key) as object
    this.set(key, { ...retrieved, ...value })
  }

  public get(key: string): string | object {
    const result = sessionStorage.getItem(key)
    const obj = JSON.parse(result)
    if (obj && obj._storedAsObject) {
      return obj
    }
    return result
  }
}
