
import { HrEmployeeXOtherSpecialtyType } from './index';
import { NgTenants } from './index';

export class HrOtherSpecialtyType {
   public otherSpecialtyTypeId: number = 0;
   public otherSpecialtyType: string = null;
   public tenantId: number = 0;
   public hrEmployeeXOtherSpecialtyType: HrEmployeeXOtherSpecialtyType[] = [];
   public tenant: NgTenants = null;
}