
import { QcObservations } from './index';
import { NgTenants } from './index';

export class QcObservationXjobControlNumbers {
   public observationXjobContrlNumberId: number = 0;
   public observationId: number = 0;
   public jobControlNumber: string = null;
   public tenantId: number = 0;
   public observation: QcObservations = null;
   public tenant: NgTenants = null;
}