import { Injectable } from '@angular/core'
import * as moment from 'moment-mini'

//Array of fields that are date only in the db
//Get translated to midnight local time
const dateOnlyField: Array<string> = [
  'readingDate',
  'weekEndingDate',
  'readingDate',
  'beginDate',
  'endDate',
  'weekOf',
  'modifiedDate',
  'dateAdded',
  'testDate',
  'effectiveOn',
  'probationStartFrom',
  'probationStartTo',
  'probationaryCompletionDateFrom',
  'probationaryCompletionDateTo',
  'driversLicenseExpDateFrom',
  'driversLicenseExpDateTo',
  'bridgedDateOfHireFrom',
  'bridgedDateOfHireTo',
  'dispatchDateFrom',
  'dispatchDateTo',
  'originalDateOfHireFrom',
  'originalDateOfHireTo',
  'clearanceDateFrom',
  'clearanceDateTo',
  'cacExpDateFrom',
  'cacExpDateTo',
  'recallExpireDateFrom',
  'recallExpireDateTo',
  'maxValidDate',
  'assignedDate',
]

//Array containing properties to always check for date
const isDate: Array<string> = [
  'acceptedDate',
  'acctReceivedOn',
  'accumulationDate',
  'acknDt',
  'actualEnd',
  'actualStart',
  'adjustmentDate',
  'announcementDate',
  'approvalDt',
  'approved',
  'approvedDate',
  'approvedOn',
  'apprvlDt',
  'arrival',
  'asofDate',
  'assignedDate',
  'assignmentStartDate',
  'beginDate',
  'birthdate',
  'bo',
  'bodate',
  'bridgedDateOfHireFrom',
  'bridgedDateOfHireTo',
  'bridgedDateofHire',
  'buyerAssignDt',
  'cacExpDateFrom',
  'cacExpDateTo',
  'cacExpireDate',
  'cacRequestToGov',
  'calDueDate',
  'calcEndDt',
  'calcStartDt',
  'canc',
  'cancelledDate',
  'cancelledOnDate',
  'certifiedOn',
  'changeDateTime',
  'chargeDate',
  'chkOutDate',
  'chngDt',
  'clearanceDate',
  'clearanceDateFrom',
  'clearanceDateTo',
  'closedApprovedDate',
  'closedOutDate',
  'commentDate',
  'complDt',
  'completedDate',
  'completedOn',
  'completedOnDate',
  'confirmedOn',
  'contractCompletionDate',
  'coordinatorRevDate',
  'created',
  'createdDate',
  'createdOn',
  'cutoverDate',
  'date',
  'dateAdded',
  'dateCancelled',
  'dateCompleted',
  'dateDue',
  'dateOfInquirey',
  'dateOfInquiry',
  'dateOfRequest',
  'dateOpened',
  'datePurchased',
  'dateReceived',
  'dateRepaired',
  'dateRequired',
  'dateShipped',
  'dateSubmitted',
  'dateSubmittedToAf',
  'dateSubmittedToQc',
  'dateTimeToExecute',
  'dateUsed',
  'deadlinedDate',
  'departSiteDate',
  'departure',
  'desiredDt',
  'disapprovedOn',
  'dispatchDateFrom',
  'dispatchDateTo',
  'dmApprovedOn',
  'dodNumberExp',
  'dor',
  'downloadEnd',
  'downloadStart',
  'driversLicenseExpDate',
  'driversLicenseExpDateFrom',
  'driversLicenseExpDateTo',
  'dueDate',
  'dueDt',
  'dueOutRelease',
  'eddPhq',
  'eddedf',
  'eddsite',
  'effectDt',
  'effectiveDate',
  'effectiveOn',
  'end',
  'endDate',
  'endDateTime',
  'entrDtt',
  'entryDtt',
  'enviroReview',
  'errcLastChanged',
  'estDelPhq',
  'estDeliveryDate',
  'estPhqDeliveryDate',
  'estRepairDate',
  'estimateTimeofRepair',
  'expiration',
  'expirationDate',
  'extApprovalDate',
  'finalReviewDate',
  'fromDate',
  'handBookReceipt',
  'hrAuditedOn',
  'hrmApprovedOn',
  'hydroTestDate',
  'inTransitDate',
  'insertTime',
  'inspectionDate',
  'installationCompDate',
  'installationDate',
  'invcDt',
  'issuedDate',
  'isu',
  'itReceivedOn',
  'lastCalibratedDate',
  'lastChanged',
  'lastChangedDate',
  'lastChngDt',
  'lastChngDtt',
  'lastEditedOn',
  'lastEoaDate',
  'lastFailedLogin',
  'lastGenDate',
  'lastInventoriedDate',
  'lastMassUpdate',
  'lastMeterReadingDate',
  'lastModDtt',
  'lastPasswordReset',
  'lastPromotionDate',
  'lastRevDate',
  'lastSuccessfulLogin',
  'lastUpdateDate',
  'lastUpdated',
  'lastUpdatedDate',
  'lastUpdatedDateTime',
  'lastUpdatedOn',
  'lastUploadDtt',
  'lastVchrdDt',
  'lastWeighedOn',
  'latestRevisionDate',
  'loaEndDate',
  'loaStartDate',
  'managerCompDate',
  'managerEstCompDate',
  'maxEffectiveDate',
  'maxValidDate',
  'modelYear',
  'modified',
  'modifiedDate',
  'modifiedOn',
  'nextCalibrationDue',
  'nextDueDate',
  'nextGenDate',
  'nextInventoryDate',
  'notamcloseTime',
  'notamopenTime',
  'noticeToProceedDate',
  'occDate',
  'occDateFrom',
  'occDateTo',
  'offSiteDate',
  'onSiteDate',
  'openedApprovedDate',
  'openedDate',
  'ordDt',
  'origDueDt',
  'originalDateOfHireFrom',
  'originalDateOfHireTo',
  'originalDateofHire',
  'orlDue',
  'orlGltransfer',
  'orlInterface',
  'orlIplastupdate',
  'orlRevised',
  'passportExp',
  'passwordDate',
  'passwordExpirationDate',
  'perfEndDt',
  'perfStartDt',
  'phqReceivedDate',
  'pkdate',
  'pmcfrontecArctecHireDate',
  'pmmApprovedOn',
  'poDesiredDt',
  'poDueDt',
  'poLnCloseDt',
  'poOrigDueDt',
  'poTypeChngDtt',
  'podate',
  'probationEndDate',
  'probationStart',
  'probationStartDate',
  'probationStartFrom',
  'probationStartTo',
  'probationaryCompletionDate',
  'probationaryCompletionDateFrom',
  'probationaryCompletionDateTo',
  'projEndDt',
  'projMngrReviewedDate',
  'projStartDt',
  'propDueDt',
  'proposalSubmittedDate',
  'pulledDate',
  'qtReqdDt',
  'qtRfqDt',
  'qtValidDt',
  'qualityControlledOn',
  'rcvdPhq',
  'readingDate',
  'readyToShip',
  'rec',
  'recAtSiteDate',
  'recallExpireDate',
  'recallExpireDateFrom',
  'recallExpireDateTo',
  'receiptDate',
  'receivedDate',
  'recptDt',
  'refreshTokenExpirationDate',
  'rejectedOn',
  'relToVendDt',
  'reliefEnd',
  'reliefStart',
  'reqCompleteDate',
  'reqCreatedDate',
  'reqDate',
  'reqTime',
  'requestDate',
  'requestedOn',
  'requiredByDate',
  'requiredDate',
  'responseDate',
  'returnDate',
  'returnFromSite',
  'reviewedDate',
  'revisionDate',
  'revokedOn',
  'rfpreceivedDate',
  'rqDt',
  'rqExportDtt',
  'rqstDt',
  'rtstart',
  'rtstop',
  'rxPhq',
  'safetyPlan',
  'sampleReceivedAtLab',
  'sampleSentDate',
  'schedConstStartDate',
  'scheduleItemEnd',
  'scheduleItemStart',
  'scheduledStartDate',
  'sealedDate',
  'shipDate',
  'siteLastRevDate',
  'start',
  'startDate',
  'startDateTime',
  'startTime',
  'step1ApprovedDate',
  'step2ApprovedDate',
  'step3ApprovedDate',
  'step4ApprovedDate',
  'submitted',
  'submittedDate',
  'submittedOn',
  'submittedToLogistics',
  'termDateLayoffDate',
  'testDate',
  'tgtPlaceDt',
  'timeOccurred',
  'timeOperational',
  'timeReported',
  'timeStamp',
  'timeToAfjc',
  'timeUtc',
  'toDate',
  'transferDate',
  'travelConfirmed',
  'travelDate',
  'travelOn',
  'trnCrncyDt',
  'tttDateCancelled',
  'tttDateCompleted',
  'tttDateSubmittedToAf',
  'tttRbDiscussionDate',
  'tttRequestDate',
  'tttRequiredDate',
  'udefDt',
  'updatedOn',
  'vacEnd',
  'vacStart',
  'validUntil',
  'vendCertDt',
  'veteranDischargeDate',
  'voidRejectDt',
  'weekEndingDate',
  'weekOf',
  'workDate',
  'workSectionCompDate',
  'workSectionSuspenseDate',
  'workStartDate',
  'wsLeadReviewedOn'
]

@Injectable({
  providedIn: 'root',
})
export class HandleJsonService {
  constructor() {}

  private isIsoDate(str: string): boolean {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
      return false
    }
    const d = new Date(str)
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str // valid date
  }

  //Converts JSON date to objects
  private handleObject(obj: Object) {
    try {
      for (let prop in obj) {
        //Anything not in the isDate array is not checked
        //Adding check for the iso format since the moment strict check doesn't seem to work
        // if (!prop.endsWith('Id') && notDate.indexOf(prop) == -1)
        if (prop.endsWith('Date') || isDate.indexOf(prop) !== -1) {
          let mom = moment(obj[prop], moment.ISO_8601, true)
          if (mom.isValid()) {
            if (moment('1983').isAfter(mom)) {
              mom.add(2, 'hours') //AK time was -10 prior to 1983, Entity Framework JSON date parser doesn't account for this, but Javascript does
            }

            //If midnight UTC assume its a date only field and we need to convert it to midnight local to remove timezone offset
            //if we have specified its a date field in array above
            const midnight = moment.utc(mom).startOf('date')
            const offset = mom.utcOffset()
            if (mom.isSame(midnight) && dateOnlyField.indexOf(prop) != -1) {
              mom = moment(mom.add(-offset, 'minutes').startOf('date'))
            }
            let date: Date = mom.toDate()
            obj[prop] = date
          }
          //Handle child object
          else if (typeof obj[prop] == 'object') {
            this.handleObject(obj[prop])
          }
          //Handle levels of arrays of objects
          else if (Array.isArray(obj[prop])) {
            for (let lvlObj of obj[prop]) {
              this.handleObject(lvlObj)
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  public handleMapping(res) {
    let jsonData = res
    if (Array.isArray(jsonData)) {
      for (let obj of jsonData) {
        this.handleObject(obj)
      }
    } else {
      //Do this if returning single object instead of array
      this.handleObject(jsonData)
    }
    return jsonData
  }
}
