import { Component, OnInit, isDevMode } from '@angular/core';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public permissions: PermissionsService) { }

  user = this.permissions.user;

  env = environment;

  ngOnInit() {
  }

}
