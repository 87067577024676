import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { PermissionsService } from '../services/permissions/permissions.service';
import { AimsCommonConfirmDialogComponent, AimsCommonConfirmDialogModel } from '@aims/common-components';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../services/message/message.service';
import { DialogComponent } from '../services/message/message.enum';



//Allows us to not type the <hostname>/AIMSApi/api/ bit of the url

@Injectable()
export class UpdateUrlInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog,
    private messageService: MessageService,
    private permissions: PermissionsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let updatedUrlReq = req.clone();
    let url = req.url;
    const user = this.permissions.user;

    if (url.indexOf('http') == -1) {
      url = `${environment.apiUrl}/${url}`;
      let cloneConfig = {
        url: url
      };
      updatedUrlReq = req.clone(cloneConfig);
    }




    return next.handle(updatedUrlReq)
      .pipe(catchError((err, caught) => {
        if (isPlatformBrowser(this.platformId)) {
          if (!(err.error instanceof ProgressEvent) && err.error != null) {
            //Alert the user, show error
            const message = (typeof err.error === 'object') ? JSON.stringify(err.error) : err.error
            
            var dialogData = this.messageService.getDialogMessage(DialogComponent.AimsError, message);
            let dialogRef = this.dialog.open(AimsCommonConfirmDialogComponent, {
              data: dialogData,
              disableClose: true
            })
            dialogRef.afterClosed().subscribe((dialogResult) => {
            });
          }
          else {
            //API didn't respond, usually it's not running or a connection refused error.
            //Limit to only show one dialog here, otherwise every failed request will show a new dialog
            const message = "***** REFRESH PAGE TO TRY AGAIN ***** \n\n Troublshooting Info:\n" + JSON.stringify(err, null, 4)
            if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) return;
            var dialogData = this.messageService.getDialogMessage(DialogComponent.AimsInternetConnectivityError, message);
            let dialogRef = this.dialog.open(AimsCommonConfirmDialogComponent, {
              data: dialogData
            })
            dialogRef.afterClosed().subscribe((dialogResult) => {
            });

          }
        }
        return throwError(err);
      }));
  }
}
