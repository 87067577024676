import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ContentChild,
  TemplateRef,
  ElementRef,
  SimpleChanges,
  HostListener,
} from '@angular/core'
import { MatSort } from '@angular/material/sort'
import { MatRow, MatTable } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { SelectionModel } from '@angular/cdk/collections'
import {
  AimsCommonTableSelection,
  TablePaginationState,
  TableRowUpdate,
  TableRowDelete,
  TableSortState,
  AimsCommonTableHeader,
  TableRowAdd,
  AimsCommonTablePermissions,
  TableState,
  AimsCommonTableAllSelection,
  TablePrint,
  FormOptions,
  TableRowClass,
} from './aims-common-table.interface'
import { AimsCommonTableDataSource } from './aims-common-table-data-source'
import { AimsCommonTableDataSourceBase, IAimsCommonTableDataSource } from './aims-common-table-data-source-interface'
import { AimsCommonTableServiceService } from './aims-common-table-service.service'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { AimsCommonTableExpandedRowDirective } from './aims-common-table-expanded-row/aims-common-table-expanded-row.directive'
import { AbstractControlOptions, AsyncValidatorFn, FormArray, FormControl, ValidatorFn } from '@angular/forms'
import Utils from '../shared/utils'
import { MatDialog } from '@angular/material/dialog'
import {
  AimsCommonConfirmDialogComponent,
  AimsCommonConfirmDialogModel,
} from '../aims-common-confirm-dialog/aims-common-confirm-dialog.component'
import { AimsCommonTableFilterDirective } from './aims-common-table-dynamic-row/aims-common-table-filter.directive'
import { AimsCommonTableFooterDirective } from './aims-common-table-dynamic-row/aims-common-table-footer.directive'
import { AimsCommonTableHeaderDirective } from './aims-common-table-dynamic-row/aims-common-table-header.directive'
import { AimsCommonTableDynamicRowComponent } from './aims-common-table-dynamic-row/aims-common-table-dynamic-row.component'
import { AimsCommonTableDynamicToolbarDirective } from './aims-common-table-dynamic-toolbar/aims-common-table-dynamic-toolbar'
import { Observable, PartialObserver, asyncScheduler, of } from 'rxjs'
import { memoized } from '../shared/memoize.decorator'
import * as _ from 'lodash'
import { AimsCommonFilterSelectionComponent, AimsCommonTableFilterSelection, AimsCommonTableFilterSelectionValue } from '../aims-common-filter-selection/aims-common-filter-selection.component'


/**
 * Aims Common Table
 *
 * See the example as a reference guide to using the table. Below are some advanced customizations you can add to the table

### Disabled controls need to be set in the header rather than in html
This is because FormControl's handle the disabled attributes

tableHeader = [{
  key: 'mondayHrs',
  type: 'dynamic',
  label: 'Monday',
  formControl: (entity) => new FormControl(
    {
      value: entity.mondayHrs,
      disabled: !this.isDayEditable(DAY_OF_WEEK.Monday, entity.timeType)
    },
    [Validators.required, Validators.min(0)]
  )
}]
### Custom validators (i.e. calculations based on user input) can be set on the headers
- Either inside the formControl
tableHeader = [{
  key: 'mondayHrs',
  type: 'dynamic',
  label: 'Monday',
  formControl: (entity) => new FormControl(
    {
      value: entity.mondayHrs,
      disabled: !this.isDayEditable(DAY_OF_WEEK.Monday, entity.timeType)
    },
    [Validators.required, Validators.min(0), this.isOverMaxHoursValidator(DAY_OF_WEEK.Monday)]
  )
  }]
- Or it could also be set in a regular dynamic control
tableHeader = [{
{ key: 'taskId', label: 'Task', type: 'dynamic', validatorOrOpts: [Validators.required, , this.isOverMaxHoursValidator(DAY_OF_WEEK.Monday)]},
}]

### Header Classes - If it's always a color, you can set that className in quotes
tableHeader = [{
  key: 'mondayHrs',
  type: 'dynamic',
  label: 'Monday',
  headerClass: 'holiday',
})
- Or call a function if it changes
tableHeader = [{
  key: 'mondayHrs',
  type: 'dynamic',
  label: 'Monday',
  headerClass: this.setTimeEntryTableHeaderColors(this.timeEntryTableHeaderWeek[DAY_OF_WEEK.Monday].day),
})

### Row Classes - Call a function to determine colors, usually it should return an object
rowClass = (context: TableRowClass) => {
  return { 'className': true };
}

<aims-common-table
  label=""
  id="standard-view-workorders-table"
  [rowClass]="rowClass"

### Adding a tooltip to the header
  This example is a slimmed down version of other usages, but you can use it for reference.
  Find others by searching for aimsHeaderTemplate
  If your tooltip is multi-line, it's important to have \n\n between them, otherwise it won't render correctly
  It's also crucial to keep the class

calcTimeEntryPayShiftTooltip(){
  return "Pay Shifts (Reg/OT/CallOuts)\n\n"
}

<aims-common-table-dynamic-row id="payShift">
  <ng-template aimsHeaderTemplate let-formGroup="formGroup">
    <div
      matTooltipClass="matToolTipMultiLine"
      matTooltip="{{calcTimeEntryPayShiftTooltip()}}">PS <i class="fas fa-question-circle"></i>
    </div>
  </ng-template>

### Adding a dynamic toolbar for custom buttons
 - Note: This entirely replaces the toolbar that has the Add/Export buttons so you'll need to add everything yourself
<ng-template aimsCommonTableDynamicToolbar>
  <aims-common-button *ngIf="create" aria-label="Add" (click)="handleRowAdd()">
    <mat-icon>add</mat-icon>
    Add
  </aims-common-button>
</ng-template>

### Adding a footer row to a table. This is helpful for totalling up values in each column
### Add [footer]="true" in aims-common-table properties

- The actual calculation is truncated for simplicity
calcTimeEntrySummaryHrs(){
  return calculatedTotal
}

<aims-common-table-dynamic-row id="mondayHrs">
  <ng-template viewMode>...</ng-template>
  <ng-template editMode>...</ng-template>
  <ng-template aimsFooterTemplate let-element>
    {{ timecard.accTimeEntry?.length > 0 ?
      calcTimeEntrySummaryHrs(ALL_WEEK_DAYS[DAY_OF_WEEK.Monday].field)
      ""
    }}
  </ng-template>


### Custom error messages for inputs, dropdowns, etc
<aims-common-table-dynamic-row id="siteId">
  <ng-template viewMode let-element>....</ng-template>
  <ng-template editMode ...>
    <mat-form-field appearance="fill" class="form-component">
      <mat-label>Site</mat-label>
      <mat-select [formControl]="dynamicControl">
        <mat-option></mat-option>
        <mat-option *ngFor="let val of sites" [value]="val.siteId">
          {{val.siteName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="dynamicControl.hasError('required')">You must make a selection</mat-error>
    </mat-form-field>
  </ng-template>


  You can also set hints
  this.formGroup.controls.password.setErrors({ mismatch: true });

  <mat-hint>
    <div *ngFor="let validation of validations.password" >
      <div
          *ngIf="formGroup.controls.password.hasError(validation.type)">
          {{validation.message}}
      </div>
    </div>
  </mat-hint>
 */
@Component({
  selector: 'aims-common-table',
  exportAs: 'aimsTable',
  templateUrl: './aims-common-table.component.html',
  styleUrls: ['./aims-common-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AimsCommonTableComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() label: string
  @Input() id: string
  @Input() dataSource: any
  @Input() header: AimsCommonTableHeader[]
  @Input() footer: boolean
  @Input() permissions: AimsCommonTablePermissions
  @Input() displayedColumns: string[] = []
  @Input() stickyColumns: string[] = []
  @Input() stickyHeader: boolean = false
  @Input() pageSize: number
  @Input() addRowInitialObject: {}
  @Input() defaultTableState: TableState = {}
  @Input() editRowOnClick: boolean = false
  @Input() exportFunction: Function
  @Input() filterSelection: AimsCommonTableFilterSelection[];
  @Input() exportFileName: string
  @Input() rowValidatorOrOpts: ValidatorFn | AbstractControlOptions | ValidatorFn[]
  // @Input() rowAsyncValidator: AsyncValidatorFn | AsyncValidatorFn[]
  @Input() formOptions: FormOptions = { type: 'default' }
  @Input() rowClass: (context: TableRowClass) => {}
  @Input() hidePageSize: boolean; // passed to mat-paginator

  // grid alert fields
  @Input() hasGridAlert: boolean;
  @Input() dailyChangeAlertSelected: boolean = false;
  @Input() everyChangeAlertSelected: boolean = false;
  @Output() dailyAlert: EventEmitter<any> = new EventEmitter<any>()
  @Output() everyAlert: EventEmitter<any> = new EventEmitter<any>()

  @Output() rowClick: EventEmitter<MatRow> = new EventEmitter<MatRow>()
  @Output() selectionClick: EventEmitter<AimsCommonTableSelection> = new EventEmitter<AimsCommonTableSelection>()
  @Output()
  selectionAllClick: EventEmitter<AimsCommonTableAllSelection> = new EventEmitter<AimsCommonTableAllSelection>()
  @Output() rowUpdated: EventEmitter<TableRowUpdate> = new EventEmitter<TableRowUpdate>()
  @Output() rowDeleted: EventEmitter<TableRowDelete> = new EventEmitter<TableRowDelete>()
  @Output() rowAdded: EventEmitter<TableRowUpdate> = new EventEmitter<TableRowUpdate>()
  @Output() rowEdit: EventEmitter<TableRowUpdate> = new EventEmitter<TableRowUpdate>()
  @Output() rowAdd: EventEmitter<TableRowUpdate> = new EventEmitter<TableRowUpdate>()
  @Output() rowCancelled: EventEmitter<TableRowUpdate> = new EventEmitter<TableRowUpdate>()
  @Output() onPrint: EventEmitter<TablePrint> = new EventEmitter<TablePrint>()
  @Output() onPageChange: EventEmitter<TablePaginationState> = new EventEmitter<TablePaginationState>()
  @Output() onSortChange: EventEmitter<TableSortState> = new EventEmitter<TableSortState>()
  @Output() rowExpanded: EventEmitter<any> = new EventEmitter<any>()

  @Output() exportStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportComplete: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatTable) table: MatTable<any>
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(AimsCommonFilterSelectionComponent) filterSelector:AimsCommonFilterSelectionComponent;

  @ContentChildren(AimsCommonTableDynamicRowComponent)
  inputDynamicColumns: QueryList<AimsCommonTableDynamicRowComponent>
  @ContentChild(AimsCommonTableExpandedRowDirective) inputExpandedRowDef: AimsCommonTableExpandedRowDirective
  @ContentChild(AimsCommonTableDynamicToolbarDirective) inputToolbarDef: AimsCommonTableDynamicToolbarDirective

  public dynamicRowDefs: { [key: string]: AimsCommonTableDynamicRowComponent } = {}
  public expandedRowDef: TemplateRef<ElementRef>
  public toolbarDef: TemplateRef<ElementRef>
  public data: IAimsCommonTableDataSource<unknown>
  public getValue = Utils.getValue
  selection: { [key: string]: SelectionModel<any> } = {}
  filterValues: { [key: string]: string | undefined } = {}
  displayedFilterColumns: string[] = []
  private _expandedElement: any
  public get expandedElement(): any {
    return this._expandedElement
  }
  public set expandedElement(value: any) {
    this._expandedElement = value
  }
  controls: Array<any> = [];
  edittingRowElement: any
  loading: boolean = true
  addRowInitial: {} | undefined;
  elementRowEdit // Used for row editing
  getDataIdxMem
  isEditing: boolean = false;  // true when editing row
  editingRowIndex: number | undefined = undefined; // index of row being edited
  expandKey: string | undefined = undefined;


  constructor(private tableService: AimsCommonTableServiceService, private dialog: MatDialog) {
    this.getDataIdxMem = _.memoize(this.getDataIdx,
      function (args) {
        // args is arguments object as accessible in memoized function
        return JSON.stringify(args)
      }
    )
  }

  /**
   * Equality method used for expandedColumn. Keeps expanded column open when data refreshed.
   * If KEY column  was defined in AimsCommonTableHeader, use key property to compare the two objects for equality.
   * If no KEY defined, then do a shallow comparison of non-object properties for equality.
   *  ex: { key: 'inventoryItemId', label: '', type: AimsCommonTableHeaderType.KEY, hidden: true},
   * @param o1
   * @param o2
   * @returns
   */
  shallowObjectOrKeyEqual(o1, o2) {
    if (o1 == undefined || o1 == null  || o2 == undefined || o2 == null )
    {
      return false;
    }

    const entries1 = Object.entries(o1);
    const entries2 = Object.entries(o2);
    if (entries1.length !== entries2.length) {
      return false;
    }

    if (this.expandKey && o1.hasOwnProperty(this.expandKey) && o2.hasOwnProperty(this.expandKey) && (o1[this.expandKey] == o2[this.expandKey])) {
      return true;
    }

    for (let i = 0; i < entries1.length; ++i) {
      // Keys
      if (entries1[i][0] !== entries2[i][0]) {
        return false;
      }
      // Values
      let eitherAreObjects = typeof(entries1[i][1]) === 'object' || typeof(entries2[i][1]) === 'object';

      if (!eitherAreObjects && entries1[i][1] !== entries2[i][1]) {
        return false;
      }
    }

    return true;
  }

  public ngAfterContentInit(): void {
    this.inputDynamicColumns.forEach((component) => {
      this.dynamicRowDefs[component.id] = component
    })
    this.expandedRowDef = this.inputExpandedRowDef?.host
    this.toolbarDef = this.inputToolbarDef?.host
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.editRowOnClick) {
      return
    }
    if (!this.elementRowEdit) {
      return
    }
    const rowEl = this.elementRowEdit[0].target.closest('tr')
    const overlay = event.target.closest('.cdk-overlay-container')

    // If clicked outside => close and save the edit row
    if (rowEl && !rowEl.contains(event.target) && !overlay?.contains(event.target)) {
      const [$event, row, idx] = this.elementRowEdit
      if (this.controls[idx].invalid) {
        // Form is invalid we can't save it.
        return
      }
      this.elementRowEdit = undefined
      asyncScheduler.schedule(() => this.handleEditRow($event, row, 'save', idx), 50)
    }
  }

  public ngOnInit(): void {
    // Default table permissions if none are specified
    if (!this.permissions) {
      this.permissions = { create: false, delete: false, update: false, clear: true }
    }
    this.tableService.setPaginatorState(this.getTableId(), this.paginator, this.pageSize)
    if (!this.id) {
      this.label = 'No Table ID was specified, please add!'
      throw new TypeError(
        "<aims-common-table> 'id' attribute is required for table sorting and filtering to persist. Ensure this 'id' is unique, otherwise tables may show empty by incorrectly filtering"
      )
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.dataSource) {
      return
    }

    // Original intent for displayed columns was to allow the user to hide/show columns dynamically
    // If no display columns defined, show all columns by default
    // If we change the headers then update the displayed columns
    // Don't include hidden columns when displaying
    if (this.displayedColumns.length === 0 || changes.header?.currentValue !== changes.header?.previousValue) {
      this.displayedColumns = this.header
        .filter((h) => h.hidden !== true)
        .map((x) => {
          if ((x.type === 'date' || x.type === 'datetime') && !x.filterType) {
            x.filterType = 'date' // Default date to date filter
          }
          return x.key
        })
    }

    this.displayedFilterColumns = this.displayedColumns.map((i) => i + '-filter');

    let keyColumn = this.header.find((h) => h.type == 'key');
    if (keyColumn) {
      this.expandKey = keyColumn.key
    }

    this.isEditing = false;

    //reset the controls
    if (changes && changes.dataSource && changes.dataSource.currentValue !== changes.dataSource.previousValue) {
      this.controls = [];
      this.getDataIdxMem.cache = new _.memoize.Cache;
    }

    if (this.dataSource instanceof AimsCommonTableDataSourceBase) {
      this.data = this.dataSource;
      // TODO: update addRowInitial when data is available if addRowInitialObject is not set
      this.addRowInitial = this.addRowInitialObject || {};
    } else {
      this.data = new AimsCommonTableDataSource(this.dataSource)
      // TODO: what if this.dataSource is empty?
      this.addRowInitial = this.addRowInitialObject || Utils.cloneObjectKeys(this.dataSource[0])
    }

    //setup pagination, filtering and sorting
    this.data.setupDynamicFiltersAndSort(Utils.getFiltersAndSort(this.header))
    this.data.paginator = this.paginator
    this.tableService.setFilterState(this.getTableId(), this.data, this.filterValues, this.defaultTableState.filter);

    this.header
      .filter((i) => i.type === 'checkbox')
      .forEach((i) => {
        this.selection[i.key] = new SelectionModel<any>(true, [])
      })

    this.loadSort()

    this.loading = false
  }

  ngAfterViewInit() {
    this.loadSort()
  }

  loadSort() {
    // Sort can load if the data is not async
    // If it's async, this will fire with lifecycle and add sort
    if (!this.data || !this.sort) {
      return
    }
    this.data.sort = this.sort
    this.sort.initialized.subscribe(() => {
      // Figure out how to initialize this sooner.
      asyncScheduler.schedule(() => this.tableService.setSortState(this.getTableId(), this.sort, this.defaultTableState.sort), 100)
    })
  }

  handlePaginatorChanges(paginator: TablePaginationState) {
    this.tableService.setState(this.getTableId(), { paginator })
    this.onPageChange.emit(paginator);
  }

  handleSortChange(sort: TableSortState) {
    this.tableService.setState(this.getTableId(), { sort })
    this.onSortChange.emit(sort);
  }

  handleRowClick($event, row: MatRow, action: string, idx: number) {
    if ($event.target.localName === 'mat-icon' || $event.target.localName === 'button') {
      return
    } // Clicked save, edit, cancel, delete
    if (this.editRowOnClick && !this.elementRowEdit) {
      const realIdx = this.getIdx(idx, row)
      this.elementRowEdit = [$event, row, realIdx]
      this.handleEditRow($event, row, action, realIdx)
    }
    // This is available to use for expand row as well in the future.
    // this.expandedElement = this.expandedElement === row ? null : row;
    this.rowClick.emit(row)
  }

  handleExpandRowClick($event, row: MatRow, element:any, col:AimsCommonTableHeader, expandOnly:boolean = false) {
    const elementToToggle = this.shallowObjectOrKeyEqual(row, this.expandedElement) ? null : row;
    // rare cases where you want to ignore toggles that would contract the expanded section
    if (expandOnly && !elementToToggle) {
      return;
    }
    this.expandedElement = elementToToggle;

    if (col.events?.click) {
      col.events?.click(element, this.shallowObjectOrKeyEqual(row, this.expandedElement));
    }
    $event?.stopPropagation();
    this.rowExpanded.emit(this.expandedElement);
  }

  handleSubscribe(): PartialObserver<any> {
    this.loading = true
    return {
      complete: () => {
        this.loading = false
      },
      error: () => {
        this.loading = false
      },
    }
  }

  handleEditRow($event, oldRow: MatRow, action: string, idx: number) {
    const index = this.getIdx(idx, oldRow)

    this.edittingRowElement = this.edittingRowElement === oldRow ? null : oldRow

    if (action === 'edit' && this.editRowOnClick) {
      this.elementRowEdit = [$event, oldRow, idx]
    }
    if (action === 'edit' && !this.editRowOnClick) {
      //Entering edit mode
      const formRow = this.controls[index].value
      const newRow = this.tableService.transferFormValues(this.formOptions, this.controls[index], formRow, oldRow)
      this.rowEdit.emit({
        index,
        newRow,
        oldRow,
        oldData: this.dataSource,
        handleSubscribe: this.handleSubscribe.bind(this),
      });
      this.isEditing = true;
      this.editingRowIndex = index;
    }

    if (action === 'add') {
      const formRow = this.controls[index].value
      const newRow = this.tableService.transferFormValues(this.formOptions, this.controls[index], formRow, oldRow)
      delete newRow._add
      this.rowAdded.emit({
        index,
        newRow,
        oldRow,
        oldData: this.dataSource,
        handleSubscribe: this.handleSubscribe.bind(this),
      })
      newRow._add = false
      this.controls[index].patchValue({ _add: false })
      this.isEditing = false;
      this.editingRowIndex = undefined;
    }

    if (action === 'save') {
      const formRow = this.controls[index].value
      const newRow = this.tableService.transferFormValues(this.formOptions, this.controls[index], formRow, oldRow)
      this.elementRowEdit = undefined
      this.rowUpdated.emit({
        index,
        newRow,
        oldRow,
        oldData: this.dataSource,
        handleSubscribe: this.handleSubscribe.bind(this),
      })
      this.isEditing = false;
      this.editingRowIndex = undefined;
    }

    if (action === 'cancel') {
      if (this.controls[index].value._add) {
        this.data.data = this.data.data.slice(1)
      }

      //reset every control, otherwise it's off by one
      for (var i = 0; i < this.data.data.length; i++) {
        this.controls[i] = this.tableService.setupSingleControl(
          this.formOptions,
          this.data.data[i],
          this.header,
          this.rowValidatorOrOpts)
      }

      this.elementRowEdit = undefined
      this.rowCancelled.emit({
        index,
        newRow: null,
        oldRow,
        oldData: this.dataSource,
        handleSubscribe: this.handleSubscribe.bind(this),
      });
      this.isEditing = false;
      this.editingRowIndex = undefined;
    }
  }

  handleDeleteRow($event, row: MatRow, action: string, idx: number) {
    const index = this.getIdx(idx, row)
    this.edittingRowElement = row
    if (action === 'delete') {
      const dialogData = new AimsCommonConfirmDialogModel(
        'Delete Item?',
        'Are you sure you want to remove this item? This cannot be undone.',
        'Delete'
      )
      let dialogRef = this.dialog.open(AimsCommonConfirmDialogComponent, {
        data: dialogData,
      })
      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.isEditing = false;
          this.rowDeleted.emit({
            index,
            row,
            handleSubscribe: this.handleSubscribe.bind(this),
          })
        }
      });
      this.editingRowIndex = undefined;
    }
  }

  handleAddRow() {
    // + Add button clicked
    this.data.data = [{ ...this.addRowInitial, _add: true }, ...this.data.data]
    this.data.resetFilters()
    this.paginator.firstPage()
    const control = this.tableService.setupSingleControl(
      this.formOptions,
      this.data.data[0],
      this.header,
      this.rowValidatorOrOpts
    )
    control.addControl('_add', new FormControl(true));
    this.controls.unshift(control);
    this.edittingRowElement = this.data.data[0];
    this.isEditing = true;
    this.editingRowIndex = 0;

     this.rowAdd.emit({
        index: 0,
        newRow: this.edittingRowElement,
        oldRow: null,
        oldData: this.dataSource,
        handleSubscribe: this.handleSubscribe.bind(this),
      });
  }

  everyChange() {
    this.everyAlert.emit();
  }
  dailyChange() {
    this.dailyAlert.emit();
  }

  @memoized()
  getButtonLabel(col: AimsCommonTableHeader, element: any) {
    return col.label instanceof Function ? col.label(element) : col.label
  }

  primaryLabels = [ 'Edit', 'Accept', "Claim", "Acknowledge", 'Up', 'Down', 'Return','Receive' ];
  @memoized()
  getButtonColor(col: AimsCommonTableHeader, element: any) {
    if (col.button?.color) {
      return col.button?.color;
    }

    var label: string = this.getButtonLabel(col, element).toString()
    let color = 'basic'
    if (label == 'Deactivate' || label == 'Delete' || label == 'Reject') {
      color = 'warn'
    } else if (this.primaryLabels.includes(label)) {
      color = 'primary'
    }

    return color
  }

  @memoized()
  getButtonIcon(col: AimsCommonTableHeader, element: any) {
    if (col.button?.icon) {
      return col.button?.icon;
    }

    var label = this.getButtonLabel(col, element)
    let action = ''
    switch (label) {
      case 'Deactivate':
      case 'Reject':
        action = 'block' //cancel icon
        break
      case 'Activate':
        action = 'add' //plus icon
        break
      case 'Accept':
        action = 'check' //check icon
        break
      case 'Edit':
      case 'Edit/Password Reset':
        action = 'create' //pencil icon
        break
      case 'Delete':
        action = 'delete' //trash icon
        break
      case 'Save':
        action = 'save' //save icon
        break
      case 'Close':
        action = 'close' //X icon
        break
      case 'Receive':
      case 'Acknowledge':
        action = 'thumb_up' //thumb up icon
        break
      case 'Claim':
        action = 'flag' //flag icon
        break
      case 'Print':
        action = 'print' //printer icon
      case 'Up':
        action = 'arrow_upward' //up arrow icon
        break
      case 'Down':
        action = 'arrow_downward' //down arrow icon
        break
      case 'Return':
        action = 'undo' // undo arrow
        break
    }
    return action
  }

  handleClear() {
    this.sort.sort({ id: '', start: 'desc', disableClear: false })
    this.tableService.setState(this.getTableId(), {})
    this.data.resetFilters()
    this.tableService.resetFilterState(this.getTableId())

    this.paginator.firstPage()
    Object.keys(this.filterValues).every((i) => {
      this.filterValues[i] = undefined
      return true
    })
    this.filterSelector.selectedFilterId = undefined;
  }
  //Gets the correct index by encapsulating sorting, filtering, etc. Performance is important for this, called a lot
  getIdx(idx: number, element: MatRow): number {
    let result

    if (Object.keys(this.filterValues).length > 0 || (this.sort && this.sort.active)) {
      //get index when filtered and sorted, do the expensive lookup.
      result = this.getDataIdxMem(element)
    } else {
      //get index by paging
      result = idx + this.paginator.pageIndex * this.paginator.pageSize
    }

    // Setting up form controls for visible elements (think sorting, filting, pagination all have controls but we don't want to display them).
    // Need to find a better spot to update the form controls
    if (!this.controls[result]) {
      this.controls[result] = this.tableService.setupSingleControl(
        this.formOptions,
        element,
        this.header,
        this.rowValidatorOrOpts
      )
    }

    return result
  }

  /** Use the getDataIdxMem so it speeds up this call to find the data index since getIdx is called many times */
  getDataIdx(element) {
    return this.data.data.findIndex((i) => i === element)
  }

  getRowClass(element, idx) {
    const result = this.rowClass ? this.rowClass({ element: element, formGroup: this.getControlForm(idx) }) : {}
    return { 'element-row': true, selected: this.isSelected(element), ...result }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(key: string) {
    this.isAllSelected(key)
      ? this.selection[key].clear()
      : this.data.filteredData.forEach((row) => this.selection[key].select(row));

    var selections = this.selection[key].selected

    this.selectionAllClick.emit({ key, selections: selections })
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row, col?): string {
    if (col) {
      return `${this.isAllSelected(col.key) ? 'select' : 'deselect'} all`
    }
    return `${this.selection[col.key].isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(key: string) {
    const numSelected = this.selection[key].selected.length
    const numRows = this.data.filteredData.length
    return numSelected === numRows
  }

  /** Need to finish adding ability to stick columns - for each matColumnDef need to add this -> [sticky]="isSticky(col.id)" */
  isSticky(id: string) {
    return (this.stickyColumns || []).indexOf(id) !== -1
  }

  selectionKey: string | null = null
  isSelected(row): boolean {
    let key = this.selectionKey || this.header[0].key
    return this.selection[key] ? this.selection[key].selected.indexOf(row) !== -1 : false
  }

  handleSelection($event, key: string, row: MatRow) {
    this.selectionKey = key
    if ($event) {
      this.selection[key].toggle(row)
    }
    this.selectionClick.emit({ key, row, selections: this.selection[key].selected })
  }

  detailsClick(col, row: MatRow) {
    return col.link(row)
  }

  filterChange($event, col) {
    const filter = $event.value /* select */ || $event.target?.value || ''
    this.filterValues[col.key] = filter
    this.data.filterChange(filter, col.key)
    this.tableService.saveFilterState(this.getTableId(), { [col.key]: filter })
  }

  getFilterTemplateContext(col: AimsCommonTableHeader) {
    return {
      $implicit: this.filterValues,
      filter: this.filterValues[col.key],
      filters: this.filterValues,
      filterChange: ($event) => this.filterChange({ value: $event }, col),
    }
  }

  getTableId() {
    return this.id
  }

  getControl(idx, fieldName) {
    if (this.controls[idx]) {
      return this.controls[idx].controls[fieldName] as FormControl
    }
  }

  getControlForm(idx) {
    return this.controls[idx]
  }

  handlePrint() {
    this.onPrint.emit({ selected: this.selection, data: this.data.filteredData })
  }

  getUpdatePermission(element) {
    if (this.permissions.update instanceof Function) {
      return this.permissions.update(element)
    }
    return this.permissions.update
  }

  filterSelected(selection: AimsCommonTableFilterSelection) {
    let filterSelectionValues = selection.filters;

    filterSelectionValues.forEach((fsv) => {
      let event = null;
      if (fsv.range) {
        event = {
          value: {
            value: fsv.filterValue,
            range: fsv.range
          }
        }
      }
      else {
        event = {
          value: fsv.filterValue
        }
      }
      const col = {
        key: fsv.columnName,
      }
      this.filterChange(event, col);
    })
  }


  getExportData(dataSource: any): Observable<readonly any[]> {
    if (dataSource instanceof AimsCommonTableDataSourceBase) {
      var serverDataSource = _.cloneDeep(dataSource);
      serverDataSource.init();
      let pg = _.cloneDeep(serverDataSource.paginator as MatPaginator);
      pg.pageSize = 10000;
      serverDataSource.paginator = pg;
      let data$: Observable<readonly any[]> = serverDataSource.connect(null);
      serverDataSource.loadData();

      return data$;
    }
    else {
      return of(dataSource.filteredData);
    }
  }

  public exportStartPassalong() {
    this.exportStart.emit();
  }
  public exportCompletePassalong() {
    this.exportComplete.emit();
  }

}
