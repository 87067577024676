
import { FacEquipment } from './index';
import { FacWasteDrums } from './index';
import { ItAssetsHardware } from './index';
import { AllSites } from './index';
import { NgTenants } from './index';

export class AllLocations {
   public locationId: number = 0;
   public siteId: number = 0;
   public location: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public facEquipment: FacEquipment[] = [];
   public facWasteDrums: FacWasteDrums[] = [];
   public itAssetsHardware: ItAssetsHardware[] = [];
   public site: AllSites = null;
   public tenant: NgTenants = null;
}