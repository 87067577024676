
import { HrEmployees } from './index';
import { HrJobTitles } from './index';
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrUnionAffiliation {
   public unionAffiliationId: number = 0;
   public unionAffiliationCode: string = null;
   public unionAffiliation: string = null;
   public isDefault: boolean = false;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public hrJobTitles: HrJobTitles[] = [];
   public hrPsc: HrPsc[] = [];
   public tenant: NgTenants = null;
}