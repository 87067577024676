
import { HrEmployees } from './index';
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacPressureReadings {
   public pressureReadingId: number = 0;
   public equipmentId: number = null;
   public readingDate: Date = null;
   public inH20: number = 0;
   public kwLoad: number = 0;
   public genHours: number = 0;
   public updatedBy: string = null;
   public employeeId: number = null;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public equipment: FacEquipment = null;
   public tenant: NgTenants = null;
}