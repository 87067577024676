
import { FacEquipment } from './index';
import { NgTenants } from './index';

export class FacStandardReportingDesignator {
   public standardReportingDesignatorId: number = 0;
   public code: string = null;
   public name: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public facEquipment: FacEquipment[] = [];
   public tenant: NgTenants = null;
}