
import { BinsAtSites } from './index';
import { LogCatalog } from './index';
import { LogDemandCodes } from './index';
import { InvInventoryItem } from './index';
import { LogReqLiReceipts } from './index';
import { LogShippingCommLi } from './index';
import { LogReqHeader } from './index';
import { LogReviewFlags } from './index';
import { LogUnitOfIssue } from './index';

export class LogReqLineItems {
   public lineItemId: number = 0;
   public lineNumber: number = null;
   public reqId: string = null;
   public qtyOrdered: number = 0;
   public ui: string = null;
   public suggestedVendorId: string = null;
   public partNumber: string = null;
   public manufacturer: string = null;
   public fsc: string = null;
   public niin: string = null;
   public nomenclature: string = null;
   public unitPrice: number = null;
   public cancelledById: string = null;
   public cancelledOnDate: Date = null;
   public cancelledReason: string = null;
   public refDesignator: string = null;
   public techOrder: string = null;
   public tofigure: string = null;
   public toindex: string = null;
   public smrcode: string = null;
   public wuc: string = null;
   public supplyStatus: string = null;
   public losingSiteId: number = null;
   public micap: string = null;
   public transactionControlNumber: string = null;
   public noSubstitutes: boolean = null;
   public isu: Date = null;
   public bodate: Date = null;
   public dueOutRelease: Date = null;
   public dueOut: string = null;
   public serialNoDel: string = null;
   public demandCodeDel: string = null;
   public errcCode: string = null;
   public estimatedUnitPrice: number = null;
   public qtyReceivedPhq: number = null;
   public qtyReceivedSite: number = null;
   public eddPhq: Date = null;
   public rxPhq: Date = null;
   public eprpWillReplacePart: boolean = null;
   public binNumber: string = null;
   public vendPartNumber: string = null;
   public clonedFromPo: string = null;
   public clonedFromLine: number = null;
   public suggestedVendor: string = null;
   public projId: string = null;
   public acctId: string = null;
   public miscLnChgType: string = null;
   public lineNotes: string = null;
   public imageName: string = null;
   public imageType: string = null;
   public catalogId: number = null;
   public acceptedVendorId: string = null;
   public companyId: string = null;
   public acceptedPrice: number = null;
   public reviewFlagId: number = null;
   public binAtSiteId: number = null;
   public siteId: number = null;
   public acceptedVendorAddrDc: string = null;
   public tenantId: number = 0;
   public isPcard: boolean = false;
   public acctGrpCd: string = null;
   public taxable: boolean = false;
   public willCall: boolean = false;
   public chargeDate: Date = null;
   public inventoryItemId: number = null;
   public attachmentGuid: string = null;
   public attachmentName: string = null;
   public attachmentType: string = null;
   public binAtSite: BinsAtSites = null;
   public catalog: LogCatalog = null;
   public demandCodeDelNavigation: LogDemandCodes = null;
   public inventoryItem: InvInventoryItem = null;
   public logReqLiReceipts: LogReqLiReceipts[] = [];
   public logShippingCommLi: LogShippingCommLi[] = [];
   public req: LogReqHeader = null;
   public reviewFlag: LogReviewFlags = null;
   public uiNavigation: LogUnitOfIssue = null;
}