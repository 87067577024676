import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AimsCommonLabeltooltipComponent } from './aims-common-labeltooltip.component'


@NgModule({
  declarations: [AimsCommonLabeltooltipComponent],
  exports: [AimsCommonLabeltooltipComponent],
  imports: [CommonModule],
})
export class AimsCommonLabelTooltipModule {}
