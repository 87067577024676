
import { NgTenants } from './index';
import { TrQualXTrainingGroup } from './index';
import { TrTrainingGroupXEmployees } from './index';

export class TrTrainingGroup {
   public trainingGroupId: number = 0;
   public description: string = null;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public trQualXTrainingGroup: TrQualXTrainingGroup[] = [];
   public trTrainingGroupXEmployees: TrTrainingGroupXEmployees[] = [];
}