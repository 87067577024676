
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class WoEstimates {
   public estimateId: number = 0;
   public ui: string = null;
   public quantity: number = 0;
   public nomenclature: string = null;
   public manufacturer: string = null;
   public partNumber: string = null;
   public cost: number = 0;
   public workOrderId: number = 0;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public workOrder: WoWorkOrders = null;
}