
import { SchResources } from './index';
import { NgTenants } from './index';

export class SchGrade {
   public gradeId: number = 0;
   public grade: string = null;
   public rank: string = null;
   public tenantId: number = 0;
   public schResources: SchResources[] = [];
   public tenant: NgTenants = null;
}