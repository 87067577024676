import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatRow } from '@angular/material/table'
import { AimsCommonTableHeader } from '../aims-common-table/aims-common-table.interface'
import { ExportToExcelService } from './export-to-excel.service'

@Component({
  selector: 'aims-common-export-to-excel',
  templateUrl: './aims-common-export-to-excel.component.html',
  styleUrls: ['./aims-common-export-to-excel.component.css'],
})
export class AimsCommonExportToExcelComponent {
  @Input() exportFileName: string
  @Input() selected: MatRow[]
  @Input() header: AimsCommonTableHeader[]
  @Input() exportFunction: Function

  // for normal or server paged table
  @Input() tableDataFunction: Function
  @Input() tableDataSource: any

  @Output() exportStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportComplete: EventEmitter<any> = new EventEmitter<any>();

  constructor(private exportToExcelService: ExportToExcelService) {}

  export() {
    this.exportStart.emit(null);
    let data$ = this.tableDataFunction(this.tableDataSource);

    data$.subscribe({
        next: ret => {
        let funcTableData = ret;
        this.doExport(funcTableData);
        this.exportComplete.emit(null);
        data$.complete();
      },
        complete: _ => {
      }
    });
  }

  doExport(dataFromTable: any[]) {
    // If selections, then use that instead of the filtered data
    const headerHash = this.header.reduce((acc, item) => {
      acc[item.key] = item.label || item.key
      return acc
    }, {})

      let defaultExportData = this.selected && this.selected.length > 0 ? this.selected : [...dataFromTable]
      const { data, columns, fileName } = this.exportFunction
        ? this.exportFunction(defaultExportData)
        : { data: defaultExportData, columns: undefined, fileName:undefined }
      if(fileName){
        this.exportFileName = fileName
      }
      const { exportData, columnsToExport } = this.getOnlyColumns(data, columns)
      this.exportToExcelService.exportArrayToExcel(exportData, this.exportFileName, headerHash, columnsToExport)
  }

  getOnlyColumns(data, columns: string[]) {
    let exportArray = data
    let columnsToExport = columns
    if (!columnsToExport) {
      columnsToExport = this.header.filter((i) => !(i.type === 'checkbox' || i.type === 'button' || i.type === 'editButton' || i.type=== 'rowExpand' || i.type === 'detailsButton')).map((i) => i.key)
    }

    // Trim down to only the columns being displayed
    exportArray = data.reduce((acc: any[], item) => {
      let newItem = {}
      columnsToExport.forEach((i) => {
        if (item[i] !== undefined) {
          newItem[i] = item[i]
        }
      })
      return [...acc, newItem]
    }, [])

    return { exportData: exportArray, columnsToExport }
  }
}
