
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class WoInventoryParts {
   public inventoryPartsId: number = 0;
   public partNumber: string = null;
   public nomenclature: string = null;
   public quantity: number = 0;
   public cost: number = 0;
   public dateUsed: Date = new Date(0);
   public workOrderId: number = 0;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public workOrder: WoWorkOrders = null;
}