import { Component, OnInit, EventEmitter, Inject, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { AimsCommonPopupEditorComponent } from './aims-common-popup-editor.component'
import { AimsCommonButtonModule } from '../aims-common-button/aims-common-button.module'

@NgModule({
  declarations: [AimsCommonPopupEditorComponent],
  exports: [AimsCommonPopupEditorComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, AimsCommonButtonModule, MatIconModule],
})
export class AimsCommonPopupEditorModule {}
