
import { HrEmployees } from './index';
import { FacFuelPeriodStatus } from './index';
import { NgTenants } from './index';

export class FacFuelOther {
   public fuelOtherId: number = 0;
   public ownerName: string = null;
   public fuelType: string = null;
   public fuelAmount: number = 0;
   public fuelPeriodStatusId: string = '00000000-0000-0000-0000-000000000000';
   public employeeId: number = 0;
   public tenantId: number = 0;
   public employee: HrEmployees = null;
   public fuelPeriodStatus: FacFuelPeriodStatus = null;
   public tenant: NgTenants = null;
}