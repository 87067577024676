
import { BinMaster } from './index';
import { BinStockNumbers } from './index';
import { BinErrcCodes } from './index';

export class BinPartNumbers {
   public partNumberId: number = 0;
   public binPrimary: boolean = false;
   public partNumber: string = null;
   public comments: string = null;
   public binNumber: string = null;
   public reqPrimary: boolean = false;
   public vendor: string = null;
   public manufacturer: string = null;
   public stockNumber: string = null;
   public errcDesig: string = null;
   public tenantId: number = 0;
   public binId: number = null;
   public bin: BinMaster = null;
   public binStockNumbers: BinStockNumbers[] = [];
   public errcDesigNavigation: BinErrcCodes = null;
}