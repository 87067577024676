
import { WoScheduledMaintenanceTemplates } from './index';
import { NgTenants } from './index';

export class WoScheduledMaintenancePartsEstimate {
   public scheduledMaintenancePartsEstimateId: number = 0;
   public ui: string = null;
   public quantity: number = 0;
   public nomenclature: string = null;
   public manufacturer: string = null;
   public partNumber: string = null;
   public cost: number = 0;
   public scheduledMaintenanceTemplateId: number = 0;
   public tenantId: number = 0;
   public scheduledMaintenanceTemplate: WoScheduledMaintenanceTemplates = null;
   public tenant: NgTenants = null;
}