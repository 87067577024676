
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrPscOcc {
   public pscOccId: number = 0;
   public pscId: number = 0;
   public occId: number = 0;
   public occDateFrom: Date = null;
   public occDateTo: Date = null;
   public occStatusFrom: string = null;
   public occStatusTo: string = null;
   public occStatusToId: number = null;
   public primaryOccFrom: boolean = null;
   public primaryOccTo: boolean = null;
   public jobTitleFrom: string = null;
   public jobTitleTo: string = null;
   public jobTitleToId: number = null;
   public employmentTypeFrom: string = null;
   public employmentTypeTo: string = null;
   public employmentTypeToId: number = null;
   public workSectionFrom: string = null;
   public workSectionTo: string = null;
   public workSectionToId: number = null;
   public tenantId: number = 0;
   public psc: HrPsc = null;
   public tenant: NgTenants = null;
}