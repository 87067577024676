export enum DialogComponent {
  DeleteGroupPermissions,
  DeactivateManageUsers,
  DeleteComponent,
  CloneUser,
  DeleteInventoryGroup,
  UnsavedChanges,
  CloseTab,
  AimsError,
  AimsWarning,
  AimsInternetConnectivityError,
  ConvertRequistion,
  CloseWorkOrder,
  RequestToApprove332,
  DeleteRecord,
  ApproveWorkOrders,
  ApproveWorkOrder,
  ChangeTravelRequest,
  ChangeStatusToSent,
  SendTravelRequestQuoteOnly,
  CancelTravelRequest,
  ConfirmTravelRequest,
  RemoveItineraryConfirmation,
  SendTravelRequestInternal,
  SendTravelRequestExternal,
  CancelTravelRequestNoEmail,
  CreatePoWarning,
  ConfirmReceiveMaxQty
}
export enum SpinnerText{
  Save = 1,
  Delete = 2,
  Create = 3,
  Generate = 4,
  Load = 5,
  Request = 6
}
if(Object.freeze){Object.freeze(SpinnerText);}

//Use action verbs for confirm button
export enum DialogAction{
  Delete = "Delete",
  Deactivate = "Deactivate",
  Remove = "Remove",
  Approve = "Approve",
  Discard = "Discard changes",
  Send = "Send",
  Convert = "Convert",
  Cancel = "Cancel",
  Okay = "OK",
  Submit = "Submit",
  Request = "Request"
}
if(Object.freeze){Object.freeze(DialogAction);}
