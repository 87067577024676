
import { InvInventoryItem } from './index';

export class InvInventoryGroup {
   public inventoryGroupId: number = 0;
   public inventoryGroupCode: string = null;
   public inventoryGroup: string = null;
   public active: boolean = false;
   public tenantId: number = 0;
   public invInventoryItem: InvInventoryItem[] = [];
}