
import { WoClassifications } from './index';
import { HrEmployees } from './index';
import { NgTenants } from './index';
import { WoWorkOrders } from './index';

export class WoClassificationHistory {
   public classificationHistoryId: number = 0;
   public classificationId: number = 0;
   public startDateTime: Date = new Date(0);
   public endDateTime: Date = null;
   public workOrderId: number = 0;
   public startEmployeeId: number = null;
   public endEmployeeId: number = null;
   public parentClassificationHistoryId: number = null;
   public tenantId: number = 0;
   public classification: WoClassifications = null;
   public endEmployee: HrEmployees = null;
   public startEmployee: HrEmployees = null;
   public tenant: NgTenants = null;
   public workOrder: WoWorkOrders = null;
}