
import { NgTenants } from './index';
import { SchTravelRequests } from './index';
import { SchTravelRequestBagType } from './index';

export class SchTravelRequestBags {
   public travelRequestBagId: number = 0;
   public travelRequestId: number = 0;
   public travelRequestBagTypeId: number = 0;
   public weight: number = 0;
   public oversize: boolean = false;
   public tenantId: number = 0;
   public tenant: NgTenants = null;
   public travelRequest: SchTravelRequests = null;
   public travelRequestBagType: SchTravelRequestBagType = null;
}