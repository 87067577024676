import moment from 'moment-mini';
import {
  AimsCommonTableDateFilter,
  AimsCommonTableDateRange,
  AimsCommonTableFilterType,
  AimsCommonTableHeader,
} from '../aims-common-table/aims-common-table.interface'


export default class Utils {
  /**
   * This allows you to get the properties inside an object via string command.
   * @param object The object that has nested objects
   * @param path The string of a path, for example, "a.b"
   */
  public static getValue(object: {}, path: string) {
    const strArr = path?.replace('/[/g', '.').replace('/]/g', '').split('.')
    return strArr?.reduce((o, k) => {
      return (o || {})[k]
    }, object)
  }

  /**
   * This allows one to set a value at a specific path in an object tree.
   * @param obj Any object
   * @param value The value to set at that location
   * @param path Any string with a path, for example, "a.b"
   */
  public static setToValue(obj, value, path) {
    var i // make sure to keep a var
    path = path.split('.')
    for (i = 0; i < path.length - 1; i++) {
      if (obj[path[i]] === undefined) {
        obj[path[i]] = {}
      }
      obj = obj[path[i]]
    }

    obj[path[i]] = value
  }

  public static cloneObjectKeys(obj) {
    if (obj === undefined) {
      return
    }
    const result = Object.keys(obj).reduce((acc, value) => {
      if (this.isObject(obj, value)) {
        acc[value] = this.cloneObjectKeys(obj[value])
      } else {
        acc[value] = ''
      }
      return acc
    }, {})
    return result
  }

  public static isObject(entity, name) {
    return entity[name] && typeof entity[name] === 'object' && Array.isArray(entity[name]) === false
  }

  public static getFiltersAndSort(columns: AimsCommonTableHeader[]) {
    const result = columns.reduce((acc, val) => {
      acc[val.key] = [val.events?.filterAndSort, val.events?.filterAndSortCompare]
      return acc
    }, {})
    return result
  }

  public static compareDate(data: string, filter: AimsCommonTableDateFilter) {
    switch (filter.range) {
      case AimsCommonTableDateRange.IS_EQUAL_TO:
        return moment(filter.value).format('MM/DD/YY') === moment(data).format('MM/DD/YY')
      case AimsCommonTableDateRange.IS_NOT_EQUAL_TO:
        return moment(filter.value).format('MM/DD/YY') !== moment(data).format('MM/DD/YY')
      case AimsCommonTableDateRange.IS_AFTER:
        return moment(data).isAfter(moment(filter.value))
      case AimsCommonTableDateRange.IS_BEFORE:
        return moment(data).isBefore(moment(filter.value))
      case AimsCommonTableDateRange.IS_NULL:
        return (data == null || data === undefined)
      case AimsCommonTableDateRange.IS_NOT_NULL:
        return (data != null && data !== undefined)
      default:
        return moment(filter.value).format('MM/DD/YY') === moment(data).format('MM/DD/YY')
    }
  }
}
