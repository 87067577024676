
import { HrEmployees } from './index';
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrEmployeeStatus {
   public employeeStatusId: number = 0;
   public employeeStatus: string = null;
   public hrAuditRequired: boolean = false;
   public wsLeadReviewRequired: boolean = false;
   public dmApprovalRequired: boolean = false;
   public pmmApprovalRequired: boolean = false;
   public hrmApprovalRequired: boolean = false;
   public acctReceivalRequired: boolean = false;
   public itReceivalRequired: boolean = false;
   public default: boolean = false;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public hrPsc: HrPsc[] = [];
   public tenant: NgTenants = null;
}